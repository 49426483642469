import React, { useContext, useEffect } from "react";
import styles from "./contacts.module.scss";
import axios from "axios";
import { useState } from "react";
import { Context } from "../../../Context";
import { useNavigate } from "@reach/router";

export default function ContactsWidget() {
  const api = process.env.REACT_APP_API;
  const [numbers, setNumbers] = useState(undefined);
  const value = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${api}widget/contact`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setNumbers(data);
      });
  }, []);

  return (
    <div className={styles.widget} onClick={() => navigate("/contacts")}>
      <div>
        <div className={styles.label}>Contacts</div>
        <div className={styles.total}>{numbers ? numbers.total : "-"}</div>
      </div>
      <div className={styles.smallStats}>
        <div>
          <span>
            <i
              style={{ color: "#00b894", fontSize: 15 }}
              className="fad fa-check-circle"
              data-tip
              data-for={`Tooltip-processed`}
            ></i>{" "}
          </span>
          {numbers ? numbers.processed : "-"}
        </div>
        <div>
          <span className="captured">
            <img
              src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
              alt="icon"
              style={{
                margin: 0,
                height: 16,
                marginRight: 5,
              }}
            />{" "}
          </span>
          {numbers ? numbers.captured : "-"}
        </div>
        <div>
          <span className="linkedin">
            <i
              className="fab fa-linkedin-in"
              style={{
                fontSize: 14,
                color: "#004e89",
                marginRight: 5,
                marginLeft: 1,
                cursor: "default",
              }}
            ></i>
          </span>
          {numbers ? numbers.linkedin : "-"}
        </div>
      </div>
    </div>
  );
}
