import { Link, useNavigate } from "@reach/router";
import moment from "moment";
import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./broadcast-small-card.module.scss";
import cx from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

export default function BroadcastSmallCard({ broadcast, edit, reload }) {
  const navigate = useNavigate();
  const [stats, setStats] = useState(undefined);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    axios
      .get(`${api}broadcast/get/single-stats/${broadcast._id}`)
      .then(({ data }) => {
        setStats(data);
      });
  }, []);

  const percentage = (value = 0, total = 0) => {
    if (total === 0) {
      return 0;
    }

    let calc = (value / total) * 100;
    return calc.toFixed(2);
  };

  const deleteBroadcast = (broadcast) => {
    var r = window.confirm(
      "Are you sure you want to remove this broadcast? if you proceed, you will no longer able to check the stats and other information related"
    );
    if (r === false) {
      return;
    }

    axios
      .post(`${api}broadcast/remove/`, {
        id: broadcast._id,
      })
      .then(({ data }) => {
        reload();
        toast.success("Campaign deleted correctly");
      });
  };

  return (
    <div className="campaigns__list-box" key={broadcast._id}>
      <div
        className={cx(
          `status ${broadcast.status}`,
          styles.status,
          styles[broadcast.status]
        )}
        onClick={() => navigate(`/broadcast/edit/${broadcast._id}`)}
      >
        {broadcast.status === "RUNNING" && (
          <i className="fas fa-bolt fa-fade"></i>
        )}
        {broadcast.status === "STOPPED" && (
          <i className="fa-duotone fa-snooze"></i>
        )}
        {broadcast.status === "COMPLETED" && (
          <i className="fa-duotone fa-flag-checkered"></i>
        )}
      </div>
      <div className="details">
        <div className="details-data">
          <div className="name">{broadcast.name}</div>
          {broadcast.sender && (
            <div className="campaign_sender">
              <span>
                <b>Send From:</b> {broadcast.sender.name}{" "}
                {`<${broadcast.sender.email}>`}
              </span>
              <span>
                <b>Type:</b> {broadcast.type}
              </span>
              {broadcast.schedule && (
                <span>
                  <b>Scheduled:</b> {moment(broadcast.schedule).format("LLL")}
                </span>
              )}
              <span>
                <b>Created:</b> {moment(broadcast.createdAt).format("LLL")}
              </span>
              <span>
                <b>Version:</b>{" "}
                <span className={styles.versionBadge}>
                  {broadcast.version || 1}
                </span>
              </span>
            </div>
          )}
          <div className={cx(styles.stats, `stats`)}>
            <div className="leads" data-tip data-for="leads-tooltip">
              <i className="fas fa-user"></i>
              {stats ? stats.leads : "-"}
            </div>

            <div className="sent" data-tip data-for="sent-tooltip">
              <i className="fas fa-share"></i>
              {stats ? stats.sent : "-"}
            </div>

            <div className="open" data-tip data-for="open-tooltip">
              <i className="fas fa-envelope-open-text"></i>
              {stats ? percentage(stats.open, stats.sent) : "-"}%
            </div>

            <div className="click" data-tip data-for="click-tooltip">
              <i className="fas fa-hand-point-up"></i>
              {stats ? stats.click : "-"}
            </div>
          </div>
          <ReactTooltip id={`leads-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Leads in this broadcast</div>
          </ReactTooltip>
          <ReactTooltip id={`sent-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Emails Sent</div>
          </ReactTooltip>
          <ReactTooltip id={`open-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Open Rate</div>
          </ReactTooltip>
          <ReactTooltip id={`click-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Clicks per broadcast</div>
          </ReactTooltip>
        </div>
        <div className="actions">
          <p onClick={() => edit(broadcast)}>Edit</p>
          <Link to={`/broadcast/edit/${broadcast._id}`}>Manage</Link>
          <Link to={`/stats-broadcast/${broadcast._id}`}>Stats</Link>
          <p onClick={() => deleteBroadcast(broadcast)}>Delete</p>
        </div>
      </div>
    </div>
  );
}
