import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import Layout from "../../templates/Layout";
import styles from "./save.module.scss";
import toast from "react-hot-toast";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "../../styled-components/form-group.styled";
import { Input } from "../../styled-components/input.styled";
import { Select } from "../../styled-components/select.styled";
import { Button } from "../../styled-components/button.styled";
import ReactQuill from "react-quill";
import { useRef } from "react";
import cx from "classnames";

export default function TipsSavePage({ id }) {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const quillRefSubject = useRef();

  const onSubmit = (data) => {
    let body = {
      ...data,
    };

    if (getValues("id")) {
      const promise = axios.put(`${api}tips/`, body).then(({ data }) => {
        navigate("/admin/tips");
      });

      toast.promise(promise, {
        loading: "Updating tip...",
        success: "Tip updated!",
        error: "Uh oh, there was an error!",
      });
    } else {
      const promise = axios.post(`${api}tips/`, body).then(({ data }) => {
        navigate(`/admin/tips`);
      });

      toast.promise(promise, {
        loading: "Creating tip...",
        success: "Tip created!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  useEffect(() => {
    if (id) {
      axios.get(`${api}tips/${id}`).then(({ data }) => {
        console.log(data);
        setValue("id", data._id);
        setValue("title", data.title);
        setValue("description", data.description);
        setValue("status", data.status);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <h2 className={styles.title}>Save Tip</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <label>Title</label>
            <Input {...register("title", { required: true })} />
          </FormGroup>
          <FormGroup>
            <label>Status</label>
            <Select {...register("status", { required: true })}>
              <option value="published">Published</option>
              <option value="draft">Draft</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <label>Description</label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <ReactQuill
                  ref={quillRefSubject}
                  theme="snow"
                  value={value}
                  onChange={onChange}
                  className={cx(errors.description && styles.error)}
                />
              )}
              defaultValue={""}
              name="description"
              variant="outlined"
              control={control}
            />
          </FormGroup>
          <div className={styles.buttonContainer}>
            <Button type="submit" primary>
              Save
            </Button>
            <Button type="button" onClick={() => navigate(`/admin/tips`)}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
