import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";

const UserModal = forwardRef((props, ref) => {
  const [id, setId] = useState(undefined);
  const email = useInputValue("");
  const first_name = useInputValue("");
  const last_name = useInputValue("");
  const campaign = useInputValue("");
  const password = useInputValue("");
  const credits = useInputValue("");
  const repeat_password = useInputValue("");

  useImperativeHandle(ref, () => ({
    open(user = undefined) {
      if (user) {
        email.setValue(user.email);
        first_name.setValue(user.first_name);
        last_name.setValue(user.last_name);
        campaign.setValue(user.campaign);
        credits.setValue(user.credit ? user.credit : "");
        password.setValue("*****");
        repeat_password.setValue("*****");
        setId(user._id);
      } else {
        email.setValue("");
        first_name.setValue("");
        last_name.setValue("");
        campaign.setValue("");
        password.setValue("");
        credits.setValue("");
        repeat_password.setValue("");
        setId(undefined);
      }
      var modal = document.getElementById("myModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id) {
      body = {
        _id: id,
        first_name: first_name.value,
        last_name: last_name.value,
        campaign: campaign.value,
        credit: credits.value,
      };

      axios.post(`${api}user/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          console.log(data);
          setId(undefined);
          props.loadData();
          close();
        }
      });
    } else {
      body = {
        email: email.value,
        first_name: first_name.value,
        last_name: last_name.value,
        campaign: campaign.value,
        password: password.value,
        repeat_password: repeat_password.value,
      };

      axios
        .post(`${api}user/add`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            console.log(data);
            props.loadData();
            close();
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const close = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  };

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2>User</h2>
            </div>
            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  disabled={id}
                  value={email.value}
                  onChange={email.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>First name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  value={first_name.value}
                  onChange={first_name.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label htmlFor="validationTooltip02">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Last name"
                  value={last_name.value}
                  onChange={last_name.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group position-relative">
                <label htmlFor="validationTooltip02">Campaign ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Campaign"
                  value={campaign.value}
                  onChange={campaign.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group position-relative">
                <label htmlFor="validationTooltip02">Credits</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={credits.value}
                  onChange={credits.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password.value}
                  disabled={id}
                  onChange={password.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label htmlFor="validationTooltip02">Repeat Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Repeat Password"
                  value={repeat_password.value}
                  disabled={id}
                  onChange={repeat_password.onChange}
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={save}
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
            <button
              type="button"
              onClick={close}
              className="btn btn-secondary waves-effect waves-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserModal;
