import React, { useState } from "react";
import styles from "./leads-tracking.module.scss";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { createTracking } from "../../redux/states/tracking";

export default function LeadsTrackingComponent() {
  const [tracking, setTracking] = useState([]);
  const dispatch = useDispatch();

  const changeStatus = (status) => {
    let newTracking = [...tracking];
    if (!tracking.some((list) => list === status)) {
      newTracking.push(status);
    } else {
      newTracking = newTracking.filter((list) => list !== status);
    }
    setTracking(newTracking);
    dispatch(createTracking(newTracking));
  };

  return (
    <div className={styles.status}>
      <div className="completed">
        <i
          className={cx(
            "fad fa-flag-checkered",
            tracking.some((list) => list === "completed") && styles.active
          )}
          onClick={() => changeStatus("completed")}
          data-tip
          data-for={`Tooltip-completed-campaign`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-completed-campaign`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">Completed campaign sequence</div>
          </ReactTooltip>
        </>
      </div>
      <div className="open">
        <i
          className={cx(
            "fa-duotone fa-eye",
            tracking.some((list) => list === "open") && styles.active
          )}
          onClick={() => changeStatus("open")}
          data-tip
          data-for={`Tooltip-open-campaign`}
        ></i>
        <>
          <ReactTooltip id={`Tooltip-open-campaign`} place="top" type="dark">
            <div className="tooltip-box">
              Open at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
      <div className="click">
        <i
          className={cx(
            "fa-duotone fa-arrow-pointer",
            tracking.some((list) => list === "click") && styles.active
          )}
          onClick={() => changeStatus("click")}
          data-tip
          data-for={`Tooltip-click-campaign`}
        ></i>
        <>
          <ReactTooltip id={`Tooltip-click-campaign`} place="top" type="dark">
            <div className="tooltip-box">
              Clicked at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
      <div className="responded">
        <i
          className={cx(
            "fad fa-reply",
            tracking.some((list) => list === "responded") && styles.active
          )}
          onClick={() => changeStatus("responded")}
          data-tip
          data-for={`Tooltip-reply-campaign`}
        ></i>
        <>
          <ReactTooltip id={`Tooltip-reply-campaign`} place="top" type="dark">
            <div className="tooltip-box">
              Responded at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
    </div>
  );
}
