import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";
import Vimeo from "@u-wave/react-vimeo";

const LoginModal = forwardRef((props, ref) => {
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 5 ? 5 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  useImperativeHandle(ref, () => ({
    open(user = undefined, lead = undefined) {
      var modal = document.getElementById("login-modal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const openHowItWorks = () => {
    props.openHowItWorks("singleContact");
  };

  const close = () => {
    var modal = document.getElementById("login-modal");
    modal.style.display = "none";
  };

  return (
    <div id="login-modal" className="modal">
      <div className="modal-content">
        <div className="form-container">
          <div className="row">
            <div className="steps-container">
              <div className="step-content">
                {step === 0 && (
                  <div>
                    <img src="/logo.png" style={{ width: 200 }} alt="metrics" />
                    <h2>
                      Welcome to the Automated Inbox dashboard! Since it’s your
                      first time here, we thought we’d show you around a bit.{" "}
                    </h2>
                  </div>
                )}
                {step === 1 && (
                  <>
                    <h2>
                      Here's a Quick Start Guide to get you started on Automated
                      Inbox 2.0
                    </h2>
                    <Vimeo video="645927913" autoplay />
                  </>
                )}
                {step === 2 && (
                  <div>
                    <img src="/AI-How-To-Guide.gif" alt="buttons-img" />
                    <div className="text">
                      Click the link below for the step-by-step guide on how to
                      use your Automated Inbox dashboard. In this page, you will
                      learn how to Upload Contacts, Buy Credits, Get Leads using
                      our Automated Inbox Prospecting Feature, and create your
                      very first campaign.
                    </div>
                  </div>
                )}
              </div>
              <div className="buttons-container">
                {step === 0 && (
                  <div>
                    <button className="btn-next" onClick={onNext}>
                      Let's go!
                    </button>
                    <span onClick={close}>Later</span>
                  </div>
                )}
                {step > 0 && step < 2 && (
                  <div>
                    <div className="buttons-navigation">
                      <button className="btn-next" onClick={onNext}>
                        Got it, what’s next?
                      </button>
                      <button className="btn-back" onClick={onPrevious}>
                        Back
                      </button>
                    </div>
                    <span onClick={close}>Later</span>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <div className="buttons-navigation">
                      <button
                        className="btn-next"
                        onClick={() =>
                          window.open(
                            "https://automatedinbox.com/beta/user-guide/",
                            "_blank"
                          )
                        }
                      >
                        Get's started
                      </button>
                      <button className="btn-next" onClick={close}>
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="buttons"></div>
        </div>
      </div>
    </div>
  );
});

export default LoginModal;
