import React, { createContext, useState } from "react";
export const Context = createContext();

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return window.localStorage.getItem("token");
  });
  const [token, setToken] = useState(() => {
    return window.localStorage.getItem("token");
  });
  const [user, setUser] = useState(() => {
    let user = window.localStorage.getItem("user");
    if (user) {
      return JSON.parse(window.localStorage.getItem("user"));
    } else {
      return undefined;
    }
  });
  const [tab, setTab] = useState("campaings");

  const value = {
    isAuth,
    token,
    tab,
    setTab,
    user,
    updateUser: (user) => {
      if (!user.active) {
        setIsAuth(false);
        setToken(undefined);
        setUser(undefined);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
        return;
      }

      setUser(user);
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    activateAuth: (token, user) => {
      setUser(user);
      setIsAuth(true);
      setToken(token);
      window.localStorage.setItem("token", token);
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    removeAuth: () => {
      // pusher.unsubscribe(`user-${user._id}`);
      setUser(undefined);
      setIsAuth(false);
      setToken(undefined);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export default {
  Provider,
  Consumer: Context.Consumer,
};
