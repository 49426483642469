import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "../../components/loading/Loading";
import CampaignLayout from "../../layouts/campaign.layout";
import { Context } from "../../Context";
import axios from "axios";
import LeadsTableComponent from "../../components/leads-table/leads-table.component";
import moment from "moment";
import styles from "./linkedin-search-individual.module.scss";
import cx from "classnames";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import ReactTooltip from "react-tooltip";
import SalesNavigatorReuseModal from "../../components/modals/sales-navigator-reuse/sales-navigator-reuse.modal";

export default function LinkedinSearchIndividualPage({ id }) {
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [leads, setLeads] = useState(undefined);
  const [search, setSearch] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [total] = useState(0);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const salesNavigatorReuseModal = useRef();

  useEffect(() => {
    axios
      .get(`${api}linkedin-search/one/`, {
        params: {
          _id: id,
        },
      })
      .then(({ data }) => {
        setSearch(data);
      });

    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const open = () => {
    setOpenModal(true);
  };

  const refreshUsers = () => {
    axios
      .get(`${api}lead/all-by-linkedin-search`, {
        params: {
          id: id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setLeads(data);
        }
      });
  };

  const enrichmentStatus = () => {
    if (search && search.status) {
      if (search.status === "pending") {
        return "Waiting";
      }

      if (search.status === "running") {
        return "Capturing data";
      }

      if (search.status === "enriching-data") {
        return "Enriching data";
      }

      if (search.status === "validating-amount") {
        return "Validating Amount";
      }

      if (search.status === "completed") {
        if (
          leads &&
          leads.filter((list) => list.status === "pending").length > 0
        ) {
          return "Processing";
        }
        return "Completed";
      }
    }
    return "";
  };

  const download = () => {
    axios
      .get(`${api}lead/download`, {
        params: {
          user: value.user._id,
          linkedin: id,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "leads.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    if (openModal) {
      salesNavigatorReuseModal.current.open(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <CampaignLayout user={value.user}>
      <div className={styles.linkedinPage}>
        {value.user ? (
          <section className={cx("container", styles.noMargin)}>
            <h2>
              {search && search.name}{" "}
              {search && search.status !== "completed" && (
                <i className="fas fa-circle-notch fa-spin"></i>
              )}
            </h2>
            <div className={styles.dates}>
              <h3>
                <b>Start:</b> {search && moment(search.createdAt).format("LLL")}
              </h3>
              {search && search.status === "completed" ? (
                <h3>
                  <b>Finish:</b>{" "}
                  {search && moment(search.updatedAt).format("LLL")}
                </h3>
              ) : (
                <h3>
                  <b>Last Process:</b>{" "}
                  {search && moment(search.updatedAt).format("LLL")}
                </h3>
              )}
            </div>
            <div className={styles.attempts}>
              <b>Attempts Left:</b>{" "}
              {search && search.status === "completed"
                ? "0"
                : search && search.tries}
            </div>
            <div
              className={styles.fields}
              data-tip
              data-for={`Tooltip-data-duplicated`}
            >
              <b>Leads Duplicated:</b>{" "}
              {search && search.leadsDuplicated ? search.leadsDuplicated : "0"}
              <i className="fad fa-info-circle"></i>
            </div>
            <ReactTooltip
              id={`Tooltip-data-duplicated`}
              place="top"
              type="dark"
            >
              <div className="tooltip-box">
                This number indicate how many profiles already exist in your
                contact list, we ignored this number and try to get new profiles
                all the time.
              </div>
            </ReactTooltip>
            <div className={styles.status}>
              <b>Status:</b> {enrichmentStatus()}
            </div>
            {search && search.status === "completed" && search.message && (
              <div className={styles.status}>
                <b>Message:</b> {search && search.message}
              </div>
            )}

            <div className={styles.majorStatus}>
              <div className={styles.box}>
                {leads ? (
                  <>
                    <div className={styles.number}>{leads.length}</div>
                    <div className={styles.name}>Profiles Captured</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <div
                className={styles.box}
                data-tip
                data-for={`Tooltip-data-not-found`}
              >
                {leads && search ? (
                  <>
                    <div className={styles.number}>
                      {search.rate * 1 - leads.length}
                    </div>
                    <div className={styles.name}>Data Not Found</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <ReactTooltip
                id={`Tooltip-data-not-found`}
                place="top"
                type="dark"
              >
                <div className="tooltip-box">
                  Sorry, we didn't find any emails for these profiles
                </div>
              </ReactTooltip>
            </div>
            {search && (
              <>
                <div className={styles.searchBox}>
                  <label>Search URL: </label>
                  <a href={search.search} target="_blank">
                    <i className="far fa-link"></i> Search
                  </a>
                  <span onClick={download} className={styles.download}>
                    <i className="fal fa-download"></i> Download profiles
                  </span>
                  {search.campaign && (
                    <a
                      href={`/campaign/edit/${search.campaign}`}
                      className={styles.campaign}
                    >
                      {" "}
                      Campaign
                    </a>
                  )}
                </div>
              </>
            )}

            {leads ? (
              <section className={styles.tableContainer}>
                <LeadsTableComponent
                  rowsPerPageLeads={rowsPerPage}
                  page={page}
                  total={total}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  leads={leads}
                  refresh={refreshUsers}
                  paginationServer={false}
                />
              </section>
            ) : (
              <div className={styles.loaderContainer}>
                <SmallLoaderComponent />
              </div>
            )}
          </section>
        ) : (
          <Loading />
        )}
      </div>
      {openModal && (
        <SalesNavigatorReuseModal
          ref={salesNavigatorReuseModal}
          setOpen={setOpenModal}
        />
      )}
    </CampaignLayout>
  );
}
