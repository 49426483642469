import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import "./Global.scss";
import "./assets/main.css";
import { Router, Redirect } from "@reach/router";
import { Context } from "./Context";

import axios from "axios";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import AllUsers from "./pages/user/All";
import AllEmailsTemplates from "./pages/email-templates/All";
import AddEmailsTemplates from "./pages/email-templates/Add";
import AddLinkedinTemplates from "./pages/linkedin/Add";
import RecoverPassword from "./pages/auth/RecoverPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import { useToasts } from "react-toast-notifications";
import UserPage from "./pages/user/User";
import Campaign from "./pages/campaign/Campaign";
import "rsuite/dist/styles/rsuite-default.css";
import Home from "./pages/home/Home";
import EditCampaign from "./pages/campaign/EditCampaign";

import LinkedinSearchsPage from "./pages/linkedin/linkedin-searchs.page";
import StatsPage from "./pages/stats/stats.page";
import UnsubscribePage from "./pages/unsubscribe/unsubscribe.page";
import ContactsPage from "./pages/contacts/contacts.page";
import { Toaster } from "react-hot-toast";
import AddSearchPage from "./pages/searchs/add-search.page";
import LinkedinSearchIndividualPage from "./pages/linkedin/linkedin-search-individual.page";
import useGaTracker from "./useGaTracker";
import CampaignV2 from "./pages/campaign/CampaignV2";
import "react-autocomplete-input/dist/bundle.css";
import AutomatedInboxSearchsPage from "./pages/automated-inbox-searches/automated-inbox-searchs.page";
import AutomatedInboxSearchsIndividualPage from "./pages/automated-inbox-searches/automated-inbox-searchs-individual.page";
import ProfilePage from "./pages/profile/profile.page";
import EnrichmentsPage from "./pages/enrichments/enrichments.page";
import EnrichmentPage from "./pages/enrichments/single/single.page";
import Broadcast from "./pages/broadcast/broadcast.page";
import StatsBroadcastPage from "./pages/stats-broadcast/stats.page";

import Login from "./pages/auth/login/login.page";
import Register from "./pages/auth/register/register.page";
import Validate from "./pages/auth/validate/validate.page";
import Validated from "./pages/auth/validated/validated.page";
import SenderPage from "./pages/sender/sender.page";

import initHelpHero from "helphero";
import { useContext } from "react";
import TipsPage from "./pages/tips/all.page";
import TipsSavePage from "./pages/tips/save.page";
import TemplatesPage from "./pages/templates/all.page";
import TemplateSavePage from "./pages/templates/save.page";
const hlp = initHelpHero("BtfPWA1NQM");

function App() {
  const [show, setShow] = useState(false);
  const { addToast } = useToasts();
  const { user } = useContext(Context);

  useGaTracker();

  useEffect(() => {
    if (user) {
      let name = `${user.first_name} ${user.last_name}`;
      hlp.identify(user._id, {
        name: name,
        email: user.email,
        role: user.role,
      });
    }
  }, [user]);

  // moment.tz.setDefault("UTC");
  axios.interceptors.request.use(
    function (config) {
      // setShow(true);
      let token = window.localStorage.getItem("token");
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    },
    function (error) {
      console.log(error);
      // setShow(false);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      setShow(false);
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        error.config.__isRetryRequest = true;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (
        error.response &&
        error.response.status === 500 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        error.config.__isRetryRequest = true;
        if (
          error.response.data.indexOf(
            "is shorter than the minimum allowed length (8)"
          ) > -1
        ) {
          addToast("Password is shorter than the minimum allowed length (8)", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error.response.data.indexOf("E11000 duplicate") > -1) {
          addToast("The email already exist.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error.response.data.indexOf("invalid email") > -1) {
          addToast("Invalid Email", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
      setShow(false);
      return Promise.reject(error);
    }
  );

  return (
    <Fragment>
      <Loading show={show} color="#2ecc71" />
      <div>
        <Toaster
          containerStyle={{
            zIndex: 999999,
            fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
          }}
        />
      </div>
      <Context.Consumer>
        {({ isAuth }) =>
          isAuth ? (
            <Router>
              <Home path="/" />
              <LinkedinSearchsPage path="/sales-navigator-searches" />
              <LinkedinSearchIndividualPage path="/sales-navigator-searches/:id" />
              <AutomatedInboxSearchsPage path="/automated-inbox-searches" />
              <AutomatedInboxSearchsIndividualPage path="/automated-inbox-searches/:id" />
              <EnrichmentsPage path="/enrichments" />
              <EnrichmentPage path="/enrichment/:id" />
              <StatsPage path="/stats/:id" />
              <StatsBroadcastPage path="/stats-broadcast/:id" />
              <ContactsPage path="/contacts" />
              <AllUsers path="/admin/users" />
              <TipsPage path="/admin/tips" />
              <TipsSavePage path="/admin/tip/:id" />
              <TipsSavePage path="/admin/tip/" />
              <TemplatesPage path="/admin/templates/" />
              <TemplateSavePage path="/admin/template/:id" />
              <TemplateSavePage path="/admin/template/" />
              <UserPage path="/admin/users/:id" />
              <EditCampaign path="/campaign/edit/:id" />
              <Campaign path="/campaign/flow/:id" />
              <CampaignV2 path="/campaign/old/:campaignId" />
              <Broadcast path="/broadcast/edit/:id" />
              <AllEmailsTemplates path="/email-templates" />
              <AddEmailsTemplates path="/email-templates/add" />
              <AddEmailsTemplates path="/email-templates/edit/:id" />
              <AddEmailsTemplates path="/email-templates/edit/:id/:type" />
              <AddLinkedinTemplates path="/linkedin-searchs/edit/:id/:type" />
              <UnsubscribePage path="/unsubscribe/:email/:campaign" />
              <ProfilePage path="/profile/:tab" />
              <Redirect from="*" to="/" noThrow />
            </Router>
          ) : (
            <Router>
              <Login path="login" />
              {/* <Register path="signup" /> */}
              {/* <Validate path="validate" /> */}
              {/* <Validated path="/auth/validate-account/:id" /> */}
              {/* <RecoverPassword path="recover-password" /> */}
              {/* <ChangePassword path="/auth/change-password/:token/:email" /> */}
              <UnsubscribePage path="/unsubscribe/:email/:campaign" />
              <Redirect from="*" to="login" noThrow />
            </Router>
          )
        }
      </Context.Consumer>
    </Fragment>
  );
}

export default App;
