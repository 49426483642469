import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "@reach/router";
import SideBar from "../components/SideBar";
import styles from "./layout.module.scss";
import Nav from "../components/nav/Nav";
import { Context } from "../Context";
import axios from "axios";
import Loading from "../components/loading/Loading";

export default function Layout(props) {
  const { children, styleClass } = props;
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${api}user/get/id/${value.user._id}`)
      .then(({ data }) => {
        value.updateUser(data);
        if (data.role !== "admin") {
          navigate("/");
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`layout-wrapper ${styleClass}`}>
      {loading && <Loading />}
      <Nav
        openInformation={() => {}}
        user={value.user}
        openBuyCredits={() => {}}
        fullWidth
      />
      <SideBar />
      <div className={styles.mainContent}>
        <div className={styles.pageContent}>{children}</div>
      </div>
    </div>
  );
}
