import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
// import "./_EditCampaign.scss";
import LeadsComponent from "../../components/leads/leads.component";
import CountdownComponent from "../../components/countdown/countdown.component";
import { Context } from "../../Context";
import ToggleStatusComponent from "../../components/toggle-status/toggle-status.component";
import ToolsComponent from "../../components/tools/tools.component";
import BroadcastLayout from "../../layouts/broadcast.layout";
import EmailModal from "../../components/modals/email/email.modal";
import toast from "react-hot-toast";
import moment from "moment";
import { createBroadcast } from "../../redux/states/broadcast";
import { useDispatch } from "react-redux";

export default function Broadcast({ id }) {
  const [update, setUpdate] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const value = useContext(Context);
  const [broadcast, setBroadcast] = useState(undefined);
  const [stats, setStats] = useState(undefined);
  const [noLeads, setNoLeads] = useState(true);
  const api = process.env.REACT_APP_API;
  const emailModalRef = useRef();
  const dispatch = useDispatch();

  const reload = () => {
    axios.get(`${api}broadcast/get/${id}`).then(({ data }) => {
      setBroadcast(data);
      dispatch(createBroadcast(data));
    });
  };

  const changeStatus = (status) => {
    var r = window.confirm("Are you sure you want to change the status?");
    if (r === false) {
      return;
    }

    let body = {
      status: status,
      id: id,
    };

    axios.post(`${api}campaigns/edit`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        reload();
      }
    });
  };

  const onSaveEmail = (email) => {
    const promise = axios.post(`${api}broadcast/edit/mail/${id}`, {
      email: email,
    });
    toast
      .promise(promise, {
        loading: "Saving email...",
        success: "Saved successfully!",
        error: "Uh oh, there was an error!",
      })
      .finally(() => {
        getBroadcast();
        setOpenEmail(false);
      });
  };

  const updateLeads = () => {
    setUpdate(true);
  };

  const openEmailBuilder = () => {
    setOpenEmail(true);
  };

  const getBroadcast = () => {
    axios.get(`${api}broadcast/get/${id}`).then(({ data }) => {
      setBroadcast(data);
      console.log(data);
      dispatch(createBroadcast(data));
    });
  };

  useEffect(() => {
    getBroadcast();
  }, []);

  useEffect(() => {
    if (openEmail) {
      emailModalRef.current.open(broadcast.email, undefined, broadcast);
    }
  }, [openEmail]);

  useEffect(() => {
    if (stats) {
      if (stats.processed === 0 || stats.unverified === 0) {
        setNoLeads(true);
      } else {
        setNoLeads(false);
      }
    }
  }, [stats]);

  return (
    <BroadcastLayout
      user={value.user}
      broadcast={broadcast}
      loading={!(value.user && broadcast)}
    >
      <div className="home">
        {value.user && broadcast ? (
          <section className="container">
            <div className="header">
              <h2>
                {broadcast.name}{" "}
                <span className="title-status">{broadcast.status}</span>{" "}
              </h2>
              <div className="button-header">
                <ToggleStatusComponent
                  broadcast={broadcast}
                  reload={reload}
                  refresh={updateLeads}
                  noLeads={noLeads}
                />
              </div>
            </div>
            <span className="campaign_sender">
              <b>Broadcast ID</b>: {broadcast.uid}
            </span>
            <div className="campaign_sender">
              <b>Scheduled</b>:{" "}
              {broadcast.schedule && moment(broadcast.schedule).format("LLL")}
            </div>
            {broadcast.sender && (
              <div className="campaign_sender">
                <b>Sender:</b> {broadcast.sender.name}{" "}
                {`<${broadcast.sender.email}>`}
              </div>
            )}
            <CountdownComponent setUpdate={setUpdate} />
            <ToolsComponent
              broadcast={broadcast}
              limit={value.user?.limits?.contacts}
              total={value.user?.leads}
              user={value.user}
              refresh={updateLeads}
              noPaddings={true}
              enableSalesNavigator={false}
              enableEnrichment={false}
              enableAISearch={false}
              enableOpenEmail={true}
              openEmailBuilder={openEmailBuilder}
            />
            <div className="campaigns">
              <div className="pending">
                {value.user && (
                  <LeadsComponent
                    title="Leads"
                    user={value.user._id}
                    status={"all"}
                    update={update}
                    broadcast={broadcast._id}
                    setUpdate={setUpdate}
                    setStats={setStats}
                    enableStatus={true}
                  />
                )}
              </div>
            </div>
          </section>
        ) : (
          <div />
        )}
        {openEmail && (
          <EmailModal
            setOpen={setOpenEmail}
            onSaveEmail={onSaveEmail}
            ref={emailModalRef}
          />
        )}
      </div>
    </BroadcastLayout>
  );
}
