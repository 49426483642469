import { useLocation } from "@reach/router";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-0MX63PJ0RB");
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
      });
    }
  }, [initialized, location]);
};

export default useGaTracker;
