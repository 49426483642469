import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { Select } from "../../styled-components/select.styled";
import { FormGroup } from "../../styled-components/form-group.styled";

const ChangeCreditsModal = forwardRef((props, ref) => {
  const [id, setId] = useState(undefined);
  const credits = useInputValue("");
  const validation_credit = useInputValue("");
  const status = useInputValue("");
  const sendgrid = useInputValue("");
  const provider = useInputValue("");
  const role = useInputValue("");
  const [save_status, setStatus] = useState(false);

  useImperativeHandle(ref, () => ({
    open(user = undefined) {
      if (user) {
        credits.setValue(user.credit ? user.credit : 0);
        validation_credit.setValue(
          user.validation_credit ? user.validation_credit : 0
        );
        status.setValue(user.active ? user.active : false);
        sendgrid.setValue(
          user.settings?.sendgridKey ? user.settings.sendgridKey : ""
        );
        provider.setValue(
          user.settings?.provider ? user.settings.provider : "sendgrid"
        );
        role.setValue(user.role || "regular");
        setId(user._id);
      }

      var modal = document.getElementById("changeCreditsModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    if (save_status) {
      return;
    }
    setStatus(true);
    const api = process.env.REACT_APP_API;
    let body;
    if (id) {
      body = {
        _id: id,
        credit: credits.value,
        validation_credit: validation_credit.value,
        active: status.value,
        sendgrid: sendgrid.value,
        provider: provider.value,
        role: role.value,
      };

      axios.post(`${api}user/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          setId(undefined);
          props.loadData();
          setStatus(false);
          close();
        }
      });
    }
  };

  const close = () => {
    var modal = document.getElementById("changeCreditsModal");
    modal.style.display = "none";
  };

  return (
    <div id="changeCreditsModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2>Details</h2>
            </div>
            <div className="col-md-12">
              <div className="form-group position-relative">
                <label htmlFor="">Credits</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={credits.value}
                  onChange={credits.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label htmlFor="">Validation Credits</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={validation_credit.value}
                  onChange={validation_credit.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label htmlFor="">Status</label>
                <div className="status-container">
                  <input
                    type="checkbox"
                    id="status-user"
                    name="status-user"
                    onChange={(e) => status.setValue(e.target.checked)}
                    checked={status.value}
                  />
                  <label htmlFor="status-user"> Active</label>
                  <br />
                </div>
              </div>
              <div className="form-group position-relative">
                <label htmlFor="">SendGrid Key</label>
                <div className="status-container">
                  <input
                    type="text"
                    value={sendgrid.value}
                    onChange={sendgrid.onChange}
                    className="form-control"
                  />
                  <br />
                </div>
              </div>
              <FormGroup>
                <label htmlFor="">Provider</label>
                {provider.value && (
                  <Select
                    defaultValue={provider.value}
                    onChange={provider.onChange}
                  >
                    <option value="sendgrid">SendGrid</option>
                    <option value="aws">AWS</option>
                  </Select>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="">Role</label>
                <Select defaultValue={role.value} onChange={role.onChange}>
                  <option value="regular">Regular</option>
                  <option value="staff">Staff</option>
                  <option value="admin">Admin</option>
                </Select>
              </FormGroup>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              disabled={save_status}
              onClick={save}
              className="btn btn-primary waves-effect waves-light"
            >
              {save_status ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={close}
              className="btn btn-secondary waves-effect waves-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChangeCreditsModal;
