import { createSlice } from "@reduxjs/toolkit";

export const trackingSlice = createSlice({
  name: "tracking",
  initialState: [],
  reducers: {
    createTracking: (state, action) => action.payload,
    modifyTracking: (state, action) => ({ ...state, ...action.payload }),
    resetTracking: () => [],
  },
});

export const { createTracking, modifyTracking, resetTracking } =
  trackingSlice.actions;

export default trackingSlice.reducer;
