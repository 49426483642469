import React, { useContext, useEffect, useRef, useState } from "react";
import "./stats.scss";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import { Context } from "../../Context";
import moment from "moment";
import StatsBox from "../../components/stats-box/StatsBox";
import { Bar } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import BroadcastLayout from "../../layouts/broadcast.layout";
import DataTable from "react-data-table-component";
import Truncate from "react-truncate";

export default function StatsBroadcastPage({ id }) {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  const [sent, setSent] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [opened, setOpened] = useState(0);
  const [bounces, setBounces] = useState(0);
  const [click, setClick] = useState(0);
  const [dropped, setDropped] = useState(0);
  const [optOut, setOptOut] = useState(0);
  const [responded, setResponded] = useState(0);

  const [chartsData, setChartsData] = useState([]);
  const [flows, setFlows] = useState([]);
  const [broadcast, setBroadcast] = useState(undefined);
  const api = process.env.REACT_APP_API;
  const apiStats = process.env.REACT_APP_API_STATS;
  const value = useContext(Context);

  const [rowsPerPageLeads, setRowsPerPageLeads] = useState(20);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [leads, setLeads] = useState([]);

  const options = {};
  const customStyles = {
    header: {
      style: {
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        ".delete-container": {
          backgroundColor: "#ebebeb",
          borderRadius: 4,
        },
        ".delete-items": {
          backgroundColor: "#d52d1b",
          color: "white",
          padding: "10px 20px",
          borderRadius: 6,
          fontWeight: 600,
        },
        background: "#fbfbfb",
      },
    },
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        background: "#fbfbfb",
      },
    },
    headCells: {
      style: {
        "&:nth-child(1)": {
          paddingLeft: 0,
          position: "sticky",
          left: 0,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
        },
        "&:nth-child(2)": {
          paddingLeft: 0,
          position: "sticky",
          left: 60,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
          borderRight: `1px solid #f1f2f6`,
        },
        "&:nth-child(3)": {
          left: 10,
        },
      },
    },
    rows: {
      style: {
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        ".font-primary": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
          display: "flex",
        },
        ".basic-single": {
          width: "100%",
        },
        ".tooltip-box": {
          maxWidth: 300,
          width: "100%",
        },
        i: {
          color: "#576574",
          cursor: "pointer",
        },
        background: "#fbfbfb",
      },
    },
    pagination: {
      style: {
        background: "#fbfbfb",
      },
    },
    cells: {
      style: {
        "&:nth-child(1)": {
          paddingLeft: 0,
          position: "sticky",
          left: 0,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
        },
        ".fa-check": {
          fontSize: 12,
        },
        "*:not(i)": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        "&:nth-child(2)": {
          paddingLeft: 0,
          position: "sticky",
          left: 60,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
          borderRight: `1px solid #f1f2f6`,
        },
        "&:nth-child(3)": {
          left: 10,
        },
        ".linkedin": {
          display: "flex",
          color: "white",
          width: 19,
          height: 19,
          justifyContent: "center",
          borderRadius: "50%",
          fontWeight: 600,
          fontSize: 10,
          marginLeft: 0,
          alignItems: "center",
        },
        select: {
          height: 35,
          border: "1px solid #b7b7b7",
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
          width: "100%",
          fontWeight: 600,
          padding: "0px 3px",
        },
        ".linkedin i": {
          color: "white",
        },
      },
    },
  };

  useEffect(() => {
    axios.get(`${api}broadcast/get/${id}`).then(({ data }) => {
      setBroadcast(data);
    });

    axios
      .get(`${apiStats}v1/stats/get-stats`, {
        params: {
          broadcast: id,
          timezone: value.user.settings?.timezone,
        },
      })
      .then(({ data }) => {
        let labels = [];
        let values = [];

        data.stats.forEach((item) => {
          labels.push(
            `${moment()
              .month(item._id.month - 1)
              .format("MMM")} ${item._id.day}`
          );
          values.push(item.count);
        });

        if (data.statsCount.some((e) => e._id === "delivered")) {
          setDelivered(
            data.statsCount.find((e) => e._id === "delivered").count
          );
        }

        if (data.statsCount.some((e) => e._id === "open")) {
          setOpened(data.statsCount.find((e) => e._id === "open").count);
        }

        if (data.statsCount.some((e) => e._id === "processed")) {
          setSent(data.statsCount.find((e) => e._id === "processed").count);
        }

        if (data.statsCount.some((e) => e._id === "bounce")) {
          setBounces(data.statsCount.find((e) => e._id === "bounce").count);
        }

        if (data.statsCount.some((e) => e._id === "click")) {
          setClick(data.statsCount.find((e) => e._id === "click").count);
        }

        if (data.statsCount.some((e) => e._id === "dropped")) {
          setDropped(data.statsCount.find((e) => e._id === "dropped").count);
        }

        if (data.statsCount.some((e) => e._id === "opt-out")) {
          setOptOut(data.statsCount.find((e) => e._id === "opt-out").count);
        }

        if (data.statsCount.some((e) => e._id === "responded")) {
          setResponded(
            data.statsCount.find((e) => e._id === "responded").count
          );
        }

        setLabels(labels);
        setValues(values);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${api}lead/all/broadcast/stats`, {
        params: {
          user: value.user._id,
          page: page,
          broadcast: id,
          rowsPerPage: rowsPerPage,
        },
      })
      .then(({ data }) => {
        if (data.error) {
        } else {
          setTotal(data.totalDocs);
          setLeads(data.docs);
        }
      });
  }, [page, rowsPerPage]);

  useEffect(() => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Emails Sent by Day",
          data: values,
          fill: true,
          lineTension: 0.5,
          backgroundColor: "#e43421",
          borderColor: "#d52d1b",
        },
      ],
    };
    setChartsData(data);
  }, [labels, values]);

  const getPercentage = (total, comparisson) => {
    if (total === 0) {
      return "0%";
    }
    let percentage = (comparisson / total) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  const download = (type) => {
    axios
      .get(`${api}lead/download/clicked-leads`, {
        params: {
          broadcast: id,
          type: type,
          user: value.user._id,
          broadcastName: broadcast.name,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", `leads-${type}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  let columnsLeads = [
    {
      name: "Email",
      selector: "email",
      sortable: false,
      minWidth: "330px",
      cell: (row) => (
        <span className="font-primary">
          {row.captured && (
            <span className="captured">
              <img
                src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
                alt="icon"
                style={{
                  margin: 0,
                  height: 16,
                  marginRight: 5,
                }}
              />{" "}
            </span>
          )}
          {row.linkedin && (
            <span className="linkedin">
              <i
                className="fab fa-linkedin-in"
                style={{
                  fontSize: 14,
                  color: "#004e89",
                  marginRight: 5,
                  marginLeft: 1,
                  cursor: "default",
                }}
              ></i>
            </span>
          )}
          {!row.email && row.linkedin && <div>processing</div>}
          <div>
            <Truncate width={200} ellipsis={"..."}>
              {row.email}
            </Truncate>
          </div>
        </span>
      ),
    },
    {
      name: "First Name",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          <Truncate width={100} ellipsis={"..."}>
            {row.first_name}
          </Truncate>
        </>
      ),
    },
    {
      name: "Last Name",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          <Truncate width={100} ellipsis={"..."}>
            {row.last_name}
          </Truncate>
        </>
      ),
    },
    {
      name: "Company Name",
      sortable: false,
      width: "170px",
      cell: (row) => (
        <>
          <Truncate width={125} ellipsis={"..."}>
            {row.companyName}
          </Truncate>
        </>
      ),
    },
    {
      name: "Industry",
      sortable: false,
      width: "170px",
      cell: (row) => (
        <>
          <Truncate width={125} ellipsis={"..."}>
            {row.industry}
          </Truncate>
        </>
      ),
    },
  ];

  return (
    <BroadcastLayout user={value.user} broadcast={broadcast}>
      <div className="stats">
        {broadcast ? (
          <section className="container">
            <h2>{broadcast.name}</h2>
            <span className="general-info">Broadcast ID: {broadcast.uid}</span>
            {broadcast.sender && (
              <div className="general-info">
                <b>Sender:</b> {broadcast.sender.name}{" "}
                {`<${broadcast.sender.email}>`}
              </div>
            )}
            <div className="statsContainer">
              <div className="stats-boxs">
                <StatsBox count={sent} name={"Processed"} />
                <div className="bigStats">
                  <div className="bigBox">
                    <div className="number">
                      {getPercentage(sent, delivered)}
                      <div className="hideNumber">{delivered}</div>
                    </div>
                    <div className="name">
                      Delivered{" "}
                      <i
                        className="fa-duotone fa-circle-info"
                        data-tip
                        data-for={`Tooltip-delivered`}
                      ></i>
                    </div>
                    <ReactTooltip
                      id={`Tooltip-delivered`}
                      place="right"
                      type="dark"
                    >
                      <>
                        Email has been successfully delivered from our sending
                        server to the prospect/recipient's email server.
                      </>
                    </ReactTooltip>
                  </div>
                  <div className="smallStats">
                    <div>
                      <div className="number">
                        {getPercentage(delivered, opened)}
                        <div className="hideNumber">{opened}</div>
                      </div>
                      <div className="name">
                        Opened{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-open`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("open")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-open`}
                        place="right"
                        type="dark"
                      >
                        <>Prospect/recipient has opened the email.</>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, click)}
                        <div className="hideNumber">{click}</div>
                      </div>
                      <div className="name">
                        Clicked{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-click`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("click")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-click`}
                        place="right"
                        type="dark"
                      >
                        <>
                          Recipient clicked on a link inside the email. This
                          could include a link to a webinar, your calendar, or
                          any link that you’ve set up in your email. We advise
                          you to only use one link per email (not including the
                          unsubscribe link)
                        </>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, optOut)}
                        <div className="hideNumber">{optOut}</div>
                      </div>
                      <div className="name">
                        Opt-Out{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-opt-out`}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-opt-out`}
                        place="right"
                        type="dark"
                      >
                        <>Recipient clicked on the “unsubscribe” link.</>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, responded)}
                        <div className="hideNumber">{responded}</div>
                      </div>
                      <div className="name">
                        Responded{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-responded`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("responded")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-responded`}
                        place="right"
                        type="dark"
                      >
                        <>
                          Prospect/recipient is tagged with this status if they
                          respond to your email in your broadcast . Once they
                          reply, they are automatically removed from the
                          broadcast and will not receive further emails.
                        </>
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
                <div className="bottomStats">
                  <StatsBox count={bounces} name={"Bounces"} />
                  <StatsBox
                    count={dropped}
                    name={"Dropped"}
                    data-tip
                    data-for={`Tooltip-dropped`}
                  />
                </div>
              </div>

              <div className="stats-events-charts">
                <Bar data={chartsData} options={options} height={210} />
              </div>
            </div>
            <div className="statsTable">
              <h2>Leads who already received the broadcast</h2>
              <DataTable
                noHeader={true}
                pagination={true}
                paginationPerPage={rowsPerPageLeads}
                paginationDefaultPage={page}
                paginationTotalRows={total}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer={true}
                onChangePage={handlePageChange}
                data={leads}
                columns={columnsLeads}
                customStyles={customStyles}
                paginationRowsPerPageOptions={[20, 50, 100]}
                style={{ background: "transparent" }}
              />
            </div>
          </section>
        ) : (
          <Loading />
        )}
      </div>
    </BroadcastLayout>
  );
}
