import React, { useEffect, useContext } from "react";
import { Link } from "@reach/router";
import { Context } from "../Context";
import "./SideBar.scss";
import axios from "axios";
import { useState } from "react";

export default function SideBar() {
  const $ = window.$;
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [pending, setPending] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [statusChange, setStatusChange] = useState(0);

  useEffect(() => {
    $("#vertical-menu-btn").on("click", function (event) {
      event.preventDefault();
      $("body").toggleClass("sidebar-enable");
      if ($(window).width() >= 992) {
        $("body").toggleClass("vertical-collpsed");
      } else {
        $("body").removeClass("vertical-collpsed");
      }
    });

    async function loadingPending() {
      let pending = 0;
      let linkedinResult = await axios.get(`${api}linkedin-search/all-pending`);
      pending += linkedinResult.data.length;

      let emailResult = await axios.get(`${api}email-template/all-pending`);
      pending += emailResult.data.emails.length;

      let linkedinResultProcessing = await axios.get(
        `${api}linkedin-search/all-processing`
      );

      let usersRequestingChange = await axios.get(`${api}user/all-requesting`);

      setPending(pending);
      setProcessing(linkedinResultProcessing.data.length);
      setStatusChange(usersRequestingChange.data.length);
    }

    loadingPending();
  }, []);

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to="/" className="waves-effect">
                <i className="fas fa-box"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            {value.user.role === "admin" && (
              <>
                <li className="menu-title">Admin</li>
                <li>
                  <Link to="/admin/users" className="waves-effect">
                    <i className="fas fa-users"></i>
                    <span>Users</span>
                    {pending > 0 && (
                      <span
                        className="badge badge-pill badge-warning float-right"
                        key="t-new"
                      >
                        {pending}
                      </span>
                    )}
                    {processing > 0 && (
                      <span
                        className="badge badge-pill badge-warning float-right badge-processing "
                        key="processing-new"
                      >
                        {processing}
                      </span>
                    )}
                    {statusChange > 0 && (
                      <span
                        className="badge badge-pill badge-warning float-right badge-request"
                        key="status-new"
                      >
                        {statusChange}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/admin/tips" className="waves-effect">
                    <i className="fa-solid fa-lightbulb-on"></i>
                    <span>Tips</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/templates" className="waves-effect">
                    <i className="fad fa-envelope"></i>
                    <span>Email Templates</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
