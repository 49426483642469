import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import Truncate from "react-truncate";
import StatusLeadsComponent from "../status-leads/status-leads.component";
import styles from "./leads-table.module.scss";
import toast from "react-hot-toast";
import axios from "axios";
import { Context } from "../../Context";
import { DateTime } from "luxon";
import LeadModal from "../modals/lead/lead.modal";
import ReactTooltip from "react-tooltip";

export default function LeadsTableComponent({
  rowsPerPageLeads,
  page,
  total,
  handlePerRowsChange,
  handlePageChange,
  progressPending,
  leads,
  refresh,
  paginationServer = true,
  campaign = undefined,
  broadcast = undefined,
  enableStatus = true,
}) {
  const customStyles = {
    header: {
      style: {
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        ".delete-container": {
          backgroundColor: "#ebebeb",
          borderRadius: 4,
        },
        ".delete-items": {
          backgroundColor: "#d52d1b",
          color: "white",
          padding: "10px 20px",
          borderRadius: 6,
          fontWeight: 600,
        },
        background: "#fbfbfb",
      },
    },
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        background: "#fbfbfb",
      },
    },
    headCells: {
      style: {
        "&:nth-child(1)": {
          paddingLeft: 0,
          position: "sticky",
          left: 0,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
        },
        "&:nth-child(2)": {
          paddingLeft: 0,
          position: "sticky",
          left: 60,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
          borderRight: `1px solid #f1f2f6`,
        },
        "&:nth-child(3)": {
          left: 10,
        },
      },
    },
    rows: {
      style: {
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        ".font-primary": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
          display: "flex",
        },
        ".basic-single": {
          width: "100%",
        },
        ".tooltip-box": {
          maxWidth: 300,
          width: "100%",
        },
        i: {
          color: "#576574",
          cursor: "pointer",
        },
        background: "#fbfbfb",
      },
    },
    pagination: {
      style: {
        background: "#fbfbfb",
      },
    },
    cells: {
      style: {
        "&:nth-child(1)": {
          paddingLeft: 0,
          position: "sticky",
          left: 0,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
        },
        ".fa-check": {
          fontSize: 12,
        },
        "*:not(i)": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        "&:nth-child(2)": {
          paddingLeft: 0,
          position: "sticky",
          left: 60,
          backgroundColor: "#fbfbfb",
          zIndex: 10,
          borderRight: `1px solid #f1f2f6`,
        },
        "&:nth-child(3)": {
          left: 10,
        },
        ".linkedin": {
          display: "flex",
          color: "white",
          width: 19,
          height: 19,
          justifyContent: "center",
          borderRadius: "50%",
          fontWeight: 600,
          fontSize: 10,
          marginLeft: 0,
          alignItems: "center",
        },
        select: {
          height: 35,
          border: "1px solid #b7b7b7",
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
          width: "100%",
          fontWeight: 600,
          padding: "0px 3px",
        },
        ".linkedin i": {
          color: "white",
        },
      },
    },
  };
  const value = useContext(Context);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const api = process.env.REACT_APP_API;
  const leadModalRef = useRef();
  const handleRowSelected = useCallback((state) => {
    console.log(state.selectedRows);
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDelete = () => {
    let message = `Are you sure you want to delete this leads ? if you accept, this leads will be removed permanently and can't be recovered in the future!`;
    if (campaign) {
      message = `Are you sure you want to delete this leads ? if you accept, this leads will be removed from your current campaign`;
    }
    if (broadcast) {
      message = `Are you sure you want to delete this leads ? if you accept, this leads will be removed from your current broadcast`;
    }

    if (window.confirm(message)) {
      setToggleCleared(!toggleCleared);
      let ids = selectedRows.map((sr) => {
        return sr._id;
      });

      const body = {
        ids: ids,
        user: value.user._id,
        campaign: campaign,
        broadcast: broadcast,
      };

      const promise = axios
        .post(`${api}lead/delete-multiple`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            if (value.user) {
              axios
                .get(`${api}user/get/id/${value.user._id}`)
                .then(({ data }) => {
                  value.updateUser(data);
                });
            }
            refresh(page);
          }
        });

      toast.promise(promise, {
        loading: "Deleting...",
        success: "Delete successfully!",
        error: "Uh oh, there was an error!",
      });

      // setData(differenceBy(data, selectedRows, 'title'));
    } else {
      setToggleCleared(!toggleCleared);
    }
  };

  const isPremium = (lead) => {
    if (lead && lead.premium && lead.premium.mobile_phone) {
      return true;
    } else {
      return false;
    }
  };

  const isPremiumHide = (lead) => {
    if (lead) {
      if (lead.premium) {
        return false;
      }

      if (lead.premiumHide && lead.premiumHide.mobile_phone && lead.premiumHide.mobile_phone !== "None") {
        return true;
      }
    } else {
      return false;
    }
  };

  const upgradeProfile = (lead) => {
    if (value.user.credit < 1) {
      toast.error("You don't have credits");
      return;
    }

    let message = "Are you sure you want to upgrade this lead for 1 credit?";
    if (window.confirm(message)) {
      const body = {
        lead: lead._id,
        user: value.user._id,
      };

      const promise = axios.post(`${api}lead/upgrade`, body).then(() => {
        refresh(page);
      });
      toast.promise(promise, {
        loading: "Upgrading...",
        success: "Upgrade successfully!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  const validateLead = (lead) => {
    if (value.user.credit < 1) {
      toast.error("You don't have credits");
      return;
    }

    let message = "Are you sure you want to verify this lead for 1 credit?";
    if (window.confirm(message)) {
      const body = {
        lead: lead._id,
        user: value.user._id,
        email: value.user.email,
      };

      const promise = axios.post(`${api}lead/verify`, body).then(() => {
        refresh(page);
      });
      toast.promise(promise, {
        loading: "Verifying...",
        success: "Verification process started!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  let columnsLeads = [
    {
      name: "Email",
      selector: "email",
      sortable: false,
      minWidth: "330px",
      cell: (row) => (
        <span className="font-primary">
          {row.captured && (
            <span className="captured">
              <img
                src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
                alt="icon"
                style={{
                  margin: 0,
                  height: 16,
                  marginRight: 5,
                }}
              />{" "}
            </span>
          )}
          {row.linkedin && (
            <span className="linkedin">
              <i
                className="fab fa-linkedin-in"
                style={{
                  fontSize: 14,
                  color: "#004e89",
                  marginRight: 5,
                  marginLeft: 1,
                  cursor: "default",
                }}
              ></i>
            </span>
          )}
          {!row.email && row.linkedin && <div>processing</div>}
          <div className={styles.nameExtraInfo} onClick={() => open(row)}>
            <Truncate width={200} ellipsis={"..."}>
              {row.email}
            </Truncate>
          </div>

          {isPremium(row) && (
            <span className={styles.premium}>
              <i
                className="fas fa-star"
                style={{ color: "#3742fa", fontSize: 7 }}
              ></i>
            </span>
          )}
          {isPremiumHide(row) && (
            <>
              <span
                className={styles.buyPremium}
                data-tip
                data-for={`Tooltip-premium-no-${row._id}`}
                onClick={() => upgradeProfile(row)}
              >
                <i className="fad fa-question-circle"></i>
              </span>
              <ReactTooltip
                id={`Tooltip-premium-no-${row._id}`}
                place="top"
                type="dark"
              >
                <div className="tooltip-box">
                  Upgrade to Premium For 1 Credit
                </div>
              </ReactTooltip>
            </>
          )}

          {DateTime.fromISO(row.createdAt).toFormat("yyyy-MM-dd") ===
            DateTime.now().toFormat("yyyy-MM-dd") && (
            <span
              style={{
                background: "hsl(150deg,74%,87%)",
                padding: "0px 7px",
                borderRadius: 15,
                marginLeft: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "13px",
                color: "hsl(150deg, 74%, 39%)",
                fontFamily:
                  "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
                fontWeight: 600,
                fontSize: "12px",
                height: 16,
              }}
            >
              new
            </span>
          )}

          {row.campaignsCompleted?.some((list) => list === campaign) && (
            <>
              <span className={styles.completedIcon}>
                <i
                  className="fad fa-flag-checkered"
                  data-tip
                  data-for={`Tooltip-completed-campaign-${row._id}`}
                ></i>
              </span>
              <ReactTooltip
                id={`Tooltip-completed-campaign-${row._id}`}
                place="top"
                type="dark"
              >
                <div className="tooltip-box">Completed campaign sequence</div>
              </ReactTooltip>
            </>
          )}
        </span>
      ),
    },
    {
      name: "First Name",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          <Truncate width={100} ellipsis={"..."}>
            {row.first_name}
          </Truncate>
        </>
      ),
    },
    {
      name: "Last Name",
      sortable: false,
      width: "150px",
      cell: (row) => (
        <>
          <Truncate width={100} ellipsis={"..."}>
            {row.last_name}
          </Truncate>
        </>
      ),
    },
    {
      name: "Verified",
      sortable: false,
      width: "70px",
      cell: (row) => {
        if (row.status === "pending") {
          return (
            <>
              <i
                style={{ fontSize: 15, color: "#0984e3" }}
                className="fad fa-clock"
              ></i>
            </>
          );
        } else if (row.status === "processed") {
          return (
            <>
              <i
                style={{ color: "#00b894", fontSize: 15 }}
                className="fad fa-check-circle"
              ></i>
            </>
          );
        } else if (row.status === "un-verified") {
          return (
            <>
              <i
                style={{ color: "#ff9800", fontSize: 15 }}
                className="fad fa-exclamation-circle"
                data-tip
                data-for={`Tooltip-un-verified`}
                onClick={() => validateLead(row)}
              ></i>
              <ReactTooltip id={`Tooltip-un-verified`} place="top" type="dark">
                <div className="tooltip-box">
                  This lead hasn't been verified yet. You can verify this lead
                  for 1 Credit.
                </div>
              </ReactTooltip>
            </>
          );
        } else {
          return (
            <>
              <i
                style={{ color: "#d63031", fontSize: 15 }}
                className="fad fa-times-circle"
              ></i>
            </>
          );
        }
      },
    },
    {
      name: "Status",
      sortable: false,
      width: "160px",
      ignoreRowClick: true,
      cell: (row) => {
        return (
          <StatusLeadsComponent
            current={row.mailStatus}
            id={row._id}
            refresh={refresh}
          />
        );
      },
    },
    {
      name: "Company Name",
      sortable: false,
      width: "170px",
      cell: (row) => (
        <>
          <Truncate width={125} ellipsis={"..."}>
            {row.companyName}
          </Truncate>
        </>
      ),
    },
    {
      name: "Industry",
      sortable: false,
      width: "170px",
      cell: (row) => (
        <>
          <Truncate width={125} ellipsis={"..."}>
            {row.industry}
          </Truncate>
        </>
      ),
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      width: "180px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LL")}</div>
        </>
      ),
    },
  ];

  if (!enableStatus || value.user.subscription === "free") {
    columnsLeads = columnsLeads.filter((list) => list.name !== "Status");
  }

  const open = (lead) => {
    leadModalRef.current.open(lead);
  };

  return (
    <div>
      {selectedRows && selectedRows.length > 0 && (
        <div className={styles.selected}>
          <span>{selectedRows.length} leads selected</span>
          <button onClick={handleDelete}>Delete</button>
        </div>
      )}
      <DataTable
        noHeader={true}
        pagination={true}
        paginationPerPage={rowsPerPageLeads}
        paginationDefaultPage={page}
        paginationTotalRows={total}
        progressPending={progressPending}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationServer={paginationServer}
        onChangePage={handlePageChange}
        data={leads}
        columns={columnsLeads}
        customStyles={customStyles}
        paginationRowsPerPageOptions={[20, 50, 100]}
        style={{ background: "transparent" }}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        selectableRows
      />
      <LeadModal ref={leadModalRef} refresh={refresh} />
    </div>
  );
}
