import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./buy-credits.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";
import cx from "classnames";
import { Link } from "@reach/router";

const BuyCreditModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const [setCampaignMain] = useState(undefined);
  const [selectAmount, setSelectAmount] = useState(undefined);
  const [selectedCard, setSelectedCard] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const company = useInputValue("");
  const industry = useInputValue("");
  const country = useInputValue("");
  const campaign = useInputValue("");
  const salesNav = useInputValue("");
  const captured = useInputValue(false);
  const title = useInputValue("");

  const updateList = () => {
    axios
      .get(`${api}user/all-cc`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.data) {
          setCreditCards(data.data);
        }
      });
  };

  useEffect(() => {
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    open(c = undefined) {
      setCampaignMain(c);
      title.setValue("");
      company.setValue("");
      industry.setValue("");
      country.setValue("");
      campaign.setValue("");
      salesNav.setValue("");
      captured.setValue(false);
    },
  }));

  const buy = () => {
    const api = process.env.REACT_APP_API;
    let body;
    let price = 10000;

    if (selectAmount === 1000) {
      price = 50000;
    } else if (selectAmount === 4000) {
      price = 100000;
    }

    body = {
      user: value.user._id,
      token: selectedCard,
      price: price,
      credits: selectAmount,
    };

    if (!selectedCard || !selectAmount) {
      toast.error("Select a card and amount");
      return;
    }

    const promise = axios.post(`${api}user/payment`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        if (data === "Payment Failed") {
          // addToast("Payment Failed", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
          props.updateUser();
          props.setOpen();
        } else {
          // addToast("Your payment was successful", {
          //   appearance: "success",
          //   autoDismiss: true,
          // });
          props.updateUser();
          props.setOpen();
        }
      }
    });

    toast.promise(promise, {
      loading: "Processing...",
      success: "Payment Successful!",
      error: "Uh oh, there was an error!",
    });
  };

  return (
    <ModalLayout
      title={"Buy Lead Credits"}
      open={true}
      close={props.setOpen}
      customButton={buy}
      customButtonText={"Buy"}
    >
      <>
        <div className={styles.buyCreditModal}>
          <div
            className={cx(styles.option, selectAmount === 100 && styles.active)}
            onClick={() => setSelectAmount(100)}
          >
            <h4>100 Credits</h4>
            <h3>$100</h3>
          </div>
          <div
            className={cx(styles.option, selectAmount === 1000 && styles.active)}
            onClick={() => setSelectAmount(1000)}
          >
            <h4>1000 Credits</h4>
            <h3>$500</h3>
          </div>
          <div
            className={cx(
              styles.option,
              selectAmount === 4000 && styles.active
            )}
            onClick={() => setSelectAmount(4000)}
          >
            <h4>4,000 Credits</h4>
            <h3>$1,000</h3>
          </div>
        </div>
        <div className={styles.myCards}>
          <label htmlFor="">
            My Cards
            <Link to="/profile/billing">Add Card</Link>
          </label>
          <div className={styles.myCreditCards}>
            {creditCards.map((cc) => {
              return (
                <div
                  className={cx(
                    styles.creditCardsGrid,
                    selectedCard === cc.id && styles.active
                  )}
                  key={cc.id}
                  onClick={() => setSelectedCard(cc.id)}
                >
                  <div className="brand">
                    {cc.brand === "Visa" && <i className="fab fa-cc-visa"></i>}
                    {cc.brand === "MasterCard" && (
                      <i className="fab fa-cc-mastercard"></i>
                    )}
                    {cc.brand === "American Express" && (
                      <i className="fab fa-cc-amex"></i>
                    )}
                  </div>
                  <div className="data">
                    <div className={styles.number}>
                      {cc.brand} **** {cc.last4}
                    </div>
                    <div className={styles.expires}>
                      Expires {cc.exp_year}/{cc.exp_month}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </ModalLayout>
  );
});

export default BuyCreditModal;
