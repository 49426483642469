import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/loading/Loading";
import CampaignLayout from "../../../layouts/campaign.layout";
import { Context } from "../../../Context";
import axios from "axios";
import LeadsTableComponent from "../../../components/leads-table/leads-table.component";
import moment from "moment";
import styles from "./single.module.scss";
import cx from "classnames";
import SmallLoaderComponent from "../../../components/small-loader/small-loader.component";
import ReactTooltip from "react-tooltip";

export default function EnrichmentPage({ id }) {
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [leads, setLeads] = useState(undefined);
  const [enrichment, setEnrichment] = useState(undefined);
  const [activeTab, setActiveTab] = useState("enriched");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [total] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    axios.get(`${api}enrichment/get/${id}`).then(({ data }) => {
      setEnrichment(data);
    });

    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const refreshUsers = () => {
    axios.get(`${api}enrichment/leads/${id}`).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setLeads(data);
      }
    });
  };

  const enrichmentStatus = () => {
    if (enrichment && enrichment.status) {
      if (enrichment.status === "processing") {
        return "Processing";
      }

      if (enrichment.status === "pending") {
        return "Waiting";
      }

      if (enrichment.status === "running") {
        return "Capturing data";
      }

      if (enrichment.status === "enriching-data") {
        return "Enriching data";
      }

      if (enrichment.status === "validating-amount") {
        return "Validating Amount";
      }

      if (enrichment.status === "completed") {
        if (
          leads &&
          leads.filter((list) => list.status === "pending").length > 0
        ) {
          return "Processing";
        }
        return "Completed";
      }
    }
    return "";
  };

  const download = () => {
    axios
      .get(`${api}lead/download`, {
        params: {
          user: value.user._id,
          enrichment: id,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "leads.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const getNotEnriched = () => {
    if (!enrichment.leadsEnriched && enrichment.leadsEnriched !== 0) {
      return "-";
    }

    return leads.length - enrichment.leadsEnriched;
  };

  const getEnriched = () => {
    if (!enrichment.leadsEnriched && enrichment.leadsEnriched !== 0) {
      return "-";
    }

    return enrichment.leadsEnriched;
  };

  return (
    <CampaignLayout user={value.user}>
      <div className={styles.singlePage}>
        {value.user ? (
          <section className={cx("container", styles.noMargin)}>
            <h2>
              {enrichment && enrichment.name}

              {enrichment && enrichment.status !== "completed" && (
                <i className="fas fa-circle-notch fa-spin"></i>
              )}
            </h2>
            <div className={styles.dates}>
              <h3>
                <b>Start:</b>{" "}
                {enrichment && moment(enrichment.createdAt).format("LLL")}
              </h3>
              {enrichment && enrichment.status === "completed" ? (
                <h3>
                  <b>Finish:</b>{" "}
                  {enrichment && moment(enrichment.updatedAt).format("LLL")}
                </h3>
              ) : (
                <h3>
                  <b>Last Process:</b>{" "}
                  {enrichment && moment(enrichment.updatedAt).format("LLL")}
                </h3>
              )}
            </div>
            <div className={styles.status}>
              <b>Status:</b> {enrichmentStatus()}
            </div>

            <div className={styles.majorStatus}>
              <div className={styles.box}>
                {leads ? (
                  <>
                    <div className={styles.number}>{getEnriched()}</div>
                    <div className={styles.name}>Enriched</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <div
                className={styles.box}
                data-tip
                data-for={`Tooltip-data-not-found`}
              >
                {leads && enrichment ? (
                  <>
                    <div className={styles.number}>{getNotEnriched()}</div>
                    <div className={styles.name}>Not Enriched</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <ReactTooltip
                id={`Tooltip-data-not-found`}
                place="top"
                type="dark"
              >
                <div className="tooltip-box">
                  Sorry, we didn't find any emails for these profiles
                </div>
              </ReactTooltip>
            </div>
            {enrichment && (
              <div className={styles.searchBox}>
                <label>Search: </label>
                <span onClick={download} className={styles.download}>
                  <i className="fal fa-download"></i> Download enriched profiles
                </span>
                {enrichment.campaign && (
                  <a
                    href={`/campaign/edit/${enrichment.campaign}`}
                    className={styles.campaign}
                  >
                    {" "}
                    Campaign
                  </a>
                )}
              </div>
            )}
            <div className={styles.tabs}>
              <button
                onClick={() => setActiveTab("enriched")}
                className={cx(activeTab === "enriched" && styles.active)}
              >
                Enriched
              </button>
              <button
                onClick={() => setActiveTab("unriched")}
                className={cx(activeTab !== "enriched" && styles.active)}
              >
                Not enriched
              </button>
            </div>
            {leads ? (
              <section className={styles.tableContainer}>
                <div className={cx(activeTab === "enriched" && styles.show)}>
                  <LeadsTableComponent
                    rowsPerPageLeads={rowsPerPage}
                    page={page}
                    total={total}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                    leads={leads.filter(
                      (list) => list.enrichmentFail === false
                    )}
                    refresh={refreshUsers}
                    paginationServer={false}
                  />
                </div>
                <div className={cx(activeTab !== "enriched" && styles.show)}>
                  <LeadsTableComponent
                    rowsPerPageLeads={rowsPerPage}
                    page={page}
                    total={total}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                    leads={leads.filter((list) => list.enrichmentFail === true)}
                    refresh={refreshUsers}
                    paginationServer={false}
                  />
                </div>
              </section>
            ) : (
              <div className={styles.loaderContainer}>
                <SmallLoaderComponent />
              </div>
            )}
          </section>
        ) : (
          <Loading />
        )}
      </div>
    </CampaignLayout>
  );
}
