import React, { useContext } from "react";
import styles from "./profile.module.scss";
import { useForm } from "react-hook-form";
import { Context } from "../../../Context";
import axios from "axios";
import toast from "react-hot-toast";

export default function ProfileComponent() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { user } = useContext(Context);
  const api = process.env.REACT_APP_API;

  const onSubmit = (data) => {
    const promise = axios.post(`${api}user/update`, {
      ...data,
      user: user._id,
    });

    toast.promise(promise, {
      loading: "Updating...",
      success: "Update successfully!",
      error: "Uh oh, there was an error!",
    });
  };
  const value = useContext(Context);

  return (
    <div className={styles.tabContainer}>
      <h2>Profile</h2>
      <div className={styles.tabContainerBody}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formGroup}>
            <label>Email</label>
            <input
              defaultValue={value.user.email}
              {...register("email", { disabled: true })}
            />
          </div>

          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label>First Name</label>
              <input
                defaultValue={value.user.first_name}
                {...register("first_name", { required: true })}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Last Name</label>
              <input
                defaultValue={value.user.last_name}
                {...register("last_name", { required: true })}
              />
            </div>
          </div>

          <input type="submit" className={styles.button} value={"Update"} />
        </form>
      </div>
    </div>
  );
}
