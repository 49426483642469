import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./email.module.scss";
import ReactTooltip from "react-tooltip";

export default function EmailComponent({ email, version, index, campaign }) {
  const api = process.env.REACT_APP_API;
  const [delivered, setDelivered] = useState(0);
  const [open, setOpen] = useState(0);
  const [processed, setProcessed] = useState(0);
  const [click, setClick] = useState(0);
  const [dropped, setDropped] = useState(0);
  const [bounce, setBounce] = useState(0);

  console.log(email);

  const getPercentage = (total, comparisson) => {
    if (total === 0) {
      return "0%";
    }
    let percentage = (comparisson / total) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  useEffect(() => {
    let url = `${api}campaigns/get/email/${email.id}`;
    if (version == 4) {
      url = `${api}campaigns/get/email/${campaign._id}_${index}`;
    }
    axios.get(url).then(({ data }) => {
      if (data.find((list) => list._id === "delivered")) {
        setDelivered(data.find((list) => list._id === "delivered").count);
      }
      if (data.find((list) => list._id === "open")) {
        setOpen(data.find((list) => list._id === "open").count);
      }
      if (data.find((list) => list._id === "processed")) {
        setProcessed(data.find((list) => list._id === "processed").count);
      }
      if (data.find((list) => list._id === "dropped")) {
        setDropped(data.find((list) => list._id === "dropped").count);
      }
      if (data.find((list) => list._id === "bounce")) {
        setBounce(data.find((list) => list._id === "bounce").count);
      }
      if (data.find((list) => list._id === "click")) {
        setClick(data.find((list) => list._id === "click").count);
      }
    });
  }, []);
  return version == 4 ? (
    <div className={styles.emailContainer} key={`email-${index}`}>
      <h4>{email.subject.replace(/<[^>]+>/g, "")}</h4>
      <div className={styles.boxes}>
        <div className={styles.box}>
          <div className={styles.number}>{processed}</div>
          <label htmlFor="">Processed</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{delivered}</div>
          <label htmlFor="">Delivered</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{open}</div>
          <label htmlFor="">Open</label>
          <div className={styles.percentage}>
            {getPercentage(delivered, open)}
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{click}</div>
          <label htmlFor="">Click</label>
          <div className={styles.percentage}>
            {getPercentage(delivered, click)}
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{bounce}</div>
          <label htmlFor="">Bounces</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{dropped}</div>
          <label htmlFor="">Dropped</label>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.emailContainer} key={`email-${index}`}>
      <h4>{email.data.subject}</h4>
      <div className={styles.boxes}>
        <div className={styles.box}>
          <div className={styles.number}>{processed}</div>
          <label htmlFor="">Processed</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{delivered}</div>
          <label htmlFor="">Delivered</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{open}</div>
          <label htmlFor="">Open</label>
          <div className={styles.percentage}>
            {getPercentage(delivered, open)}
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{click}</div>
          <label htmlFor="">Click</label>
          <div className={styles.percentage}>
            {getPercentage(delivered, click)}
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{bounce}</div>
          <label htmlFor="">Bounces</label>
        </div>

        <div className={styles.box}>
          <div className={styles.number}>{dropped}</div>
          <label htmlFor="">Dropped</label>
        </div>
      </div>
    </div>
  );
}
