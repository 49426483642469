import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";

const ChangeLimitsModal = forwardRef((props, ref) => {
  const [id, setId] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const contacts = useInputValue(0);
  const campaigns = useInputValue(0);
  const broadcasts = useInputValue(0);
  const searchs = useInputValue(0);
  const monthlyLimit = useInputValue(0);

  useImperativeHandle(ref, () => ({
    open(user = undefined, admin = undefined) {
      if (user) {
        if (user.limits) {
          contacts.setValue(user.limits.contacts ? user.limits.contacts : 0);
          campaigns.setValue(user.limits.campaigns ? user.limits.campaigns : 0);
          broadcasts.setValue(
            user.limits?.broadcasts ? user.limits.broadcasts : 0
          );
          searchs.setValue(user.limits.searchs ? user.limits.searchs : 0);
          monthlyLimit.setValue(
            user.settings?.monthlyLimit ? user.settings.monthlyLimit : 3000
          );
        }
        setId(user._id);
      }
      setUser(admin);
      var modal = document.getElementById("changeLimitsModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id) {
      body = {
        _id: id,
        contacts: contacts.value,
        campaigns: campaigns.value,
        broadcasts: broadcasts.value,
        searchs: searchs.value,
        monthlyLimit: monthlyLimit.value,
        user: user,
      };

      axios.post(`${api}user/limits`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          setId(undefined);
          props.loadData();
          close();
        }
      });
    }
  };

  const close = () => {
    var modal = document.getElementById("changeLimitsModal");
    modal.style.display = "none";
  };

  return (
    <div id="changeLimitsModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2>Limits</h2>
            </div>
            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Contacts</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={contacts.value}
                  onChange={contacts.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>Campaigns</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={campaigns.value}
                  onChange={campaigns.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>Broadcasts</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Credits"
                  value={broadcasts.value}
                  onChange={broadcasts.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>Searchs</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Searchs"
                  value={searchs.value}
                  onChange={searchs.onChange}
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>Monthly Limits</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Searchs"
                  value={monthlyLimit.value}
                  onChange={monthlyLimit.onChange}
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={save}
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
            <button
              type="button"
              onClick={close}
              className="btn btn-secondary waves-effect waves-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChangeLimitsModal;
