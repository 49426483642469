import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./delay.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";

const DelayModal = forwardRef((props, ref) => {
  const time = useInputValue(3);
  const interval = useInputValue("day");

  useImperativeHandle(ref, () => ({
    open(delay = undefined) {
      if (delay) {
        time.setValue(delay.time);
        interval.setValue(delay.interval);
      } else {
        time.setValue(3);
        interval.setValue("day");
      }
    },
  }));

  const save = () => {
    const delay = {
      time: time.value,
      interval: interval.value,
    };
    console.log("save", delay);
    props.onSaveDelay(delay);
  };

  return (
    <ModalLayout title={"Delay"} open={true} close={props.setOpen} save={save}>
      <>
        <div className={styles.grid}>
          <div className={styles.formGroup}>
            <label>Time</label>
            <input
              type="text"
              placeholder={"1"}
              value={time.value}
              onChange={time.onChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Interval</label>
            <select onChange={interval.onChange} value={interval.value}>
              <option value="hour">Hour(s)</option>
              <option value="day">Day(s)</option>
            </select>
          </div>
        </div>
      </>
    </ModalLayout>
  );
});

export default DelayModal;
