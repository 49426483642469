import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./email.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextInput from "react-autocomplete-input";
var Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

const EmailModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const _value = useContext(Context);
  const [test, setTest] = useState(false);

  const campaign = useInputValue("");
  const broadcast = useInputValue("");
  const subject = useInputValue("");
  const [content, setContent] = useState("");
  const testEmail = useInputValue("");
  const testFname = useInputValue("");
  const testLname = useInputValue("");
  const testCompany = useInputValue("");
  const testIndustry = useInputValue("");
  const quillRef = useRef();

  useImperativeHandle(ref, () => ({
    open(email = undefined, c = undefined, b = undefined) {
      if (email) {
        subject.setValue(email.subject);
        setContent(email.body);
      } else {
        subject.setValue("");
        setContent("");
      }
      campaign.setValue(c);
      broadcast.setValue(b);
    },
  }));

  const insert = (text) => {
    let editor = quillRef.current.getEditor();
    let range = editor.getSelection();
    let position = range ? range.index : 0;
    editor.insertText(position, text);
  };

  const save = () => {
    if (!subject.value.trim() || !content.trim()) {
      toast.error("Required fields");
      return;
    }

    const email = {
      subject: subject.value,
      body: content,
    };
    props.onSaveEmail(email);
  };

  const sendTest = () => {
    const lead = {
      first_name: testFname.value,
      last_name: testLname.value,
      email: testEmail.value,
      companyName: testCompany.value,
      industry: testIndustry.value,
    };
    if (!testFname.value || !testLname.value || !testEmail.value) {
      toast.error("Required fields for testing");
      return;
    }

    let _body = bindFields(content, lead);
    let _subject = bindFields(subject.value, lead);

    const body = {
      content: _body,
      subject: _subject,
      to: testEmail.value,
      campaign: campaign?.value,
      broadcast: broadcast?.value,
    };

    const promise = axios
      .post(`${api}lead/send-test`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        }
      });
    toast.promise(promise, {
      loading: "Sending test...",
      success: "Email test sent!",
      error: (error) => (error ? error : "Uh oh, there was an error!"),
    });
  };

  const bindFields = (body, lead) => {
    let bodyBind = body;

    if (bodyBind.indexOf("{{first_name}}") > -1) {
      if (lead.first_name) {
        bodyBind = bodyBind.replace(/{{first_name}}/g, lead.first_name);
      } else {
        if (bodyBind.indexOf("{{last_name}}") === -1) {
          bodyBind = bodyBind.replace(/{{first_name}}/g, "Friend");
        } else {
          bodyBind = bodyBind.replace(/{{first_name}}/g, "");
        }
      }
    }

    if (bodyBind.indexOf("{{last_name}}") > -1) {
      if (lead.last_name) {
        bodyBind = bodyBind.replace(/{{last_name}}/g, lead.last_name);
      } else {
        bodyBind = bodyBind.replace(/{{last_name}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{company}}") > -1) {
      if (lead.companyName) {
        bodyBind = bodyBind.replace(/{{company}}/g, lead.companyName);
      } else {
        bodyBind = bodyBind.replace(/{{company}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{industry}}") > -1) {
      if (lead.industry) {
        bodyBind = bodyBind.replace(/{{industry}}/g, lead.industry);
      } else {
        bodyBind = bodyBind.replace(/{{industry}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{email}}") > -1) {
      if (lead.email) {
        bodyBind = bodyBind.replace(/{{email}}/g, lead.email);
      } else {
        bodyBind = bodyBind.replace(/{{email}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{unsubscribe}}") > -1) {
      if (campaign.value) {
        bodyBind = bodyBind.replace(
          /{{unsubscribe}}/g,
          `<a href="https://staging.automatedinbox.com/unsubscribe/${
            lead.email
          }/${campaign.value.toString()}">unsubscribe</a>`
        );
      }
      if (broadcast.value) {
        bodyBind = bodyBind.replace(
          /{{unsubscribe}}/g,
          `<a href="https://staging.automatedinbox.com/unsubscribe/${
            lead.email
          }/${broadcast.value.toString()}">unsubscribe</a>`
        );
      }
    }

    return bodyBind;
  };

  return (
    <ModalLayout
      title={"Email Template"}
      open={true}
      close={props.setOpen}
      save={save}
    >
      <>
        <div className={styles.formGroup}>
          <label>
            Subject <span>required</span>
          </label>
          <TextInput
            trigger={["{{"]}
            options={{
              "{{": [
                "first_name}}",
                "last_name}}",
                "email}}",
                "company}}",
                "industry}}",
              ],
            }}
            onChange={(value) => subject.setValue(value)}
            value={subject.value}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="">
            Content <span>required</span>
          </label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={content}
            onChange={setContent}
          />

          <div className={styles.buttonsFlex}>
            <button onClick={() => insert("{{first_name}}")}>first name</button>
            <button onClick={() => insert("{{last_name}}")}>last name</button>
            <button onClick={() => insert("{{email}}")}>email</button>
            <button onClick={() => insert("{{company}}")}>company</button>
            <button onClick={() => insert("{{industry}}")}>industry</button>
            <button onClick={() => insert("{{unsubscribe}}")}>
              unsubscribe
            </button>
          </div>
        </div>
        <label className={styles.headerSection}>
          <input
            type="checkbox"
            checked={test}
            onChange={(e) => setTest(e.target.checked)}
          />{" "}
          Test Email
        </label>
        {test && (
          <div className={styles.formGroup}>
            <div className={styles.grid}>
              <div className={styles.formGroup}>
                <label>
                  First name <span>required</span>
                </label>
                <input
                  type="text"
                  placeholder="Jane"
                  onChange={testFname.onChange}
                  value={testFname.value}
                />
              </div>
              <div className={styles.formGroup}>
                <label>
                  Last name <span>required</span>
                </label>
                <input
                  type="text"
                  placeholder="Doe"
                  onChange={testLname.onChange}
                  value={testLname.value}
                />
              </div>
            </div>

            <div className={styles.grid}>
              <div className={styles.formGroup}>
                <label>Company</label>
                <input
                  type="text"
                  placeholder="Automated Inbox"
                  onChange={testCompany.onChange}
                  value={testCompany.value}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Industry</label>
                <input
                  type="text"
                  placeholder="Tech"
                  onChange={testIndustry.onChange}
                  value={testIndustry.value}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label>
                Email <span>required</span>
              </label>

              <input
                type="text"
                placeholder="janedoe@automatedinbox.com"
                onChange={testEmail.onChange}
                value={testEmail.value}
              />
            </div>

            <div className={styles.buttonsFlex}>
              <button primary className={styles.testEmail} onClick={sendTest}>
                Send Test Email
              </button>
            </div>
          </div>
        )}
      </>
    </ModalLayout>
  );
});

export default EmailModal;
