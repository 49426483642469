import React, { DragEvent } from "react";
import styles from "./sidebar.module.scss";
import ReactFlow, { Handle } from "react-flow-renderer";

const onDragStart = (event, nodeType, className) => {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.setData("className", className);
  event.dataTransfer.effectAllowed = "move";
};

const isValidConnection = (connection) => {
  console.log({ connection });
  return connection.target === "B";
};

const Sidebar = ({ disableEmails }) => {
  return (
    <aside>
      <h2>Flow Controls</h2>
      <div className={styles.description}>
        You can drag these follow ups to the pane on the left.
      </div>
      {!disableEmails && (
        <div
          className="react-flow__node-emailSelector"
          onDragStart={(event) => onDragStart(event, "emailSelector", "email")}
          draggable
        >
          Email
        </div>
      )}

      <div
        className="react-flow__node-delaySelector"
        onDragStart={(event) => onDragStart(event, "delaySelector", "delay")}
        draggable
      >
        Delay
      </div>
    </aside>
  );
};

export default Sidebar;
