import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Context from "./Context";
import { ToastProvider } from "react-toast-notifications";
import { PusherProvider } from "./PusherContext";
import Pusher from "pusher-js";
import PusherConnection from "./PusherConnection";
import { LocationProvider, createHistory } from "@reach/router";
import { IntercomProvider } from "react-use-intercom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { store } from "./redux/store";

let history = createHistory(window);
const pusher = new Pusher("cc33c2ae8584dc416ef2", {
  cluster: "us2",
});

const INTERCOM_APP_ID = "hd0oiycs";

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey="6Le_zc0dAAAAALH7vo_DbHAiA5TLVCQA_06-S8JU"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <ToastProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot="true">
          <LocationProvider history={history}>
            <PusherProvider pusher={pusher}>
              <Context.Provider>
                <App />
                <PusherConnection />
              </Context.Provider>
            </PusherProvider>
          </LocationProvider>
        </IntercomProvider>
      </ToastProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
