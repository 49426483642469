import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { useNavigate } from "@reach/router";

const SenderModal = forwardRef((props, ref) => {
  const [user, setUser] = useState(undefined);

  const id = useInputValue("");
  const email = useInputValue("");
  const name = useInputValue("");
  const replyTo = useInputValue("");

  useImperativeHandle(ref, () => ({
    open(user = undefined, sender = undefined) {
      setUser(user);
      if (sender) {
        id.setValue(sender._id);
        name.setValue(sender.name ? sender.name : "");
        email.setValue(sender.email ? sender.email : "");
        replyTo.setValue(sender.replyTo ? sender.replyTo : "");
      } else {
        id.setValue("");
        name.setValue("");
        email.setValue("");
        replyTo.setValue("");
      }

      var modal = document.getElementById("single-lead-modal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id.value) {
      body = {
        name: name.value,
        email: email.value,
        replyTo: replyTo.value,
        id: id.value,
        user: user,
      };

      axios.post(`${api}sender/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.loadData();
          close();
        }
      });
    } else {
      body = {
        name: name.value,
        email: email.value,
        replyTo: replyTo.value,
        user: user,
      };

      axios.post(`${api}sender/add`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.loadData();
          close();
        }
      });
    }
  };

  const close = () => {
    var modal = document.getElementById("single-lead-modal");
    modal.style.display = "none";
  };

  return (
    <div id="single-lead-modal" className="modal campaign-modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12 header-modal">
              <h2>Sender</h2>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>
                  Name <span className="required">required</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Acme"
                  value={name.value}
                  onChange={name.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="no-reply@acme.com"
                  value={email.value}
                  onChange={email.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Reply To</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="reply@acme.com"
                  value={replyTo.value}
                  onChange={replyTo.onChange}
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button
              type="button"
              disabled={!name.value || !email.value || !replyTo.value}
              onClick={save}
              className="button primary"
            >
              Save
            </button>
            <button type="button" onClick={close} className="button secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SenderModal;
