import { useLocation } from "@reach/router";
import React, { useContext, useEffect, useRef } from "react";
import ContactsBarComponent from "../components/contacts-bar/contacts-bar.component";
import Loading from "../components/loading/Loading";
import Menu from "../components/menu/Menu";
import Nav from "../components/nav/Nav";
import WarningLimitExceededComponent from "../components/warning-limit-exceeded/warning-limit-exceeded.component";
import axios from "axios";
import { Context } from "../Context";
import LoginModal from "../components/modals/LoginModal";

export default function ProfileLayout({ children, user, loading, campaign }) {
  const location = useLocation();
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const loginModalRef = useRef();

  const openTutorial = () => {
    loginModalRef.current.open();
  };

  useEffect(() => {
    if (user) {
      axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
        value.updateUser(data);
      });
    }
  }, [location]);

  return (
    <main>
      <Nav
        openInformation={openTutorial}
        user={user}
        openBuyCredits={() => {}}
      />
      {children}
    </main>
  );
}
