import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";

const SingleLeadModal = forwardRef((props, ref) => {
  const [leads, setLeads] = useState([]);
  const [user, setUser] = useState(undefined);
  const [campaign, setCampaign] = useState(undefined);
  const [broadcast, setBroadcast] = useState(undefined);

  const id = useInputValue("");
  const first_name = useInputValue("");
  const last_name = useInputValue("");
  const email = useInputValue("");
  const name = useInputValue("");
  const companyName = useInputValue("");
  const industry = useInputValue("");

  useImperativeHandle(ref, () => ({
    open(user = undefined, campaign = undefined, broadcast = undefined) {
      setUser(user);
      setCampaign(campaign);
      setBroadcast(broadcast);
      id.setValue("");
      email.setValue("");
      first_name.setValue("");
      last_name.setValue("");
      companyName.setValue("");
      industry.setValue("");

      var modal = document.getElementById("single-lead-modal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id.value) {
      body = {
        first_name: first_name.value,
        last_name: last_name.value,
        companyName: companyName.value,
        industry: industry.value,
        id: id.value,
        user: user,
        campaign: campaign,
        broadcast: broadcast,
      };

      axios.post(`${api}lead/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          console.log(data);
          props.loadData();
          close();
        }
      });
    } else {
      body = {
        email: email.value,
        first_name: first_name.value,
        last_name: last_name.value,
        companyName: companyName.value,
        industry: industry.value,
        user: user,
        campaign: campaign,
        broadcast: broadcast,
      };

      axios.post(`${api}lead/add`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          console.log(data);
          props.loadData();
          close();
        }
      });
    }
  };

  const openHowItWorks = () => {
    props.openHowItWorks("singleContact");
  };

  const close = () => {
    var modal = document.getElementById("single-lead-modal");
    modal.style.display = "none";
  };

  return (
    <div id="single-lead-modal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12 header-modal">
              <h2>Lead</h2>
              <div className={"how-it-works"} onClick={() => openHowItWorks()}>
                Show me how it works
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>
                  First Name <span className="required">required</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={first_name.value}
                  onChange={first_name.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={last_name.value}
                  onChange={last_name.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>
                  Email <span className="required">required</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  disabled={id.value}
                  value={email.value}
                  onChange={email.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  value={companyName.value}
                  onChange={companyName.onChange}
                  required=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>Industry</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Industry"
                  value={industry.value}
                  onChange={industry.onChange}
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button
              type="button"
              disabled={!email.value || !first_name.value}
              onClick={save}
              className="button primary"
            >
              Save
            </button>
            <button type="button" onClick={close} className="button secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SingleLeadModal;
