import { useNavigate } from "@reach/router";
import moment from "moment";
import React from "react";
import Status from "../status/status.component";
import styles from "./sales-navigator-card.module.scss";
import cx from "classnames";

export default function SalesNavigatorCard({ sales }) {
  const navigate = useNavigate();

  const getLeadsFound = (leads) => {
    if (leads.status === "completed") {
      return leads.leadsFound ? leads.leadsFound : "-";
    }

    if (leads.status === "error") {
      return leads.leadsFound ? leads.leadsFound : "-";
    }

    if (leads.leadsFound) {
      return leads.leadsFound;
    } else {
      return "still processing";
    }
  };

  const enrichmentStatus = (search) => {
    if (search && search.status) {
      if (search.status === "pending") {
        return "Waiting";
      }

      if (search.status === "running") {
        return "Capturing data";
      }

      if (search.status === "enriching-data") {
        return "Enriching data";
      }

      if (search.status === "validating-amount") {
        return "Validating Amount";
      }

      if (search.status === "completed") {
        return "Completed";
      }
    }
    return "";
  };

  return (
    <div
      className={styles.enrichmentCard}
      onClick={() => navigate(`/sales-navigator-searches/${sales._id}`)}
    >
      <div
        className={cx(
          styles.iconStatus,
          sales.status === "completed" && styles.completed,
          sales.status === "error" && styles.error
        )}
      >
        {sales.status === "completed" && (
          <div>
            <i className="far fa-check"></i>
          </div>
        )}

        {sales.status === "error" && (
          <div>
            <i className="fad fa-times-circle"></i>
          </div>
        )}

        {sales.status !== "completed" && sales.status !== "error" && (
          <div>
            <i className="fas fa-circle-notch fa-spin"></i>
          </div>
        )}
      </div>
      <div>
        <h2>{sales.name}</h2>
        <div className={styles.metadata}>
          <span>
            <b>Status:</b> {enrichmentStatus(sales)}
          </span>
          <span>
            <b>Created date:</b>
            {moment(sales.createdAt).format("LLL")}
          </span>
        </div>
        <div className={styles.metadata}>
          <span>
            <b>Premium:</b> {sales.premium ? "Yes" : "No"}
          </span>
          <span>
            <b>Personal Email:</b> {sales.personalEmail ? "Yes" : "No"}
          </span>
          <span>
            <b>Profiles Requested:</b> {sales.rate}
          </span>
          <span>
            <b>Profiles Found:</b> {getLeadsFound(sales)}
          </span>
        </div>
        {sales.message && (
          <div className={styles.metadata}>
            <span>
              <b>Message:</b> {sales.message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
