import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";

const HardResetModal = forwardRef((props, ref) => {
  const [user, setUser] = useState("");
  const confirmation = useInputValue("");
  const api = process.env.REACT_APP_API;

  useImperativeHandle(ref, () => ({
    open(user = undefined) {
      if (user) {
        setUser(user);
        confirmation.setValue("");
      }
      var modal = document.getElementById("hardReset");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const close = () => {
    var modal = document.getElementById("hardReset");
    modal.style.display = "none";
  };

  const hardReset = () => {
    let body = {
      user: user._id,
      campaign: user.campaign,
    };

    axios.post(`${api}lead/delete-campaign`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        close();
      }
    });
  };

  return (
    <div id="hardReset" className="modal">
      {user && (
        <div className="modal-content">
          <span className="close" onClick={close}>
            &times;
          </span>
          <div className="form-container">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  {user.first_name} {user.last_name}
                </h2>
              </div>
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label htmlFor="validationTooltip">
                    Are you sure you want to remove all the leads of this user ?
                    if yes, type DELETE below.
                  </label>
                  <div className="input-search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="DELETE"
                      value={confirmation.value}
                      onChange={confirmation.onChange}
                      required=""
                    />
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={hardReset}
                      disabled={confirmation.value !== "DELETE"}
                    >
                      Hard Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button
                type="button"
                onClick={close}
                className="btn btn-secondary waves-effect waves-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default HardResetModal;
