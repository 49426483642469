import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { Context } from "../../../Context";
import styles from "./lead-search.module.scss";
import axios from "axios";
import BuyCreditModal from "../../modals/buy-credits/buy-credits.modal";

export default function LeadSearchWidget() {
  const value = useContext(Context);
  const buyCreditsModal = useRef();
  const api = process.env.REACT_APP_API;
  const [openBuy, setOpenBuy] = useState(false);

  const updateUser = () => {
    axios.get(`${api}user/get/id/${value.user._id}`).then(({ data }) => {
      value.updateUser(data);
    });
  };

  return (
    <div className={styles.widget}>
      <div className={styles.title}>Credits</div>
      <div className={styles.subtitle}>{value.user.credit}</div>
      <div className={styles.buttons}>
        <button onClick={() => setOpenBuy(true)}>
          Buy <b>Credits</b> to get <b>Leads</b>
        </button>
      </div>
      {openBuy && (
        <BuyCreditModal
          ref={buyCreditsModal}
          setOpen={setOpenBuy}
          updateUser={updateUser}
        />
      )}
    </div>
  );
}
