import React, { useEffect, useState, useRef, useContext } from "react";
import Layout from "../../templates/Layout";
import { Link } from "@reach/router";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import moment from "moment";
import { Context } from "../../Context";
import "./All.scss";
import UserModal from "../../components/modals/User";
import { useInputValue } from "../../hooks/useInputValue";
import { useNavigate } from "@reach/router";

export default function AddEmailsTemplates({ id, type }) {
  const value = useContext(Context);
  const navigate = useNavigate();
  const $ = window.$;
  const api = process.env.REACT_APP_API;

  const subject = useInputValue("");
  const content = useInputValue("");
  const position = useInputValue("");

  const [breadcrump] = useState([
    {
      name: "Email Templates",
      link: "/email-templates",
      active: false,
    },
    {
      name: "Add Email Templates",
      active: true,
    },
  ]);

  useEffect(() => {
    if (id) {
      axios
        .get(`${api}email-template/one`, {
          params: {
            _id: id,
          },
        })
        .then(({ data }) => {
          if (data.error) {
            console.log(data.error);
          } else {
            console.log(data);
            position.setValue(data.position);
            subject.setValue(data.subject);
            content.setValue(data.content);
          }
        });
    }
  }, []);

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id) {
      body = {
        subject: subject.value,
        position: position.value,
        content: content.value,
        user: value.user._id,
        _id: id,
      };

      axios.post(`${api}email-template/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          navigate("/email-templates", { replace: true });
        }
      });
    } else {
      body = {
        subject: subject.value,
        position: position.value,
        content: content.value,
        user: value.user._id,
      };

      axios.post(`${api}email-template/add`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          navigate("/email-templates", { replace: true });
        }
      });
    }
  };

  const approve = () => {
    let body = {
      approve: true,
      _id: id,
    };

    axios.post(`${api}email-template/edit`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        navigate("/users", { replace: true });
      }
    });
  };

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Email Templates</h4>

              <div className="page-title-right">
                <Breadcrumb childs={breadcrump} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="form-group position-relative">
                  <label>
                    Position <span className="required">required</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Position"
                    value={position.value}
                    onChange={position.onChange}
                    required=""
                  />
                </div>
                <div className="form-group position-relative">
                  <label>
                    Subject <span className="required">required</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject.value}
                    onChange={subject.onChange}
                    required=""
                  />
                </div>
                <div className="form-group position-relative">
                  <label>
                    Content <span className="required">required</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    rows={10}
                    placeholder="Subject"
                    value={content.value}
                    onChange={content.onChange}
                    required=""
                  />
                </div>
                <div className="buttons">
                  <button
                    type="button"
                    disabled={
                      !subject.value || !content.value || !position.value
                    }
                    onClick={save}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Save
                  </button>
                  {type == 1 && (
                    <button
                      type="button"
                      disabled={
                        !subject.value || !content.value || !position.value
                      }
                      onClick={approve}
                      className="btn btn-primary approve waves-effect waves-light"
                    >
                      Approve
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
