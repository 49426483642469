import React, { useContext, useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import CampaignLayout from "../../layouts/campaign.layout";
import { Context } from "../../Context";
import axios from "axios";
import SearchLeadsTableComponent from "../../components/search-leads-table/leads-table.component";
import moment from "moment";
import styles from "./automated-inbox-searchs-individual.module.scss";
import cx from "classnames";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import ReactTooltip from "react-tooltip";

export default function AutomatedInboxSearchsIndividualPage({ id }) {
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [total, setTotal] = useState(undefined);
  const [leads, setLeads] = useState([]);  
  const [search, setSearch] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${api}search/one/`, {
        params: {
          _id: id,
        },
      })
      .then(({ data }) => {
        setSearch({
          ...data,
          status: data.status ? data.status : "completed",
        });
      });
      getTotal()
      refreshUsers(page)        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    refreshUsers(page, newPerPage)
  };

  const handlePageChange = (page) => {
    setPage(page);
    refreshUsers(page)    
  };

  const refreshUsers = (page, size = rowsPerPage) => {
    setLoading(true);
    axios
      .get(`${api}lead/page-by-search`, {
        params: {
          id: id,
          page: page,
          limit: size
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setLeads(data);
        }
      });
      setLoading(false);
  };
  
  const getTotal = async () => {
    setLoading(true);
    await axios
      .get(`${api}lead/total-by-search`, {
        params: {
          id: id
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTotal(data);
          console.log("total: " +data)
        }
      });
      setLoading(false);
  };

  const download = () => {
    axios
      .get(`${api}lead/download`, {
        params: {
          user: value.user._id,
          search: id,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "leads.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const getStatus = (search) => {
    if (!search) {
      return "Completed";
    }

    if (search.status === "pending") {
      return "Running";
    }

    return "Completed";
  };

  return (
    <CampaignLayout user={value.user}>
      <div className={styles.linkedinPage}>
        {value.user ? (
          <section className={cx("container", styles.noMargin)}>
            <h2>{search && search.name}</h2>

            <div className={styles.dates}>
              <h3>
                <b>Start:</b> {search && moment(search.createdAt).format("LLL")}
              </h3>
              {search && search.status === "completed" ? (
                <h3>
                  <b>Finish:</b>{" "}
                  {search && moment(search.updatedAt).format("LLL")}
                </h3>
              ) : (
                <h3>
                  <b>Last Process:</b>{" "}
                  {search && moment(search.updatedAt).format("LLL")}
                </h3>
              )}
            </div>
            <div
              className={styles.fields}
              data-tip
              data-for={`Tooltip-data-duplicated`}
            >
              <b>Leads Duplicated:</b>{" "}
              {search && search.leadsDuplicated ? search.leadsDuplicated : "0"}
              <i className="fad fa-info-circle"></i>
            </div>
            <br />
            <div className={styles.fields}>
              <b>Status:</b> {getStatus(search)}
            </div>

            <div className={styles.majorStatus}>
              <div className={styles.box}>
                {total ? (
                  <>
                    <div className={styles.number}>{total}</div>
                    <div className={styles.name}>Profiles Captured</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <div
                className={styles.box}
                data-tip
                data-for={`Tooltip-data-not-found`}
              >
                {total && search ? (
                  <>
                    <div className={styles.number}>
                      {search.rate * 1 - total}
                    </div>
                    <div className={styles.name}>Data Not Found</div>
                  </>
                ) : (
                  <SmallLoaderComponent />
                )}
              </div>
              <ReactTooltip
                id={`Tooltip-data-not-found`}
                place="top"
                type="dark"
              >
                <div className="tooltip-box">
                  Sorry, we didn't find any emails for these profiles
                </div>
              </ReactTooltip>
            </div>
            {search && (
              <div className={styles.searchBox}>
                <label>Search: </label>
                <span onClick={download} className={styles.download}>
                  <i className="fal fa-download"></i> Download profiles
                </span>
                {search.campaign && (
                  <a
                    href={`/campaign/edit/${search.campaign}`}
                    className={styles.campaign}
                  >
                    {" "}
                    Campaign
                  </a>
                )}
              </div>
            )}

            {total ? (
              <section className={styles.tableContainer}>
                <SearchLeadsTableComponent
                  rowsPerPageLeads={rowsPerPage}
                  page={page}
                  total={total}
                  progressPending={loading}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  leads={leads}
                  refresh={refreshUsers}
                  paginationServer={true}
                />
              </section>
            ) : (
              <div className={styles.loaderContainer}>
                <SmallLoaderComponent />
              </div>
            )}
          </section>
        ) : (
          <Loading />
        )}
      </div>
    </CampaignLayout>
  );
}
