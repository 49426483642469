import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./sales-navigator-reuse.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";
import { useNavigate } from "@reach/router";

const SalesNavigatorReuseModal = forwardRef((props, ref) => {
  const [isValid, setIsValid] = useState(true);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [noLinkedIn, setNoLinkedIn] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;

  const name = useInputValue("");
  const limit = useInputValue("");
  const search = useInputValue("");
  const cookie = useInputValue("");
  const campaign = useInputValue("");

  const premium = useInputValue(false);
  const personalEmail = useInputValue(true);

  const profilesChange = (e) => {
    limit.onChange(e);
    const cost = premium.value ? 2 : 1;
    if (cost * e.target.value <= value.user.credit) {
      if (e.target.value > 2500) {
        setReachedLimit(true);
      } else {
        setReachedLimit(false);
      }
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${api}lead/campaigns`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCampaigns(data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cost = premium.value ? 2 : 1;
    if (cost * limit.value <= value.user.credit) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premium.value]);

  useImperativeHandle(ref, () => ({
    open(previousSearch) {
      if (previousSearch) {
        let splitURL = previousSearch.search.split("?");
        let searchParams = new URLSearchParams(splitURL[1]);
        let newPage = 0;

        if (searchParams.get("page")) {
          newPage = searchParams.get("page") * 1;
          newPage += (previousSearch.rate * 1) / 25;
          searchParams.set("page", Math.ceil(newPage) + 1);
          search.setValue(`${splitURL[0]}?${searchParams.toString()}`);
        } else {
          newPage += (previousSearch.rate * 1) / 25;
          search.setValue(
            `${previousSearch.search}&page=${Math.ceil(newPage) + 1}`
          );
        }

        name.setValue(`${previousSearch.name}-Page:${Math.ceil(newPage) + 1}`);
        cookie.setValue(previousSearch.cookie);
      }
    },
  }));

  const start = () => {
    let body;

    body = {
      name: name.value,
      search: search.value,
      rate: limit.value,
      campaign: campaign.value !== "" ? campaign.value : undefined,
      user: value.user,
      cookie: cookie.value,
      premium: premium.value,
      personalEmail: personalEmail.value,
    };

    if (!search.value || !limit.value || limit.value === "" || !name.value) {
      toast.error("Fields Required");
      return;
    }

    if (reachedLimit) {
      toast.error(
        "You exceeded the maximum of profiles you can get per search"
      );
      return;
    }

    const promise = axios
      .post(`${api}linkedin-search/add-manual`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.setOpen(false);
          navigate(`/linkedin-searchs/${data._id}`, { replace: true });
        }
      });

    toast.promise(promise, {
      loading: "Processing...",
      success: "Capture process started!",
      error: (err) => {
        console.log(err.message);
        return err.message ? err.message : "Uh oh, there was an error!";
      },
    });
  };

  useEffect(() => {
    if (
      search.value &&
      search.value.indexOf("https://www.linkedin.com") === -1
    ) {
      setNoLinkedIn(true);
    } else {
      setNoLinkedIn(false);
    }
  }, [search.value]);

  return (
    <ModalLayout
      title={"Reuse Sales Navigator Search"}
      open={true}
      close={props.setOpen}
      start={start}
    >
      <>
        <div className={styles.formGroup}>
          <label className={styles.labelsInput}>
            Search Title <span>Required</span>{" "}
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Ex. United States + IT"
            value={name.value}
            onChange={name.onChange}
            required=""
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.labelsInput}>Campaign</label>
          <select onChange={campaign.onChange}>
            <option value="">Select</option>
            {campaigns &&
              campaigns.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>

        <div>
          <div className={styles.formGroup}>
            <label className={styles.labelsInput}>
              LinkedIn URL <span>Required</span>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Paste LinkedIn search URL here..."
              value={search.value}
              onChange={search.onChange}
              required=""
              disabled={true}
            />
            {noLinkedIn && (
              <p className={styles.creditError}>Invalid LinkedIn URL</p>
            )}
          </div>
        </div>

        <div>
          <div className={styles.formGroup}>
            <label className={styles.labelsInput}>
              how many profiles to capture (max: 2500)? <span>Required</span>
            </label>
            <input
              type="number"
              min="1"
              className="form-control"
              id="many-profiles"
              placeholder="Ex. 10"
              value={limit.value}
              onChange={profilesChange}
              required=""
            />
            <div className={styles.creditsText}>
              You have:{" "}
              {value.user && value.user.credit ? value.user.credit : 0} credits,
              every profile is equivalent to {premium.value ? "2" : "1"}{" "}
              credit(s).
            </div>
            {!isValid && (
              <div className={styles.creditError}>
                You exceeded the credit limit
              </div>
            )}
            {reachedLimit && (
              <div className={styles.creditError}>
                You exceeded the maximum of profiles you can get per search
              </div>
            )}
          </div>
        </div>

        <div>
          <div className={styles.formGroup}>
            <label className={styles.premiumFields}>
              <input
                type="checkbox"
                onChange={(e) => premium.setValue(e.target.checked)}
              />{" "}
              Get premium fields if found
            </label>
            <div className={styles.premium}>
              <div className={styles.item}>Phone</div>
            </div>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="">If nothing's found, get:</label>
          <label className={styles.checkboxField}>
            <input
              type="checkbox"
              onChange={(e) => personalEmail.setValue(e.target.checked)}
              checked={personalEmail.value}
            />{" "}
            Personal email
          </label>
          {personalEmail.value === false && (
            <div className={styles.warning}>
              This will increase your search results. Recommended if you’re
              targeting small businesses
            </div>
          )}
        </div>
      </>
    </ModalLayout>
  );
});

export default SalesNavigatorReuseModal;
