import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./contact-import.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";

const ContactImportModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const [countries, setCountries] = useState([]);
  const [count, setCount] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [searchsLN, setSearchsLN] = useState([]);
  const [searchesAI, setSearchesAI] = useState([]);
  const [campaignMain, setCampaignMain] = useState(undefined);
  const [broadcastDestiny, setbroadcastDestiny] = useState(undefined);

  const company = useInputValue("");
  const industry = useInputValue("");
  const country = useInputValue("");
  const campaign = useInputValue("");
  const broadcast = useInputValue("");
  const salesNav = useInputValue("");
  const aiSearch = useInputValue("");
  const captured = useInputValue(false);
  const title = useInputValue("");

  useEffect(() => {
    axios
      .get(`${api}lead/filters`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCountries(data.countries);
        }
      });

    axios
      .get(`${api}lead/campaigns`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCampaigns(data);
        }
      });

    axios
      .get(`${api}lead/broadcasts`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setBroadcasts(data);
      });

    axios
      .get(`${api}lead/searchs-sn`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setSearchsLN(data.filter((list) => list.name !== undefined));
        }
      });
    axios
      .get(`${api}lead/searches-ai`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setSearchesAI(data.filter((list) => list.name !== undefined));
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${api}lead/import-count`, {
        params: {
          industry: industry.value,
          user: value.user._id,
          company: company.value,
          country: country.value,
          captured: captured.value,
          campaign: campaign.value,
          broadcast: broadcast.value,
          title: title.value,
          salesNav: salesNav.value,
          aiSearch: aiSearch.value,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCount(data.total);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industry, company, country, campaign, captured, salesNav, aiSearch]);

  useImperativeHandle(ref, () => ({
    open(c = undefined, broadcast = undefined) {
      setCampaignMain(c);
      setbroadcastDestiny(broadcast);
      title.setValue("");
      company.setValue("");
      industry.setValue("");
      country.setValue("");
      campaign.setValue("");
      salesNav.setValue("");
      aiSearch.setValue("");
      captured.setValue(false);
    },
  }));

  const filter = () => {
    let body = {
      industry: industry.value,
      user: value.user._id,
      company: company.value,
      country: country.value,
      captured: captured.value,
      campaign: campaign.value,
      broadcast: broadcast.value,
      salesNav: salesNav.value,
      aiSearch: aiSearch.value,
      title: title.value,
      campaignMain: campaignMain?._id,
      broadcastDestiny: broadcastDestiny?._id,
    };

    const promise = axios
      .get(`${api}lead/import-contacts`, { params: body })
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.refresh();
          props.setOpen(false);
        }
      });
    toast.promise(promise, {
      loading: "Importing...",
      success: "Import completed!",
      error: (error) => (error ? error : "Uh oh, there was an error!"),
    });
  };

  return (
    <ModalLayout
      title={"Filter Contacts"}
      open={true}
      close={props.setOpen}
      filter={filter}
    >
      <>
        <div className={styles.formGroup}>
          <label>Job title</label>
          <input
            type="text"
            placeholder="Job title"
            onChange={title.onChange}
            value={title.value}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Industries</label>
          <input
            type="text"
            placeholder="Industries"
            onChange={industry.onChange}
            value={industry.value}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Company Name</label>
          <input
            type="text"
            placeholder="Company"
            onChange={company.onChange}
            value={company.value}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Country</label>
          <select onChange={country.onChange}>
            <option value="">Select</option>
            {countries &&
              countries.map((c, i) => {
                return (
                  <option key={`country-${i}`} value={c}>
                    {c}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Used in this campaign</label>
          <select onChange={campaign.onChange}>
            <option value="">Select</option>
            {campaigns &&
              campaigns.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Used in this broadcast</label>
          <select onChange={broadcast.onChange}>
            <option value="">Select</option>
            {broadcasts &&
              broadcasts.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Used in this sales navigator search</label>
          <select onChange={salesNav.onChange}>
            <option value="">Select</option>
            {searchsLN &&
              searchsLN.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Used in this Automated Inbox search</label>
          <select onChange={aiSearch.onChange}>
            <option value="">Select</option>
            {searchesAI &&
              searchesAI.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={styles.formGroup}>
          <label>Get just captured contacts</label>
          <div className={styles.captured}>
            <input
              type="checkbox"
              onChange={(e) => captured.setValue(e.target.checked)}
              checked={captured.value}
            />
            <span>Just captured</span>
          </div>
        </div>
        <div className={styles.count}>
          With these filters you are going to import: {count} contacts
        </div>
      </>
    </ModalLayout>
  );
});

export default ContactImportModal;
