/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./broadcast.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import toast from "react-hot-toast";
import { Context } from "../../../Context";
import { DateTime } from "luxon";
import { useNavigate } from "@reach/router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "../../../styled-components/input.styled";
import { Select } from "../../../styled-components/select.styled";
import { useSelector } from "react-redux";

const BroadCastModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const user = useSelector((store) => store.user);
  const value = useContext(Context);
  const [senders, setSenders] = useState([]);
  const [schedule, setSchedule] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required().min(3),
    sender: yup.string().required(),
    type: yup.string().required(),
    dailyLimit: yup.number().min(1),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let body = {
      ...data,
    };

    const api = process.env.REACT_APP_API;
    let schedule;

    if (getValues("date") && getValues("time")) {
      schedule = DateTime.fromFormat(
        `${getValues("date")} ${getValues("time")}`,
        `yyyy-MM-dd HH:mm`
      );
    }

    body = {
      ...body,
      schedule: schedule ? schedule.toJSDate() : undefined,
    };

    if (getValues("id")) {
      const promise = axios
        .post(`${api}broadcast/edit/${props.broadcast._id}`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            props.reload();
            props.setOpen();
          }
        });

      toast
        .promise(promise, {
          loading: "Updating Broadcast...",
          success: "Broadcast Updated Successful!",
          error: "Uh oh, there was an error!",
        })
        .finally(() => props.setBroadcast(undefined));
    } else {
      if (value.user._id !== user._id) {
        body.user = value.user._id;
      }
      const promise = axios
        .post(`${api}broadcast/add`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            navigate(`/broadcast/edit/${data._id}`);
            props.setOpen();
          }
        });

      toast
        .promise(promise, {
          loading: "Creating Broadcast...",
          success: "Broadcast Created Successful!",
          error: "Uh oh, there was an error!",
        })
        .finally(() => props.setBroadcast(undefined));
    }
  };

  const close = () => {
    props.setBroadcast(undefined);
    props.setOpen();
  };

  useEffect(() => {
    if (value.user) {
      axios.get(`${api}sender/get/user/${value.user._id}`).then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setSenders(data);
        }
      });
    }
  }, [value.user]);

  useEffect(() => {
    if (props.broadcast) {
      setValue("id", props.broadcast._id);
      setValue("name", props.broadcast.name);
      setValue("type", props.broadcast.type);
      setValue("sender", props.broadcast.sender._id);
      setValue("dailyLimit", props.broadcast.dailyLimit);

      if (props.broadcast.schedule) {
        setSchedule(true);
        let _time = DateTime.fromISO(props.broadcast.schedule).toFormat(
          "HH:mm"
        );
        let _date = DateTime.fromISO(props.broadcast.schedule).toFormat(
          "yyyy-MM-dd"
        );
        setValue("time", _time);
        setValue("date", _date);
      }
    }
  }, [props.broadcast, senders]);

  return (
    <ModalLayout
      title={"Broadcast"}
      open={true}
      close={close}
      customButton={handleSubmit(onSubmit)}
      customButtonText={props.broadcast ? "Update" : "Create"}
    >
      <form>
        <div className={styles.formGroup}>
          <label>
            Title <span>required</span>
          </label>
          <Input
            type="text"
            placeholder="My Unique Broadcast"
            {...register("name")}
            error={errors.name}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Type</label>
          <Select {...register("type")} error={errors.type}>
            <option value="">Select</option>
            <option value="newsletters">Newsletters</option>
            <option value="product-updates">Product Updates</option>
            <option value="digests">Digests</option>
            <option value="sales">Sales</option>
            <option value="promotion">Promotion</option>
          </Select>
        </div>

        <div className={styles.formGroup}>
          <label>
            Send From <span>required</span>
          </label>
          <Select {...register("sender")} error={errors.sender}>
            <option value="">Select</option>
            {senders.map((sender) => {
              return (
                <option
                  key={sender._id}
                  value={sender._id}
                >{`${sender.name} <${sender.email}>`}</option>
              );
            })}
          </Select>
        </div>

        <div className={styles.formGroup}>
          <label>Daily Limit</label>
          <Input
            type="number"
            placeholder="Daily Limit"
            defaultValue={100}
            {...register("dailyLimit")}
            error={errors.dailyLimit}
          />
        </div>

        <div className={styles.formGroup}>
          <div className={styles.captured}>
            <Input
              type="checkbox"
              id="schedule"
              onChange={(e) => setSchedule(e.target.checked)}
              checked={schedule}
            />
            <span htmlFor="schedule">Schedule</span>
          </div>
        </div>
        {schedule && (
          <div className={styles.formGroup}>
            <label>Schedule Date</label>
            <div className={styles.dateTime}>
              <Input
                type="date"
                min={DateTime.now().toFormat("yyyy-MM-dd")}
                {...register("date")}
                defaultValue={DateTime.now().toFormat("yyyy-MM-dd")}
                datetime
                error={errors.date}
              />
              <Input
                type="time"
                {...register("time")}
                datetime
                error={errors.time}
                defaultValue={DateTime.now().toFormat("HH:mm")}
              />{" "}
            </div>
          </div>
        )}
      </form>
    </ModalLayout>
  );
});

export default BroadCastModal;
