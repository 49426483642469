import React from "react";
import styles from "./small-loader.module.scss";

export default function SmallLoaderComponent() {
  return (
    <div className={styles.box}>
      <i className="far fa-spinner-third fa-spin"></i>
    </div>
  );
}
