import { createSlice } from "@reduxjs/toolkit";

export const broadcastSlice = createSlice({
  name: "broadcast",
  initialState: {},
  reducers: {
    createBroadcast: (state, action) => action.payload,
    modifyBroadcast: (state, action) => ({ ...state, ...action.payload }),
    resetBroadcast: () => {},
  },
});

export const { createBroadcast, modifyBroadcast, resetBroadcast } =
  broadcastSlice.actions;

export default broadcastSlice.reducer;
