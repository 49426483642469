import React from "react";
import styles from "./toggle-status.module.scss";
import cx from "classnames";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import toast from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import { useContext } from "react";
import { Context } from "../../Context";

export default function ToggleStatusComponent({
  campaign,
  broadcast,
  reload,
  noLeads,
  unverified,
  refresh,
}) {
  const api = process.env.REACT_APP_API;
  const { user } = useContext(Context);

  const changeStatus = (e) => {
    if (unverified > 0) {
      const confirm = window.confirm(
        "This campaign have unverified leads, do you want to continue ?"
      );
      if (!confirm) {
        return;
      }
    }

    if (e.target.checked) {
      if (noLeads) {
        toast.error("You need to add at least one valid lead");
        return;
      }

      const confirm = window.confirm(
        "Are you sure you want to run your campaign ?"
      );
      if (!confirm) {
        return;
      }
    }

    let body = {
      status: e.target.checked ? "RUNNING" : "STOPPED",
    };

    if (campaign) {
      body["id"] = campaign._id;
    }

    if (broadcast) {
      body["id"] = broadcast._id;
    }

    axios.post(`${api}campaigns/edit/status`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        reload();
      }
    });
  };

  const changeBroadcastStatus = (e) => {
    if (unverified > 0) {
      const confirm = window.confirm(
        "This broadcast have unverified leads, do you want to continue ?"
      );
      if (!confirm) {
        return;
      }
    }

    if (e.target.checked) {
      if (noLeads) {
        toast.error("You need to add at least one valid lead");
        return;
      }

      const confirm = window.confirm(
        "Are you sure you want to turn on your broadcast ?"
      );
      if (!confirm) {
        return;
      }
    }

    let body = {
      status: e.target.checked ? "RUNNING" : "STOPPED",
    };

    if (broadcast) {
      body["id"] = broadcast._id;
    }

    axios.post(`${api}broadcast/change-status`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        reload();
      }
    });
  };

  const verifyAllProfile = (n) => {
    if (unverified === 0) {
      toast.error("No available leads for verify");
      return;
    }
    if (user.credit < unverified) {
      toast.error("You don't have credits");
      return;
    }

    let message = `Are you sure you want to verify the leads for ${unverified} credit(s)?`;
    if (window.confirm(message)) {
      const body = {
        campaign: campaign?._id,
        broadcast: broadcast?._id,
        user: user._id,
        email: user.email,
      };

      const promise = axios.post(`${api}lead/verify-all`, body).then(() => {
        console.log("verified");
        refresh();
      });

      toast.promise(promise, {
        loading: "Verifying...",
        success: "Verification process started!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  return (
    <div className={styles.toggleContainer}>
      {campaign && (
        <>
          <div className={styles.text}>
            {unverified > 0 && (
              <>
                <span
                  onClick={() => verifyAllProfile()}
                  className={cx(styles.qtyLeads)}
                >
                  <i
                    className="fad fa-exclamation-circle"
                    style={{
                      color: "#ff9800",
                      fontSize: 16,
                      position: "relative",
                      top: 2,
                      left: -4,
                    }}
                    data-tip
                    data-for={`Tooltip-un-verified-global`}
                  ></i>{" "}
                </span>
                <ReactTooltip
                  id={`Tooltip-un-verified-global`}
                  place="top"
                  type="dark"
                >
                  <div className="tooltip-box">
                    This {campaign ? "campaign" : "broadcast"} have unverified
                    leads, if you want to verify it have a cost of {unverified}{" "}
                    credit
                  </div>
                </ReactTooltip>
              </>
            )}{" "}
            Run campaign
          </div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={campaign.status === "RUNNING"}
              onChange={(e) => changeStatus(e)}
            />
            <span className={cx(styles.slider, styles.round)}></span>
          </label>
        </>
      )}
      {broadcast && (
        <>
          <div className={styles.text}>Run broadcast</div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={broadcast.status === "RUNNING"}
              onChange={(e) => changeBroadcastStatus(e)}
            />
            <span className={cx(styles.slider, styles.round)}></span>
          </label>
        </>
      )}
    </div>
  );
}
