import React, { useContext, useEffect } from "react";
import styles from "./billing.module.scss";
import { useForm } from "react-hook-form";
import { Context } from "../../../Context";
import { useState } from "react";
import PaymentComponent from "../../payment/payment.component";
import axios from "axios";

export default function BillingComponent() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [creditCards, setCreditCards] = useState([]);
  const onSubmit = (data) => console.log(data);
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [addCard, setAddCard] = useState(false);

  const updateList = () => {
    axios
      .get(`${api}user/all-cc`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.data) {
          setCreditCards(data.data);
        }
      })
      .catch((error) => {
        if (
          error.message?.indexOf("a similar object exists in test mode") !== -1
        ) {
          axios
            .post(`${api}user/remove-cc`, { user: value.user._id })
            .then(({ data }) => {
              console.log("removed customer id");
            });
          setCreditCards([]);
        }
      });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <div className={styles.tabContainer}>
      <h2>Billing</h2>
      <div className={styles.tabContainerBody}>
        <h3>Credit Cards</h3>

        <div className={styles.myCreditCards}>
          {creditCards.map((cc) => {
            return (
              <div className={styles.creditCardsGrid} key={cc.id}>
                <div className="brand">
                  {cc.brand === "Visa" && <i className="fab fa-cc-visa"></i>}
                  {cc.brand === "MasterCard" && (
                    <i className="fab fa-cc-mastercard"></i>
                  )}
                  {cc.brand === "American Express" && (
                    <i className="fab fa-cc-amex"></i>
                  )}
                </div>
                <div className="data">
                  <div className={styles.number}>
                    {cc.brand} **** {cc.last4}
                  </div>
                  <div className={styles.expires}>
                    Expires {cc.exp_year}/{cc.exp_month}
                  </div>
                </div>
              </div>
            );
          })}
          {creditCards.length === 0 && (
            <div className={styles.noCard}>Add Your First Card</div>
          )}
        </div>
        <div className={styles.buttonMargin}>
          {!addCard ? (
            <div>
              <button onClick={() => setAddCard(true)}>Add new card</button>
            </div>
          ) : (
            <PaymentComponent
              user={value.user._id}
              setAddCard={setAddCard}
              updateList={updateList}
            />
          )}
        </div>

        <div className={styles.footer}>
          Powered by <i className="fab fa-stripe"></i>
        </div>
      </div>
    </div>
  );
}
