import React, { createContext } from "react";
import Pusher from "pusher-js";

export const PusherContext = createContext();

const pusher = new Pusher("cc33c2ae8584dc416ef2", {
  cluster: "us2",
});

function PusherProvider({ children }) {
  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

function usePusher() {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  return pusher;
}

export { PusherProvider, usePusher };
