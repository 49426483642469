import React, { useEffect, useState } from "react";
import styles from "./user.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../styled-components/input.styled";

const UserModal2 = (props) => {
  const api = process.env.REACT_APP_API;
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    first_name: yup.string().required().min(3),
    last_name: yup.string(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let body = {
      ...data,
    };

    body = {
      ...body,
    };

    if (getValues("id")) {
      const promise = axios
        .post(`${api}user/admin-edit`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            props.reload();
            props.setOpen(false);
          }
        });

      toast.promise(promise, {
        loading: "Updating user...",
        success: "User updated!",
        error: (error) => {
          return `${error.response.data.message}`;
        },
      });
    }
  };

  useEffect(() => {
    if (props.user) {
      setValue("id", props.user._id);
      setValue("first_name", props.user.first_name);
      setValue("last_name", props.user.last_name);
      setValue("email", props.user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  return (
    <ModalLayout
      title={"User"}
      open={true}
      close={props.setOpen}
      save={handleSubmit(onSubmit)}
    >
      <form>
        <div className={styles.formGroup}>
          <label>
            Email <span>required</span>
          </label>
          <Input
            type="text"
            placeholder="Email"
            {...register("email")}
            error={errors.email}
            className={styles.input}
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.formGroup}>
            <label>
              First Name <span>required</span>
            </label>
            <Input
              type="text"
              placeholder="First Name"
              {...register("first_name")}
              error={errors.first_name}
              className={styles.input}
            />
            {errors.first_name && (
              <span className={styles.errorMessage}>
                First name should at least have 3 letters
              </span>
            )}
          </div>
          <div className={styles.formGroup}>
            <label>Last Name</label>
            <Input
              type="text"
              placeholder="Last Name"
              {...register("last_name")}
              error={errors.last_name}
              className={styles.input}
            />
          </div>
        </div>
      </form>
    </ModalLayout>
  );
};

export default UserModal2;
