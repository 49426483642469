import React, { useEffect, useState } from "react";
import styles from "./warning-limit-exceeded.module.scss";

export default function WarningLimitExceededComponent({ user }) {
  const [totals, setTotals] = useState(0);

  useEffect(() => {
    if (user.leads > user.limits?.contacts) {
      setTotals(100);
    } else {
      setTotals((user.leads / user.limits?.contacts) * 100);
    }
  }, [user]);

  return (
    <div className="container">
      {totals > 75 && totals < 90 && (
        <div className={styles.limits75}>
          Your are above the 75% of your limit, if you want to increase it,
          contact our <a href="mailto:support@automatedinbox.com">support</a>
        </div>
      )}
      {totals >= 90 && totals <= 99 && (
        <div className={styles.limits90}>
          Your are above the 90% of your limit, if you want to increase it,
          contact our <a href="mailto:support@automatedinbox.com">support</a>
        </div>
      )}
      {totals > 99 && (
        <div className={styles.limits100}>
          Your reached the 100% of your limit, if you want to increase it,
          contact our <a href="mailto:support@automatedinbox.com">support</a>
        </div>
      )}
      {user.limitExceeded && (
        <div className={styles.limitsExceeded}>
          You exceeded the limit of contacts, please contact{" "}
          <a href="mailto:support@automatedinbox.com">support</a> or your
          account will be locked
        </div>
      )}
    </div>
  );
}
