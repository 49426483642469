import { Link, useNavigate } from "@reach/router";
import React from "react";
import { useContext } from "react";
import Truncate from "react-truncate";
import { Popover, Whisper } from "rsuite";
import { Context } from "../../Context";
import "./_Menu.scss";

export default function Menu({ campaign, broadcast }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  return (
    <div className="menu-container">
      <div className="container">
        <div className="menu">
          <Link to="/">Dashboard</Link>
          <Link to="/contacts">Contacts</Link>
          <Link to="/sales-navigator-searches">Sales Navigator Searches</Link>
          <Link to="/automated-inbox-searches">Automated Inbox Searches</Link>
          <Link to="/enrichments">Enrichments</Link>
          {campaign && (
            <>
              <div className="menu-sub">
                <Whisper
                  trigger="click"
                  placement={"bottomEnd"}
                  speaker={
                    <Popover
                      title={
                        <Truncate width={250} ellipsis={"..."}>
                          {campaign.name}
                        </Truncate>
                      }
                      visible
                      classPrefix="popover-menu-campaign"
                    >
                      <div className="options-container">
                        <div
                          className="option"
                          onClick={() =>
                            navigate(`/campaign/edit/${campaign._id}`, {
                              replace: true,
                            })
                          }
                        >
                          <i className="fa-duotone fa-address-book"></i>{" "}
                          Contacts
                        </div>
                        <div
                          className="option"
                          onClick={() =>
                            navigate(`/stats/${campaign._id}`, {
                              replace: true,
                            })
                          }
                        >
                          <i className="fa-duotone fa-chart-column"></i>
                          Stats
                        </div>
                        {campaign.version > 3.5 ? (
                          <div
                            className="option"
                            onClick={() =>
                              navigate(`/campaign/flow/${campaign._id}`, {
                                replace: true,
                              })
                            }
                          >
                            <i className="fa-duotone fa-diagram-nested"></i>
                            Email Sequence
                          </div>
                        ) : (
                          <div
                            className="option"
                            onClick={() =>
                              navigate(`/campaign/old/${campaign._id}`, {
                                replace: true,
                              })
                            }
                          >
                            <i className="fa-duotone fa-diagram-nested"></i>
                            Email Sequence
                          </div>
                        )}
                      </div>
                    </Popover>
                  }
                >
                  <div className="name-icon">
                    <div className="iconName">
                      <i className="fad fa-envelope"></i>
                      <Truncate width={120} ellipsis={"..."}>
                        {campaign.name}
                      </Truncate>
                    </div>
                    <i className="fa-regular fa-angle-down"></i>
                  </div>
                </Whisper>
              </div>
            </>
          )}
          {broadcast && (
            <>
              <div className="menu-sub">
                <Whisper
                  trigger="click"
                  placement={"bottomEnd"}
                  speaker={
                    <Popover
                      title={
                        <Truncate width={250} ellipsis={"..."}>
                          {broadcast.name}
                        </Truncate>
                      }
                      visible
                      classPrefix="popover-menu-campaign"
                    >
                      <div className="options-container">
                        <div
                          className="option"
                          onClick={() =>
                            navigate(`/broadcast/edit/${broadcast._id}`, {
                              replace: true,
                            })
                          }
                        >
                          <i className="fa-duotone fa-address-book"></i>{" "}
                          Contacts
                        </div>
                        <div
                          className="option"
                          onClick={() =>
                            navigate(`/stats-broadcast/${broadcast._id}`, {
                              replace: true,
                            })
                          }
                        >
                          <i className="fa-duotone fa-chart-column"></i>
                          Stats
                        </div>
                      </div>
                    </Popover>
                  }
                >
                  <div className="name-icon">
                    <div className="iconName">
                      <i className="fad fa-bullhorn"></i>
                      <Truncate width={120} ellipsis={"..."}>
                        {broadcast.name}
                      </Truncate>
                    </div>
                    <i className="fa-regular fa-angle-down"></i>
                  </div>
                </Whisper>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
