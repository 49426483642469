import React, { useEffect, useState } from "react";

export default function CountdownComponent({ setUpdate }) {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (timer === 0) {
      setUpdate(true);
      setTimer(60);
    }

    const timerObj = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => clearInterval(timerObj);
  }, [timer]);

  return <div className="information">Refreshing in {timer} seconds</div>;
}
