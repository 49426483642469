import React from "react";
import ReactTooltip from "react-tooltip";
import "./StatsBox.scss";

export default function StatsBox({ count, name }) {
  return (
    <>
      <div className={"box-stat"}>
        <div className="count">{count}</div>
        <div className="name">
          {name}{" "}
          <i
            className="fa-duotone fa-circle-info"
            data-tip
            data-for={`Tooltip-${name}`}
          ></i>
        </div>
      </div>
      <ReactTooltip id={`Tooltip-Processed`} place="right" type="dark">
        <>
          Email has been received by our sending server and is ready to be
          delivered to prospects/recipients.
        </>
      </ReactTooltip>
      <ReactTooltip id={`Tooltip-Bounces`} place="right" type="dark">
        <>
          A bounce occurs when a sent message is rejected by the
          prospect/recipient’s mail server. Possible reasons include misspelled
          or nonexistent email addresses.
        </>
      </ReactTooltip>
      <ReactTooltip id={`Tooltip-Dropped`} place="right" type="dark">
        <>
          This means your email will not be delivered to your
          prospect/recipient’s mail server. Possible reasons include (but not
          limited to) Spam Content (if Spam Checker app is enabled),
          Unsubscribed Address, Bounced Address, Invalid email address.
        </>
      </ReactTooltip>
    </>
  );
}
