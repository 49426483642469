import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./campaign-path-import.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";

const CampaignPathImportModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const [campaigns, setCampaigns] = useState([]);
  const campaign = useInputValue("");

  useEffect(() => {
    axios
      .get(`${api}lead/campaigns`, {
        params: {
          user: value.user._id,
          version: props.version || undefined,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCampaigns(data.filter((list) => list._id !== props.current._id));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalLayout
      title={"Import Email Sequence"}
      open={true}
      close={props.setOpen}
      customButtonText={"Import"}
      customButton={() =>
        props.import(campaigns.find((list) => list._id === campaign.value))
      }
    >
      <>
        <div className={styles.formGroup}>
          <label>Campaign</label>
          <select onChange={campaign.onChange}>
            <option value="">Select</option>
            {campaigns &&
              campaigns.map((c) => {
                return (
                  <option key={c._id} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
      </>
    </ModalLayout>
  );
});

export default CampaignPathImportModal;
