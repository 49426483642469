import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import styles from "./contacts-bar.module.scss";
import axios from "axios";

export default function ContactsBarComponent({ limit, total }) {
  const [totals, setTotals] = useState(0);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    if (total > limit) {
      setTotals(100);
      setLimitExceeded(true);

      if (value.user && !value.user.limitExceeded) {
        axios.post(`${api}user/edit`, {
          _id: value.user._id,
          limitExceeded: true,
        });
      }
    } else {
      setTotals((total / limit) * 100);
    }
  }, [limit, total]);

  return (
    <div className="container">
      <div className={styles.bar}>
        <div className={styles.filled} style={{ width: `${totals}%` }}></div>
      </div>
      <div className={styles.labels}>
        <div>Leads: {total}</div>
        <div>Limit: {limit}</div>
      </div>
    </div>
  );
}
