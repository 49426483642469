import React, { useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "@reach/router";
import ReactTooltip from "react-tooltip";
import { useState } from "react";
import moment from "moment";
import styles from "./campaign-small.module.scss";

export default function CampaignSmallComponent({
  campaign,
  edit,
  deleteCampaing,
}) {
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const [stats, setStats] = useState(undefined);

  const percentage = (value = 0, total = 0) => {
    if (total === 0) {
      return 0;
    }

    let calc = (value / total) * 100;
    return calc.toFixed(2);
  };

  useEffect(() => {
    axios
      .get(`${api}campaigns/get/single-stats/${campaign._id}`)
      .then(({ data }) => {
        setStats(data);
      });
  }, []);

  return (
    <div className="campaigns__list-box" key={campaign._id}>
      <div
        className={`status ${campaign.status}`}
        onClick={() => navigate(`/campaign/edit/${campaign._id}`)}
      >
        {campaign.status === "RUNNING" && (
          <i className="fas fa-bolt fa-fade"></i>
        )}
        {campaign.status === "STOPPED" && (
          <i className="fa-duotone fa-snooze"></i>
        )}
        {campaign.status === "COMPLETED" && (
          <i className="fa-duotone fa-flag-checkered"></i>
        )}
      </div>
      <div className="details">
        <div className="details-data">
          <div className="name">{campaign.name}</div>
          {campaign.sender && (
            <div className="campaign_sender">
              <span>
                <b>Send From:</b> {campaign.sender.name}{" "}
                {`<${campaign.sender.email}>`}
              </span>
              {campaign.schedule && (
                <span>
                  <b>Scheduled:</b> {moment(campaign.schedule).format("LLL")}
                </span>
              )}
              <span>
                <b>Created:</b> {moment(campaign.createdAt).format("LLL")}
              </span>
              <span>
                <b>Version:</b>{" "}
                <span className={styles.versionBadge}>{campaign.version}</span>
              </span>
            </div>
          )}
          <div className="stats">
            <div className="leads" data-tip data-for="leads-tooltip">
              <i className="fas fa-user"></i>
              {stats ? stats.leads : "-"}
            </div>

            <div className="sent" data-tip data-for="sent-tooltip">
              <i className="fas fa-share"></i>
              {stats ? stats.sent : "-"}
            </div>

            <div className="open" data-tip data-for="open-tooltip">
              <i className="fas fa-envelope-open-text"></i>
              {stats ? percentage(stats.open, stats.sent) : "-"}%
            </div>

            <div className="click" data-tip data-for="click-tooltip">
              <i className="fas fa-hand-point-up"></i>
              {stats ? stats.click : "-"}
            </div>
          </div>
          <ReactTooltip id={`leads-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Leads in this campaign</div>
          </ReactTooltip>
          <ReactTooltip id={`sent-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Emails Sent</div>
          </ReactTooltip>
          <ReactTooltip id={`open-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Open Rate</div>
          </ReactTooltip>
          <ReactTooltip id={`click-tooltip`} place="right" type="dark">
            <div className="tooltip-box">Clicks per Campaign</div>
          </ReactTooltip>
        </div>
        <div className="actions">
          <p onClick={() => edit(campaign)}>Edit</p>
          <Link to={`/campaign/edit/${campaign._id}`}>Manage</Link>
          <Link to={`/stats/${campaign._id}`}>Stats</Link>
          <p onClick={() => deleteCampaing(campaign)}>Delete</p>
        </div>
      </div>
      {/* <div className="actions">
    <p onClick={() => edit(c)}>Edit</p>
    <Link to={`/campaign/edit/${campaign._id}`}>Manage</Link>
    <Link to={`/stats/${campaign._id}`}>Stats</Link>
  </div> */}
    </div>
  );
}
