import React, { useContext, forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";
import toast, { Toaster } from "react-hot-toast";
import styles from "./lead.module.scss";
import { Context } from "../../Context";

const LeadModal = forwardRef((props, ref) => {
  const value = useContext(Context);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [sampleLeads, setSampleLeads] = useState([]);
  const [user, setUser] = useState(undefined);
  const [max, setMax] = useState(false);
  const [campaign, setCampaign] = useState(undefined);
  const [broadcast, setBroadcast] = useState(undefined);
  const [verify, setVerify] = useState(false);

  useImperativeHandle(ref, () => ({
    open(user = undefined, campaign = undefined, total, broadcast = undefined) {
      setUser(user);
      setCampaign(campaign);
      setBroadcast(broadcast);
      setSampleLeads([]);
      setLeads([]);
      setTotalLeads(total);
      document.querySelector('input[type="file"]').value = "";
      var modal = document.getElementById("ownList");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      const data = readString(reader.result);
      const leads = [];
      data.data.filter((x) => {
        if(!x.every(e => e === "")){
          return x;
        }
      }).map((lead, i) => {
        if (i > 0) {
          let email = "";
          if (lead[2]) {
            email = lead[2];
          }
          leads.push({
            first_name: lead[0],
            last_name: lead[1],
            email: email,
            companyName: lead[3],
            industry: lead[4],
            status: "un-verified",
            mailStatus: "ACTIVE",
            user: user._id,
            userEmail: `${email.toLocaleLowerCase()}_${user._id}`,
            campaign: campaign,
          });
        }
      });
      if (totalLeads + leads.length > user.limits.contacts) {
        toast.error(
          "with this upload you reached the limit of contacts in your account, please contact support to increase your limits."
        );
        setMax(true);
      } else {
        setMax(false);
        setLeads(leads);
        setSampleLeads(leads.slice(0, 10));
      }
    };
    reader.readAsText(files.target.files[0]);
  };
  const openBuyValidation = () =>{
    console.log("clicked")
    props.openBuyValidation()
    close();
  }
  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (leads.length === 0) {
      return;
    }

    if (verify) {
      if (user.validation_credit <= 0 || user.validation_credit == undefined) {
        toast.error("You don't have enough validation credits");
        return;
      }
    }
    const validate_total = !verify ? 0 : (user.validation_credit >= leads.length ? leads.length : user.validation_credit);

    body = {
      campaign: campaign,
      broadcast: broadcast,
      user: user._id,
      leads: leads,
      verify: verify,
      validate_total
    };

    props.showLoading();
    const promise = axios
      .post(`${api}lead/add-multiple`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.loadData();
          setSampleLeads([]);
          close();
        }
      })
      .finally(() => {
        props.closeLoading();
      });
    
    toast.promise(promise, {
      loading: "Loading...",
      success: "Uploaded list successfully!",
      error: "Uh oh, there was an error!",
    });
  };

  const close = () => {
    setSampleLeads([]);
    var modal = document.getElementById("ownList");
    modal.style.display = "none";
  };

  const openHowItWorks = () => {
    props.openHowItWorks("leads");
  };

  return (
    <div id="ownList" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12 header-modal">
              <h2>Leads</h2>
              <div className={"how-it-works"} onClick={() => openHowItWorks()}>
                Show me how it works
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group position-relative">
                <label>
                  Upload CSV <span className="required">required</span>
                </label>
                <input
                  type="file"
                  accept=".csv"
                  className="form-control"
                  onChange={handleFiles}
                  required=""
                />
              </div>
              {sampleLeads.length > 0 && (
                <>
                  <label>File Preview</label>
                  <div className="preview-box">
                    <table>
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Company Name</th>
                          <th>Industry</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sampleLeads.map((slead, i) => {
                          return (
                            <tr key={`samplelist-${i}`}>
                              <td>{slead.first_name}</td>
                              <td>{slead.last_name}</td>
                              <td>{slead.email}</td>
                              <td>{slead.companyName}</td>
                              <td>{slead.industry}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div
                className="bandge download-sample"
                onClick={() =>
                  window.open(
                    "https://automatedinbox.s3-ap-southeast-1.amazonaws.com/automatedinbox-sample.csv",
                    "_blank"
                  )
                }
              >
                download sample file
              </div>
            </div>

            <div>
              <div className={styles.formGroup}>
                {!verify && (
                  <div className={styles.unverified}>
                    WARNING: You're about to upload {leads.length} number of
                    leads. Before sending to these leads we recommend cleaning
                    and verifying the data to avoid bounces, spam flags, and
                    domain blacklisting. Our system can do this for you
                    automatically for (Estimated Credits = {leads.length})
                  </div>
                )}
                <label className={styles.premiumFields}>
                  <input
                    type="checkbox"
                    onChange={(e) => setVerify(e.target.checked)}
                  />{" "}
                  Verify all leads for {leads.length} validation credits
                </label>
                {value.user.validation_credit < leads.length && verify && value.user.validation_credit > 0 &&(
                  <div className={styles.not_enough_credits}>You only have {value.user.validation_credit} validation credits left. We can only validate the first {value.user.validation_credit} leads in your list. </div>  
                )}
                
                <button className={styles.get_validation_credits} onClick={openBuyValidation}>
                 You have {value.user.validation_credit ? value.user.validation_credit : 0} validation credits, <b>Get more!</b>
                </button>
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button
              type="button"
              onClick={save}
              disabled={max}
              className="button primary"
            >
              Upload
            </button>
            <button type="button" onClick={close} className="button secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LeadModal;
