import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorage } from "../../utils/localstorage.utility";

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    if (getLocalStorage("userAdmin")) {
      return JSON.parse(getLocalStorage("userAdmin"));
    }

    if (getLocalStorage("user")) {
      return JSON.parse(getLocalStorage("user"));
    }

    return {};
  },
  reducers: {
    createUser: (state, action) => action.payload,
    modifyUser: (state, action) => ({ ...state, ...action.payload }),
    resetUser: () => {},
  },
});

export const { createUser, modifyUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
