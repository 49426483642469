import React, { useContext, useEffect } from "react";
import styles from "./purchases.module.scss";
import { useForm } from "react-hook-form";
import { Context } from "../../../Context";
import { useState } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";

export default function PurchaseComponent() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [purchases, setPurchases] = useState([]);
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [addCard, setAddCard] = useState(false);

  const updateList = () => {
    axios.get(`${api}purchases/get/${value.user._id}`).then(({ data }) => {
      setPurchases(data);
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <div className={styles.tabContainer}>
      <h2>Purchases</h2>
      <div className={styles.tabContainerBody}>
        <div className={styles.myPurchases}>
          {purchases.map((purchase) => {
            return (
              <div className={styles.purchaseGrid} key={purchase.id}>
                <span>
                  <div className={styles.amount}>
                    <NumberFormat
                      value={purchase.amount}
                      prefix={"$"}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </div>
                  <div>
                    <div className={styles.charge}>
                      charge: {purchase.charge}
                    </div>
                    <div className={styles.date}>
                      {moment(purchase.createdAt).format("LLL")}
                    </div>
                  </div>
                </span>
                <a
                  href={purchase.receipt_url}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  <i className="fa-duotone fa-receipt"></i>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
