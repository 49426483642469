import { useNavigate } from "@reach/router";
import moment from "moment";
import React from "react";
import Status from "../status/status.component";
import styles from "./automated-search-card.module.scss";
import cx from "classnames";

export default function AutomatedSearchCard({ sales }) {
  const navigate = useNavigate();

  const getStatus = (search) => {
    if (search && search.status) {
      if (search.status === "pending") {
        return "Running";
      }

      if (search.status === "completed") {
        return "Completed";
      }
    }
    return "Completed";
  };

  return (
    <div
      className={styles.enrichmentCard}
      onClick={() => navigate(`/automated-inbox-searches/${sales._id}`)}
    >
      <div
        className={cx(
          styles.iconStatus,
          sales?.status === "completed" && styles.completed,
          !sales.status && styles.completed
        )}
      >
        {(sales.status === "completed" || !sales.status) && (
          <div>
            <i className="far fa-check"></i>
          </div>
        )}

        {sales?.status === "pending" && (
          <div>
            <i className="fas fa-circle-notch fa-spin"></i>
          </div>
        )}
      </div>
      <div>
        <h2>{sales.name}</h2>
        <div className={styles.metadata}>
          <span>
            <b>Status:</b> {getStatus(sales)}
          </span>
          <span>
            <b>Created date:</b>
            {moment(sales.createdAt).format("LLL")}
          </span>
        </div>
        <div className={styles.metadata}>
          <span>
            <b>Premium:</b> {sales.premium ? "Yes" : "No"}
          </span>
          <span>
            <b>Personal Email:</b> {sales.personalEmail ? "Yes" : "No"}
          </span>
          <span>
            <b>Profiles Requested:</b> {sales.rate}
          </span>
        </div>
        {sales.message && (
          <div className={styles.metadata}>
            <span>
              <b>Message:</b> {sales.message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
