import React, { useContext, useEffect, useRef, useState } from "react";
import "./stats.scss";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import CampaignLayout from "../../layouts/campaign.layout";
import { Context } from "../../Context";
import moment from "moment";
import StatsBox from "../../components/stats-box/StatsBox";
import { Bar } from "react-chartjs-2";
import EmailComponent from "../../components/stats/email/email.component";
import ReactTooltip from "react-tooltip";
import { DateTime } from "luxon";

export default function StatsPage({ id }) {
  const [user, setUser] = useState(undefined);
  const [charts, setCharts] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  const [sent, setSent] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [opened, setOpened] = useState(0);
  const [bounces, setBounces] = useState(0);
  const [click, setClick] = useState(0);
  const [dropped, setDropped] = useState(0);
  const [optOut, setOptOut] = useState(0);
  const [responded, setResponded] = useState(0);

  const [chartsData, setChartsData] = useState([]);
  const [flows, setFlows] = useState([]);
  const [campaign, setCampaign] = useState(undefined);
  const [linkedinSearchs, setLinkedinSearchs] = useState([]);
  const api = process.env.REACT_APP_API;
  const apiStats = process.env.REACT_APP_API_STATS;
  const value = useContext(Context);

  const campaingModalRef = useRef();

  const open = () => {
    campaingModalRef.current.open(user);
  };

  const edit = (campaign) => {
    campaingModalRef.current.open(user, campaign);
  };

  const options = {};

  useEffect(() => {
    axios.get(`${api}campaigns/get/${id}`).then(({ data }) => {
      setCampaign(data);
      console.log(data);
      if (data.flow) {
        if (data.version == 4) {
          setFlows(data.flow);
        } else {
          setFlows(data.flow.filter((list) => list.type === "emailSelector"));
        }
      }
    });

    axios
      .get(`${apiStats}v1/stats/get-stats`, {
        params: {
          campaign: id,
          timezone: value.user.settings?.timezone,
        },
      })
      .then(({ data }) => {
        let labels = [];
        let values = [];

        data.stats.forEach((item) => {
          labels.push(
            `${moment()
              .month(item._id.month - 1)
              .format("MMM")} ${item._id.day}`
          );
          values.push(item.count);
        });

        if (data.statsCount.some((e) => e._id === "delivered")) {
          setDelivered(
            data.statsCount.find((e) => e._id === "delivered").count
          );
        }

        if (data.statsCount.some((e) => e._id === "open")) {
          setOpened(data.statsCount.find((e) => e._id === "open").count);
        }

        if (data.statsCount.some((e) => e._id === "processed")) {
          setSent(data.statsCount.find((e) => e._id === "processed").count);
        }

        if (data.statsCount.some((e) => e._id === "bounce")) {
          setBounces(data.statsCount.find((e) => e._id === "bounce").count);
        }

        if (data.statsCount.some((e) => e._id === "click")) {
          setClick(data.statsCount.find((e) => e._id === "click").count);
        }

        if (data.statsCount.some((e) => e._id === "dropped")) {
          setDropped(data.statsCount.find((e) => e._id === "dropped").count);
        }

        if (data.statsCount.some((e) => e._id === "opt-out")) {
          setOptOut(data.statsCount.find((e) => e._id === "opt-out").count);
        }

        if (data.statsCount.some((e) => e._id === "responded")) {
          setResponded(
            data.statsCount.find((e) => e._id === "responded").count
          );
        }

        setLabels(labels);
        setValues(values);
      });
  }, []);

  useEffect(() => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Emails Sent by Day",
          data: values,
          fill: true,
          lineTension: 0.5,
          backgroundColor: "#e43421",
          borderColor: "#d52d1b",
        },
      ],
    };
    setChartsData(data);
  }, [labels, values]);

  const getPercentage = (total, comparisson) => {
    if (total === 0) {
      return "0%";
    }
    let percentage = (comparisson / total) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  const download = (type) => {
    axios
      .get(`${api}lead/download/clicked-leads`, {
        params: {
          campaign: id,
          type: type,
          user: value.user._id,
          campaignName: campaign.name,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", `leads-${type}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <CampaignLayout user={value.user} campaign={campaign}>
      <div className="stats">
        {campaign ? (
          <section className="container">
            <h2>{campaign.name}</h2>
            <span className="general-info">Campaign ID: {campaign.uid}</span>
            {campaign.sender && (
              <div className="general-info">
                <b>Sender:</b> {campaign.sender.name}{" "}
                {`<${campaign.sender.email}>`}
              </div>
            )}
            <div className="statsContainer">
              <div className="stats-boxs">
                <StatsBox count={sent} name={"Processed"} />
                <div className="bigStats">
                  <div className="bigBox">
                    <div className="number">
                      {getPercentage(sent, delivered)}
                      <div className="hideNumber">{delivered}</div>
                    </div>
                    <div className="name">
                      Delivered{" "}
                      <i
                        className="fa-duotone fa-circle-info"
                        data-tip
                        data-for={`Tooltip-delivered`}
                      ></i>
                    </div>
                    <ReactTooltip
                      id={`Tooltip-delivered`}
                      place="right"
                      type="dark"
                    >
                      <>
                        Email has been successfully delivered from our sending
                        server to the prospect/recipient's email server.
                      </>
                    </ReactTooltip>
                  </div>
                  <div className="smallStats">
                    <div>
                      <div className="number">
                        {getPercentage(delivered, opened)}
                        <div className="hideNumber">{opened}</div>
                      </div>
                      <div className="name">
                        Opened{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-open`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("open")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-open`}
                        place="right"
                        type="dark"
                      >
                        <>Prospect/recipient has opened the email.</>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, click)}
                        <div className="hideNumber">{click}</div>
                      </div>
                      <div className="name">
                        Clicked{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-click`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("click")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-click`}
                        place="right"
                        type="dark"
                      >
                        <>
                          Recipient clicked on a link inside the email. This
                          could include a link to a webinar, your calendar, or
                          any link that you’ve set up in your email. We advise
                          you to only use one link per email (not including the
                          unsubscribe link)
                        </>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, optOut)}
                        <div className="hideNumber">{optOut}</div>
                      </div>
                      <div className="name">
                        Opt-Out{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-opt-out`}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-opt-out`}
                        place="right"
                        type="dark"
                      >
                        <>Recipient clicked on the “unsubscribe” link.</>
                      </ReactTooltip>
                    </div>
                    <div>
                      <div className="number">
                        {getPercentage(delivered, responded)}
                        <div className="hideNumber">{responded}</div>
                      </div>
                      <div className="name">
                        Responded{" "}
                        <i
                          className="fa-duotone fa-circle-info"
                          data-tip
                          data-for={`Tooltip-responded`}
                        ></i>
                        <i
                          className="fad fa-cloud-download"
                          onClick={() => download("responded")}
                        ></i>
                      </div>
                      <ReactTooltip
                        id={`Tooltip-responded`}
                        place="right"
                        type="dark"
                      >
                        <>
                          Prospect/recipient is tagged with this status if they
                          respond to any of your emails in your campaign
                          sequence. Once they reply, they are automatically
                          removed from the campaign and will not receive further
                          emails.
                        </>
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
                <div className="bottomStats">
                  <StatsBox count={bounces} name={"Bounces"} />
                  <StatsBox
                    count={dropped}
                    name={"Dropped"}
                    data-tip
                    data-for={`Tooltip-dropped`}
                  />
                </div>
              </div>

              <div className="stats-events-charts">
                <Bar data={chartsData} options={options} height={210} />
              </div>
            </div>

            <h3>Stats by Email</h3>
            {flows.map((flow, i) => {
              return (
                <EmailComponent
                  email={flow}
                  key={`email-component-${i}`}
                  version={campaign.version}
                  index={i}
                  campaign={campaign}
                />
              );
            })}
          </section>
        ) : (
          <Loading />
        )}
      </div>
    </CampaignLayout>
  );
}
