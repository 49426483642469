import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./tracking.module.scss";
import { useSelector } from "react-redux";
import cx from "classnames";

export default function TrackingComponent({ lead }) {
  const campaign = useSelector((store) => store.campaign);

  return (
    <div className={styles.status}>
      <div className="completed">
        <i
          className={cx(
            "fad fa-flag-checkered",
            lead.campaignsCompleted?.some((list) => list === campaign._id) &&
              styles.active
          )}
          data-tip
          data-for={`Tooltip-completed-campaign-${lead._id}`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-completed-campaign-${lead._id}`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">Completed campaign sequence</div>
          </ReactTooltip>
        </>
      </div>
      <div className="open">
        <i
          className={cx(
            "fa-duotone fa-eye",
            lead.openCampaigns?.some((list) => list === campaign._id) &&
              styles.active
          )}
          data-tip
          data-for={`Tooltip-open-campaign-${lead._id}`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-open-campaign-${lead._id}`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">
              Open at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
      <div className="click">
        <i
          className={cx(
            "fa-duotone fa-arrow-pointer",
            lead.clickCampaigns?.some((list) => list === campaign._id) &&
              styles.active
          )}
          data-tip
          data-for={`Tooltip-click-campaign-${lead._id}`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-click-campaign-${lead._id}`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">
              Clicked at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
      <div className="responded">
        <i
          className={cx(
            "fad fa-reply",
            lead.respondedCampaigns?.some((list) => list === campaign._id) &&
              styles.active
          )}
          data-tip
          data-for={`Tooltip-reply-campaign-${lead._id}`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-reply-campaign-${lead._id}`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">
              Responded at least one of the follow ups
            </div>
          </ReactTooltip>
        </>
      </div>
    </div>
  );
}
