import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./leads-header.module.scss";
import cx from "classnames";
import axios from "axios";
import toast from "react-hot-toast";
import LeadsTrackingComponent from "../leads-tracking/leads-tracking.component";

export default function LeadsHeaderComponent({
  setFilter,
  campaignStats,
  criteria,
  user,
  campaign,
  broadcast,
  download,
  openExport,
  refresh,
}) {
  const api = process.env.REACT_APP_API;
  const text = {
    pending: {
      text: `
      Pending: <br /><br />
      “These contacts have been successfully uploaded into the
      Automated Inbox system. They are currently chilling,
      enjoying a Mai Thai on the beach. Soon, as they are
      processed by our systems, they will be en route to their
      destiny: generating leads for your business in the land of
      legendary email outreach.`,
    },
    warning: {
      text: `
      Warning: <br /><br />
      `,
    },
    failed: {
      text: `
      Failed <br /><br />
      “The word ‘happy’ would lose its meaning if it were not
      balanced by sadness.” -Carl Jung <br />
      <br />
      “These poor, unfortunate contacts did not pass our rigorous
      screening, and have thus been voted off the island. They
      will not be uploaded, will not pass ‘Go.” and will not
      collect $200 dollars. Don’t worry, there’s someone out there
      for every contact. But today is not their day.”
`,
    },
    processed: {
      text: `Processed<br /><br />“Alas, not all contacts are created equal. For that reason,
      we send every contact through a third-party verification
      process to help ensure that emails are valid, won’t bounce,
      and won’t endanger your newly established email domain.
      Don’t worry. If a contact doesn’t make it through, we’ll
      ensure they get a nice consolation prize.”`,
    },
    captured: {
      text: `Leads captured from AI Lead Search Tool`,
    },
    linkedin: {
      text: `Leads captured from Sales Navigator`,
    },
  };
  const setNumber = (number, name) => {
    if (number && number[name]) {
      return number[name];
    }
    return 0;
  };
  const deleteLeads = () => {
    let message = `Are you sure you want to delete all the unverified leads? if you accept, this leads will be removed permanently and can't be recovered in the future!`;

    if (campaign) {
      message = `Are you sure you want to delete all the unverified leads in this campaign? if you accept, this leads will be removed permanently and can't be recovered in the future!`;
    }

    if (window.confirm(message)) {
      let body = {
        campaign: campaign,
        broadcast: broadcast,
        user: user._id,
      };
      const promise = axios
        .post(`${api}lead/delete/unverified`, body)
        .finally(() => {
          refresh();
        });
      toast.promise(promise, {
        loading: "Deleting leads...",
        success: "Delete successfully!",
        error: "Uh oh, there was an error!",
      });
    }
  };
  const upgradeAllProfile = (n) => {
    if (n === 0) {
      toast.error("No available leads for upgrade");
      return;
    }
    if (user.credit < n) {
      toast.error("You don't have credits");
      return;
    }

    let message = `Are you sure you want to upgrade all this leads for ${n} credit?`;
    if (window.confirm(message)) {
      const body = {
        campaign: campaign,
        broadcast: broadcast,
        user: user._id,
      };

      const promise = axios.post(`${api}lead/upgrade-all`, body).then(() => {
        refresh();
      });
      toast.promise(promise, {
        loading: "Upgrading...",
        success: "Upgrade successfully!",
        error: "Uh oh, there was an error!",
      });
    }
  };
  const verifyAllProfile = (n) => {
    if (n === 0) {
      toast.error("No available leads for verify");
      return;
    }
    if (user.validation_credit < n || user.validation_credit === undefined) {
      console.log("validation credit: "+user.validation_credit)
      toast.error("You don't have validation credits");
      return;
    }

    let message = `Are you sure you want to validate the leads for ${n} validation credit(s)?`;
    if (window.confirm(message)) {
      const body = {
        campaign: campaign,
        broadcast: broadcast,
        user: user._id,
        email: user.email,
      };

      const promise = axios.post(`${api}lead/verify-all`, body).then(() => {
        refresh();
      });
      toast.promise(promise, {
        loading: "Verifying...",
        success: "Verification process started!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  return (
    <>
      <div className={styles.buttonsContainer}>
        <button
          className={styles.download}
          onClick={() => download(campaign, broadcast)}
        >
          <i className="fas fa-download"></i> Download leads
        </button>
        {campaign && (
          <button
            className={styles.download}
            onClick={() => openExport(campaign)}
          >
            <i className="fas fa-share"></i> Export to campaign
          </button>
        )}

        <button className={styles.download} onClick={() => deleteLeads()}>
          <i className="fa-solid fa-trash-can"></i> Delete unverified leads
        </button>
      </div>
      <span>
        <span
          onClick={() => setFilter("processed")}
          className={cx(
            styles.qtyLeads,
            criteria === "processed" && styles.active
          )}
        >
          <i
            style={{ color: "#00b894", fontSize: 15 }}
            className="fad fa-check-circle"
            data-tip
            data-for={`Tooltip-processed`}
          ></i>{" "}
          <span className={styles.qtyNumber}>
            {setNumber(campaignStats, "processed")}
          </span>
        </span>
        <span
          onClick={() => setFilter("pending")}
          className={cx(
            styles.qtyLeads,
            criteria === "pending" && styles.active
          )}
        >
          <i
            style={{ fontSize: 15, color: "#0984e3" }}
            className="fad fa-clock"
            data-tip
            data-for={`Tooltip-pending`}
          ></i>{" "}
          <span className={styles.qtyNumber}>
            {setNumber(campaignStats, "pending")}
          </span>
        </span>
        <span
          onClick={() => setFilter("failed")}
          className={cx(
            styles.qtyLeads,
            criteria === "failed" && styles.active
          )}
        >
          <i
            style={{ color: "#d63031", fontSize: 15 }}
            className="fad fa-times-circle"
            data-tip
            data-for={`Tooltip-failed`}
          ></i>{" "}
          <span className={styles.qtyNumber}>
            {setNumber(campaignStats, "failed")}
          </span>
        </span>
        <span
          onClick={() => setFilter("captured")}
          className={cx(
            styles.qtyLeads,
            styles.AILeads,
            criteria === "captured" && styles.active
          )}
        >
          <img
            src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
            alt="icon"
            data-tip
            data-for={`Tooltip-captured`}
          />{" "}
          <span className={styles.qtyNumber}>
            {setNumber(campaignStats, "captured")}
          </span>
        </span>
        <span
          onClick={() => setFilter("linkedin")}
          className={cx(
            styles.qtyLeads,
            criteria === "linkedin" && styles.active
          )}
        >
          <i
            style={{ color: "#004e89", fontSize: 14 }}
            className="fab fa-linkedin-in"
            data-tip
            data-for={`Tooltip-linkedin`}
          ></i>{" "}
          <span className={styles.qtyNumber}>
            {setNumber(campaignStats, "linkedin")}
          </span>
        </span>
        <span>
          <span
            onClick={() => setFilter("premium-available")}
            className={cx(
              styles.qtyLeads,
              criteria === "premium-available" && styles.active
            )}
          >
            <i
              className="fad fa-question-circle"
              style={{
                color: "#e81821",
                fontSize: 14,
              }}
            ></i>{" "}
            <span className={styles.qtyNumber}>
              {setNumber(campaignStats, "premium-available")}
            </span>
          </span>
          {criteria === "premium-available" && (
            <span
              className={styles.upgradeAll}
              onClick={() =>
                upgradeAllProfile(setNumber(campaignStats, "premium-available"))
              }
            >
              Upgrade all
            </span>
          )}
        </span>
        <span>
          <span
            onClick={() => setFilter("un-verified")}
            className={cx(
              styles.qtyLeads,
              criteria === "un-verified" && styles.active
            )}
          >
            <i
              className="fad fa-exclamation-circle"
              style={{
                color: "#ff9800",
                fontSize: 14,
              }}
            ></i>{" "}
            <span className={styles.qtyNumber}>
              {setNumber(campaignStats, "un-verified")}
            </span>
          </span>
          {criteria === "un-verified" && (
            <span
              className={styles.verifyAll}
              onClick={() =>
                verifyAllProfile(setNumber(campaignStats, "un-verified"))
              }
            >
              Verify all
            </span>
          )}
        </span>
        {campaign && <LeadsTrackingComponent />}
      </span>
      <ReactTooltip
        id={`Tooltip-processed`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.processed.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-linkedin`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.linkedin.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-linkedin`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.linkedin.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-linkedin`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.linkedin.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-captured`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.captured.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-failed`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.failed.text }}
        />
      </ReactTooltip>
      <ReactTooltip
        id={`Tooltip-pending`}
        place="right"
        type="dark"
        className={styles.tooltip}
      >
        <div
          className="tooltip-box"
          dangerouslySetInnerHTML={{ __html: text.pending.text }}
        />
      </ReactTooltip>
    </>
  );
}
