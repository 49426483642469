import React from "react";
import styles from "./empty-follow-up.module.scss";

export default function EmptyFollowUp({ total, addFollowUp }) {
  return (
    <div className={styles.emptyFollowUp}>
      {total === 0 ? (
        <>
          <span onClick={() => addFollowUp()}>Add Intro Email</span>
        </>
      ) : (
        <>
          Click on an Existing Email Sequence or add a{" "}
          <span onClick={() => addFollowUp()}>New Follow Up</span>
        </>
      )}
    </div>
  );
}
