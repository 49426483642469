import React, { useContext, useEffect } from "react";
import { Context } from "./Context";
import { usePusher } from "./PusherContext";
import axios from "axios";

export default function PusherConnection() {
  const pusher = usePusher();
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    let channel;
    function refresh() {
      console.log("called refresh");
      axios.get(`${api}user/get/id/${value.user._id}`).then(({ data }) => {
        value.updateUser(data);
      });
    }
    if (value && value.user) {
      channel = pusher.subscribe(`user-${value.user._id}`);
      channel.bind("update", refresh);
    } else {
      if (channel) {
        channel.unbind();
        pusher.disconnect();
      }
    }

    return () => {
      if (channel) {
        channel.unbind("update", refresh);
      }
    };
  }, [value.isAuth]);

  return <div></div>;
}
