import React, { useContext, useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import "./leads.component.scss";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Truncate from "react-truncate";
import Select from "react-select";
import FilterLeadsComponent from "../filter-leads/filter-leads.component";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import LeadsTableComponent from "../leads-table/leads-table.component";
import LeadsHeaderComponent from "../leads-header/leads-header.component";
import { Context } from "../../Context";
import toast from "react-hot-toast";
import { useRef } from "react";
import ExportModal from "../modals/export/export.modal";
import { useSelector } from "react-redux";

export default function LeadsComponent({
  title,
  user,
  status,
  update,
  setUpdate,
  campaign,
  broadcast,
  setTotalLeads,
  setStats,
  enableStatus = true,
  setUnverified,
}) {
  const value = useContext(Context);
  const [name, setName] = useState("");
  const [mailStatus, setMailStatus] = useState([]);
  const [phone, setPhone] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [campaignStats, setCampaignStats] = useState(undefined);
  const [criteria, setCriteria] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPageLeads, setRowsPerPageLeads] = useState(20);
  const [leads, setLeads] = useState([]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const openExportModalRef = useRef();
  const api = process.env.REACT_APP_API;
  const tracking = useSelector((store) => store.tracking);

  useEffect(() => {
    if (update) {
      setUpdate(false);
      filtrar();
    }
  }, [update]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };

  const updateFilters = (name, mailStatus, phone) => {
    setName(name);
    setMailStatus(mailStatus);
    setPhone(phone);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const setFilter = (param) => {
    if (criteria === param) {
      setCriteria(undefined);
    } else {
      setCriteria(param);
    }
  };

  useEffect(() => {
    filtrar();
  }, [name, page, rowsPerPage, criteria, mailStatus, phone, tracking]);

  const filtrar = () => {
    const promise = axios
      .get(`${api}lead/all`, {
        params: {
          user: user,
          page: page,
          campaign: campaign,
          broadcast: broadcast,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          criteria: criteria,
          mailStatus: mailStatus,
          phone: phone,
          tracking: tracking,
        },
      })
      .then(({ data }) => {
        if (data.error) {
        } else {
          if (setTotalLeads) {
            setTotalLeads(data.totalDocs);
          }
          setTotal(data.totalDocs);
          setLeads(data.docs);
        }
      });

    toast.promise(promise, {
      loading: "Getting data...",
      success: "Got data successfully!",
      error: "Uh oh, there was an error!",
    });

    axios
      .get(`${api}lead/campaign-leads-stats`, {
        params: {
          campaign: campaign,
          broadcast: broadcast,
        },
      })
      .then(({ data }) => {
        setCampaignStats(data[0]);
        if (setUnverified) {
          setUnverified(data[0]["un-verified"]);
        }
        if (Object.keys(data[0]).length !== 0) {
          setStats(data[0]);
        }
      });
  };

  const refreshUsers = () => {
    axios
      .get(`${api}lead/all`, {
        params: {
          user: value.user._id,
          page: page,
          campaign: campaign,
          broadcast: broadcast,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          criteria: criteria,
          mailStatus: mailStatus,
          phone: phone,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setLeads(data.docs);
        }
      });
  };

  const download = (campaign, broadcast) => {
    axios
      .get(`${api}lead/download`, {
        params: {
          criteria: criteria,
          user: value.user._id,
          campaign: campaign ? campaign : undefined,
          broadcast: broadcast ? broadcast : undefined,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          mailStatus: mailStatus,
          phone: phone,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "leads.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const openExport = (campaign) => {
    setOpenExportModal(true);
  };

  useEffect(() => {
    if (openExportModal) {
      let body = {
        user: value.user._id,
        campaign: campaign,
        filterByName: name,
        criteria: criteria,
        mailStatus: mailStatus,
      };
      openExportModalRef.current.open(body);
    }
  }, [openExportModal, campaign]);

  return (
    <div>
      <div className="users-heading">
        <h3>
          <LeadsHeaderComponent
            setFilter={setFilter}
            campaignStats={campaignStats}
            criteria={criteria}
            user={value.user}
            campaign={campaign}
            broadcast={broadcast}
            download={download}
            openExport={openExport}
            refresh={filtrar}
          />
        </h3>
        <FilterLeadsComponent filter={updateFilters} />
      </div>
      <LeadsTableComponent
        rowsPerPageLeads={rowsPerPageLeads}
        page={page}
        total={total}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        leads={leads}
        refresh={refreshUsers}
        campaign={campaign}
        broadcast={broadcast}
        enableStatus={enableStatus}
      />
      {openExportModal && (
        <ExportModal
          ref={openExportModalRef}
          refresh={refreshUsers}
          setOpen={setOpenExportModal}
        />
      )}
    </div>
  );
}
