import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import "./_Campaign.scss";
import { useNavigate } from "@reach/router";
import CampaignLayout from "../../layouts/campaign.layout";
import toast from "react-hot-toast";
import styles from "./campaign2.module.scss";
import cx from "classnames";
import FollowUp from "../../components/campaign/sequence/follow-up/follow-up.component";
import ReactTooltip from "react-tooltip";
import { Button } from "../../styled-components/button.styled";
import CampaignPathImportModal from "../../components/modals/campaign-path-import/campaign-path-import.modal";
import TipComponent from "../../components/campaign/sequence/tip/tip.component";
import TemplateComponent from "../../components/campaign/sequence/template/template.component";
import SaveTemplateModal from "../../components/modals/save-template/save-template.modal";
import { Context } from "../../Context";

export default function Campaign({ id }) {
  const api = process.env.REACT_APP_API;
  const [campaign, setCampaign] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [index, setIndex] = useState(undefined);
  const value = useContext(Context);

  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [openBuilder, setOpenBuilder] = useState(false);
  const [followUp, setFollowUp] = useState(undefined);
  const [openImportPath, setOpenImportPath] = useState(false);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [tips, setTips] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(undefined);

  const navigate = useNavigate();
  const [followUps, setFollowUps] = useState([]);

  const orderFollowUps = useMemo(() => {
    return followUps.sort((a, b) => {
      return a.position > b.position;
    });
  }, [followUps]);

  const importPath = (campaignImported) => {
    let flowCleaned = JSON.stringify(campaignImported.flow);
    flowCleaned = JSON.parse(flowCleaned);
    const body = {
      flow: flowCleaned,
      id: campaign._id,
    };

    const promise = axios.post(`${api}campaigns/update-flow`, body);
    toast
      .promise(promise, {
        loading: "Saving flow...",
        success: "Flow saved!",
        error: (error) => (error ? error : "Uh oh, there was an error!"),
      })
      .finally(() => {
        setOpenImportPath(false);
        loadCampaign();
      });
  };

  const loadCampaign = () => {
    axios.get(`${api}campaigns/get/${id}`).then(({ data }) => {
      setCampaign(data);
      if (data.flow) {
        setFollowUps(data.flow);
      }
    });
    axios.get(`${api}template/all`).then(({ data }) => {
      setTemplates(data);
    });
    axios.get(`${api}tips/all`).then(({ data }) => {
      setTips(data);
    });
  };

  const addNewFollowUp = () => {
    let newFollowUp = {
      subject: "",
      content: "",
      delay: 3,
      interval: "day",
    };
    let tempFollowUps = [...followUps];
    tempFollowUps.push(newFollowUp);
    setFollowUps(tempFollowUps);
    setFollowUp(newFollowUp);
    setIndex(tempFollowUps.length - 1);
    setOpenBuilder(true);
  };

  const refresh = () => {
    setOpenSaveAs(false);
    loadCampaign();
  };

  const openTemplate = (template) => {
    setTemplate(template);
    setOpenSaveAs(true);
  };

  useEffect(() => {
    loadCampaign();
  }, []);

  const upEmail = (i, followUp) => {
    let tempFollowUps = [...followUps];
    let newPosition = i - 1;
    if (newPosition > -1) {
      let temp = tempFollowUps[newPosition];
      tempFollowUps[newPosition] = followUp;
      tempFollowUps[i] = temp;
    }
    setFollowUps(tempFollowUps);
  };

  const updateFollowUp = (followUp, closeBox) => {
    let tempFollowUps = followUps.map((fp, i) => {
      if (i === index) {
        return followUp;
      }
      return fp;
    });
    setFollowUps(tempFollowUps);
    if (closeBox) {
      setIndex(undefined);
    }
  };

  const downEmail = (i, followUp) => {
    let tempFollowUps = [...followUps];
    let newPosition = i + 1;
    if (newPosition < tempFollowUps.length) {
      let temp = tempFollowUps[newPosition];
      tempFollowUps[newPosition] = followUp;
      tempFollowUps[i] = temp;
    }
    setFollowUps(tempFollowUps);
  };

  const openFollowUp = (followUp, i) => {
    if (i === index) {
      setOpenBuilder(false);
      setIndex(undefined);
      return;
    }
    setContent(followUp.content);
    setSubject(followUp.subject);
    setFollowUp(followUp);
    setOpenBuilder(true);
    setIndex(i);
  };

  const deleteFollowUp = (e, i) => {
    if (!window.confirm("Are you sure you want to delete this follow up?")) {
      e.stopPropagation();
      return;
    }

    let tempFollowUps = [...followUps];
    tempFollowUps.splice(i, 1);
    setFollowUps(tempFollowUps);
    setIndex(undefined);
    setOpenBuilder(false);
    e.stopPropagation();
  };

  const isInvalid = (followUp) => {
    if (followUp.subject === "" || followUp.content === "") {
      return true;
    }

    return false;
  };

  const replaceSequence = (object) => {
    if (!window.confirm("Are you sure you want to use this template?")) {
      return;
    }
    toast.success("Imported Sequence");
    setFollowUps(object);
  };

  const onSave = () => {
    if (followUps.length === 0) {
      toast.error("No follow ups");
      return;
    }

    let errors = 0;
    followUps.forEach((element) => {
      if (isInvalid(element)) {
        errors++;
      }
    });

    if (errors > 0) {
      toast.error("Email Sequence With Errors");
      return;
    }

    const body = {
      flow: followUps,
      id: id,
    };

    const promise = axios.post(`${api}campaigns/update-flow`, body);
    toast.promise(promise, {
      loading: "Saving flow...",
      success: "Flow saved!",
      error: (error) => (error ? error : "Uh oh, there was an error!"),
    });
  };

  const onSaveTemplate = () => {
    if (followUps.length === 0) {
      toast.error("No follow ups");
      return;
    }

    let errors = 0;
    followUps.forEach((element) => {
      if (isInvalid(element)) {
        errors++;
      }
    });

    if (errors > 0) {
      toast.error("Email Sequence With Errors");
      return;
    }

    setOpenSaveAs(true);
  };

  const editTemplate = (template) => {
    setTemplate(template);
    setOpenSaveAs(true);
  };

  const deleteTemplate = (template) => {
    const body = {
      id: template._id,
    };

    if (!window.confirm("Are you sure you want to delete this template?")) {
      return;
    }

    const promise = axios.post(`${api}template/delete`, body);
    toast
      .promise(promise, {
        loading: "Deleting template...",
        success: "Template deleted!",
        error: (error) => (error ? error : "Uh oh, there was an error!"),
      })
      .finally(() => refresh());
  };

  if (!campaign) {
    return <div />;
  }
  return (
    <CampaignLayout user={user} campaign={campaign} CampaignLayout={!campaign}>
      <div className="campaign">
        <div className="container">
          <div className="campaign-header">
            <div className="campaign-header__info">
              <h1>{campaign.name}</h1>
            </div>
            <div className="campaign-header__actions">
              <Button className={cx(styles.btn)} onClick={onSave}>
                Save Email Sequence
              </Button>
              <Button className={cx(styles.btn)} onClick={onSaveTemplate}>
                Save as Template
              </Button>
              <Button
                className={cx(styles.btn)}
                onClick={() => setOpenImportPath(true)}
              >
                Import email sequence
              </Button>
              <Button onClick={() => navigate(-1)} className={cx(styles.btn)}>
                Go Back
              </Button>
            </div>
          </div>
          <div className={styles.followUpsContainer}>
            <div className={styles.followColumn}>
              <div className={styles.followUpsScroll}>
                {orderFollowUps.map((followUpItem, i) => {
                  return (
                    <div
                      className={cx(
                        styles.followUpBox,
                        index === i && styles.active,
                        openBuilder && styles.opacity,
                        isInvalid(followUpItem) && styles.redBorder
                      )}
                      key={`follow-${i}`}
                      onClick={() => openFollowUp(followUpItem, i)}
                    >
                      <div className={styles.boxContent}>
                        <div className={styles.subjectHeader}>
                          <div
                            className={styles.headerIcon}
                            data-tip
                            data-for={`Tooltip-open-sequence-number`}
                          >
                            <i className="fad fa-envelope"></i> {i + 1}
                            {i === 0 && " - Intro"}
                            {isInvalid(followUpItem) && " - Missing Content"}
                          </div>
                          <ReactTooltip
                            id={`Tooltip-open-sequence-number`}
                            place="top"
                            type="dark"
                          >
                            <div className="tooltip-box">
                              This number indicate the position in the sequence
                              for this follow up
                            </div>
                          </ReactTooltip>
                          <div className={styles.buttonPosition}>
                            <i
                              className="fad fa-arrow-circle-up"
                              onClick={() => upEmail(i, followUpItem)}
                            ></i>
                            <i
                              className="fad fa-arrow-circle-down"
                              onClick={() => downEmail(i, followUpItem)}
                            ></i>
                          </div>
                        </div>
                        <div className={styles.subjectBox}>
                          <label htmlFor="">Subject</label>
                          <div className={styles.subjectTitle}>
                            {followUpItem.subject.replace(/<[^>]+>/g, "")}
                          </div>
                        </div>
                        {i > 0 && (
                          <div className={styles.delayBox}>
                            <label htmlFor="">Wait</label>
                            <div className={styles.delayTitle}>
                              {followUpItem.delay} {followUpItem.interval}
                              {"(s) "}
                              <span> after the previous email</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.footerBox}>
                        <button onClick={(e) => deleteFollowUp(e, i)}>
                          <i className="fad fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
                {followUps.length < 10 && (
                  <button
                    className={cx(styles.addFollowButton)}
                    onClick={() => addNewFollowUp()}
                  >
                    {followUps.length === 0
                      ? "Add Intro Email"
                      : "Add Follow Up"}
                  </button>
                )}
              </div>
            </div>
            <div>
              <FollowUp
                campaign={campaign}
                subject={subject}
                content={content}
                followUp={followUp}
                open={openBuilder}
                setOpen={setOpenBuilder}
                updateFollowUp={updateFollowUp}
                index={index}
                setIndex={setIndex}
                total={followUps.length}
                addFollowUp={addNewFollowUp}
              />
            </div>
            <div className={styles.tips}>
              <h2>Tips</h2>
              <div className={styles.tipsContainer}>
                {tips.map((tip, i) => {
                  return (
                    <TipComponent
                      title={tip.title}
                      content={tip.description}
                      key={`tips-${i}`}
                    />
                  );
                })}
              </div>
              <br />
              <h2>Template Library</h2>
              <h3 className={styles.librarySubTitle}>
                Automated Inbox Library
              </h3>
              <div className={styles.tipsContainer}>
                {templates
                  .filter((list) => list.staff === true)
                  .map((template, i) => {
                    return (
                      <TemplateComponent
                        title={template.name}
                        object={template.flow}
                        owner={template.user}
                        admin={value.user.role === "admin"}
                        description={template.description}
                        replaceSequence={replaceSequence}
                        staff={template.staff}
                        editTemplate={editTemplate}
                        template={template}
                        key={`tips-${i}`}
                        deleteTemplate={deleteTemplate}
                      />
                    );
                  })}
              </div>
              <h3 className={styles.librarySubTitle}>My Templates</h3>
              <div className={styles.tipsContainer}>
                {templates
                  .filter((list) => !list.staff)
                  .map((template, i) => {
                    return (
                      <TemplateComponent
                        title={template.name}
                        object={template.flow}
                        owner={template.user}
                        admin={value.user.role === "admin"}
                        description={template.description}
                        replaceSequence={replaceSequence}
                        staff={template.staff}
                        editTemplate={editTemplate}
                        template={template}
                        key={`tips-${i}`}
                        deleteTemplate={deleteTemplate}
                      />
                    );
                  })}
              </div>
              <div className={styles.comingSoon}>More Coming Soon</div>
            </div>
          </div>
        </div>
        {openImportPath && (
          <CampaignPathImportModal
            import={importPath}
            setOpen={setOpenImportPath}
            version={4}
            current={campaign}
          />
        )}
        {openSaveAs && (
          <SaveTemplateModal
            refresh={refresh}
            template={template}
            setTemplate={setTemplate}
            setOpen={setOpenSaveAs}
            followUps={followUps}
          />
        )}
      </div>
    </CampaignLayout>
  );
}
