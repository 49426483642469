import React from "react";
import { useEffect } from "react";
import styles from "./unsubscribe.module.scss";
import axios from "axios";

export default function UnsubscribePage({ campaign, email }) {
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    const body = {
      campaign,
      email,
    };

    axios.post(`${api}unsubscribe/email`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        console.log("updated");
      }
    });
  }, []);

  return (
    <div className={styles.unsuscribed}>
      <div className={styles.icon}>
        <i className="fad fa-envelope-open-text"></i>
      </div>
      <h1>Sucessfully Unsubscribed</h1>
      <div className={styles.email}>{email}</div>
    </div>
  );
}
