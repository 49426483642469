/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./save-template.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import toast from "react-hot-toast";
import { Context } from "../../../Context";
import { DateTime } from "luxon";
import { useNavigate } from "@reach/router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Input } from "../../../styled-components/input.styled";
import { Select } from "../../../styled-components/select.styled";

const SaveTemplateModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);

  const schema = yup.object().shape({
    name: yup.string().required().min(3),
    description: yup.string().max(150),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let body = {
      ...data,
      flow: props.followUps,
    };

    if (getValues("id")) {
      if (
        !window.confirm(
          "Are you sure you want to update the template with your current email sequence?"
        )
      ) {
        return;
      }

      body = {
        ...body,
        id: getValues("id"),
      };
      const promise = axios
        .post(`${api}template/edit/`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            props.refresh();
            props.setOpen();
          }
        });

      toast
        .promise(promise, {
          loading: "Updating Template...",
          success: "Template Updated Successful!",
          error: "Uh oh, there was an error!",
        })
        .finally(() => props.setTemplate(undefined));
    } else {
      const promise = axios
        .post(`${api}template/add`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            props.refresh();
          }
        });

      toast
        .promise(promise, {
          loading: "Creating Template...",
          success: "Template Created Successful!",
          error: "Uh oh, there was an error!",
        })
        .finally(() => props.setTemplate(undefined));
    }
  };

  const close = () => {
    props.setTemplate(undefined);
    props.setOpen();
  };

  useEffect(() => {
    if (props.template) {
      setValue("id", props.template._id);
      setValue("name", props.template.name);
      setValue("description", props.template.description);
    }
  }, [props.template]);

  return (
    <ModalLayout
      title={"Save as Template"}
      open={true}
      close={close}
      save={handleSubmit(onSubmit)}
    >
      <form>
        <div className={styles.formGroup}>
          <label>
            Title <span>required</span>
          </label>
          <Input
            type="text"
            placeholder="My Template"
            {...register("name")}
            error={errors.name}
          />
        </div>

        <div className={styles.formGroup}>
          <label>Description</label>
          <Input
            type="text"
            placeholder="This is my template"
            {...register("description")}
            error={errors.description}
          />
        </div>
      </form>
    </ModalLayout>
  );
});

export default SaveTemplateModal;
