import React, { useContext, useRef } from "react";
import ContactsBarComponent from "../components/contacts-bar/contacts-bar.component";
import Loading from "../components/loading/Loading";
import Menu from "../components/menu/Menu";
import Nav from "../components/nav/Nav";
import WarningLimitExceededComponent from "../components/warning-limit-exceeded/warning-limit-exceeded.component";
import { Context } from "../Context";
import LoginModal from "../components/modals/LoginModal";

export default function BroadcastLayout({ children, loading, broadcast }) {
  const { user } = useContext(Context);
  const loginModalRef = useRef();

  const openTutorial = () => {
    loginModalRef.current.open();
  };

  return (
    <main>
      <Nav
        openInformation={openTutorial}
        user={user}
        openBuyCredits={() => {}}
      />
      <Menu broadcast={broadcast} />
      {user && <WarningLimitExceededComponent user={user} />}
      <ContactsBarComponent
        limit={user?.limits?.contacts}
        total={user?.leads}
      />
      {loading && <Loading />}
      {children}
      <LoginModal ref={loginModalRef} />
    </main>
  );
}
