import { useNavigate } from "@reach/router";
import moment from "moment";
import React from "react";
import Status from "../status/status.component";
import styles from "./enrichment-card.module.scss";
import cx from "classnames";

export default function EnrichmentCard({ enrichment }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.enrichmentCard}
      onClick={() => navigate(`/enrichment/${enrichment._id}`)}
    >
      <div
        className={cx(
          styles.iconStatus,
          enrichment.status === "completed" && styles.completed
        )}
      >
        {enrichment.status === "completed" ? (
          <div>
            <i className="far fa-check"></i>
          </div>
        ) : (
          <div>
            <i className="fas fa-circle-notch fa-spin"></i>
          </div>
        )}
      </div>
      <div>
        <h2>{enrichment.name}</h2>
        <div className={styles.metadata}>
          <span>
            <b>Status:</b> <Status status={enrichment.status} />
          </span>
          <span>
            <b>Created date:</b>
            {moment(enrichment.createdAt).format("LLL")}
          </span>
        </div>
        <div className={styles.metadata}>
          <span>
            <b>Premium:</b> {enrichment.premium ? "Yes" : "No"}
          </span>
          <span>
            <b>Personal Email:</b> {enrichment.personalEmail ? "Yes" : "No"}
          </span>
          <span>
            <b>Profiles Enriched:</b>{" "}
            {enrichment.leadsEnriched ? enrichment.leadsEnriched : "-"}
          </span>
        </div>
      </div>
    </div>
  );
}
