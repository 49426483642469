import React, { useState } from "react";
import axios from "axios";

export default function StatusLeadsComponent({ id, current, refresh }) {
  const api = process.env.REACT_APP_API;
  const [status, setStatus] = useState(current);

  const handle = async (e) => {
    const body = {
      mailStatus: e.target.value,
      id: id,
    };
    setStatus(e.target.value);

    await axios.post(`${api}lead/edit`, body);
    e.stopPropagation();
  };

  return (
    <select value={status} onChange={handle}>
      <option>-</option>
      <option value="ACTIVE">ACTIVE</option>
      <option value="OPT-OUT">OPT-OUT</option>
      <option value="RESPONDED">RESPONDED</option>
      <option value="BOUNCED">BOUNCED</option>
      <option value="COMPLETE">COMPLETE</option>
      <option value="AUTOREPLIED">AUTOREPLIED</option>
    </select>
  );
}
