import React, { useEffect, useState, useRef } from "react";
import Layout from "../../templates/Layout";
import { Link, useLocation, useNavigate } from "@reach/router";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import moment from "moment";
import "./All.scss";
import UserModal from "../../components/modals/User";
import EmailsTemplatesModal from "../../components/modals/EmailsTemplates";
import LinkedinSearchsModal from "../../components/modals/LinkedinSearchs";
import LeadsPerUserModal from "../../components/modals/LeadsPerUser";
import HardResetModal from "../../components/modals/HardReset";
import DataTable, { defaultThemes } from "react-data-table-component";
import queryString from "query-string";
import { Popover, Whisper } from "rsuite";
import ReactTooltip from "react-tooltip";

export default function AllUsers() {
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [users, setUsers] = useState([]);
  const [usersTemp, setUsersTemp] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [nameTemp, setNameTemp] = useState("");
  const navigate = useNavigate();
  const [datatable, setdatatable] = useState(undefined);
  const $ = window.$;
  const api = process.env.REACT_APP_API;
  let userModalRef = useRef();
  let emailsTemplatesModalRef = useRef();
  let linkedinSearchsModalRef = useRef();
  let leadsPerUserModalRef = useRef();
  let hardResetModalRef = useRef();

  const customStyles = {
    header: {},
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        i: {
          fontFamily: "'Font Awesome 6 Pro' !important",
          cursor: "pointer",
        },
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },

        ".pending": {
          backgroundColor: "#ffc31233",
          color: "#f79f1f",
          fontSize: "12px",
          fontWeight: 600,
          marginLeft: 10,
          borderRadius: 4,
        },
        ".processing": {
          backgroundColor: "#0652dd57",
          color: "#0652dd",
          fontSize: "12px",
          fontWeight: 600,
          marginLeft: 10,
          borderRadius: 4,
        },
        ".request": {
          backgroundColor: "#57606f57",
          color: "#57606f",
        },
      },
    },
    cells: {
      style: {},
    },
  };

  const columns = [
    {
      name: "Name",
      selector: "first_name",
      sortable: false,
      minWidth: "200px",
      cell: (row) => (
        <>
          <Link to={`/admin/users/${row._id}`} className={"name-link"}>
            <>
              <div className="text">
                {row.first_name} {row.last_name}{" "}
                {row.pending_linkedin.length > 0 && (
                  <span className={"badge pending"}>
                    {row.pending_linkedin.length}
                  </span>
                )}
                {row.processing_linkedin.length > 0 && (
                  <span className={"badge processing"}>
                    {row.processing_linkedin.length}
                  </span>
                )}
                {row.requestStatus !== "" &&
                  row.requestStatus !== undefined && (
                    <span className={"badge request"}>1</span>
                  )}
                {row.limitExceeded && (
                  <>
                    <span className="limit-exceeded">
                      <i
                        className="fas fa-exclamation-circle"
                        data-tip
                        data-for="limit-tooltip"
                      ></i>
                    </span>
                    <ReactTooltip
                      id={`limit-tooltip`}
                      place="right"
                      type="dark"
                    >
                      <div className="tooltip-box">Limit Exceeded</div>
                    </ReactTooltip>
                  </>
                )}
              </div>
            </>
          </Link>
        </>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Campaign",
      selector: "campaign",
      sortable: false,
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      minWidth: "120px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LL")}</div>
        </>
      ),
    },
    {
      name: "",
      sortable: false,
      width: "50px",
      cell: (row) => (
        <>
          <Whisper
            trigger="click"
            placement={"bottomEnd"}
            speaker={
              <Popover title="Actions" visible>
                <>
                  <div
                    className="option"
                    onClick={() => navigate(`/admin/users/${row._id}`)}
                  >
                    Edit
                  </div>
                  <div className="option" onClick={() => removeUser(row._id)}>
                    Delete
                  </div>
                </>
              </Popover>
            }
          >
            <i className="far fa-ellipsis-h"></i>
          </Whisper>
        </>
      ),
    },
  ];

  const [breadcrump] = useState([
    {
      name: "Users",
      active: true,
    },
  ]);

  const changeStatus = (obj) => {
    const api = process.env.REACT_APP_API;
    let body = {
      _id: obj._id,
    };

    if (obj.active) {
      body["active"] = false;
    } else {
      body["active"] = true;
    }

    // activateAuth
    axios.post(`${api}user/edit`, body).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        loadData();
      }
    });
  };

  const open = (user) => {
    userModalRef.current.open(user);
  };

  const openHardReset = (user) => {
    hardResetModalRef.current.open(user);
  };

  const searchLeadsPerUser = (user) => {
    leadsPerUserModalRef.current.open(user);
  };

  const openEmailsTemplates = (pending) => {
    emailsTemplatesModalRef.current.open(pending);
  };

  const openLinkedinSearchs = (pending) => {
    linkedinSearchsModalRef.current.open(pending);
  };

  const loadData = () => {
    axios
      .get(`${api}user/all`, {
        params: {
          pagination: false,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setUsers(data);
          setUsersTemp(data);
        }
      });
  };

  useEffect(() => {
    filtrar();
  }, [location]);

  const filtrar = () => {
    console.log("filter");
    axios
      .get(`${api}user/all`, {
        params: {
          pagination: false,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setUsers(data);
        }
      });
  };

  const handleFilterByName = (e) => {
    setNameTemp(e.target.value);
  };

  useEffect(() => {
    let queryURL = queryString.stringify({
      page: page,
      filterByName: name,
      rowsPerPage: rowsPerPage,
    });
    navigate(`?${queryURL}`);
  }, [name, page, rowsPerPage]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (nameTemp) {
      setUsers(
        usersTemp
          .map((user) => {
            return {
              ...user,
              name: user.first_name + " " + user.last_name,
            };
          })
          .filter((list) =>
            list.name.toLowerCase().includes(nameTemp.toLowerCase())
          )
      );
    } else {
      setUsers(usersTemp);
    }
  }, [nameTemp]);

  const filterByName = () => {
    setName(nameTemp);
  };

  const removeUser = (id) => {
    var r = window.confirm("Are you sure you want to delete this user?");
    if (r == true) {
      axios
        .post(`${api}user/edit`, { _id: id, active: false })
        .then(({ data }) => {
          if (data.error) {
            console.log(data.error);
          } else {
            loadData();
          }
        });
    } else {
      return;
    }
  };

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Users</h4>

              <div className="page-title-right">
                <Breadcrumb childs={breadcrump} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {/* <h4 className="card-title">All Users</h4> */}
                <div style={{ marginBottom: 10 }} className={"users-heading"}>
                  <button
                    onClick={() => userModalRef.current.open()}
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    Add User
                  </button>
                  <div className="filter-container">
                    <input
                      type="text"
                      placeholder={"Filter by Name"}
                      onInput={handleFilterByName}
                      className="filter-input"
                    />
                    <button className="filter-btn" onClick={filterByName}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
                {users && (
                  <DataTable
                    noHeader={true}
                    pagination={true}
                    data={users}
                    columns={columns}
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[15, 20, 30]}
                    customStyles={customStyles}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <UserModal ref={userModalRef} loadData={loadData} />
        <EmailsTemplatesModal ref={emailsTemplatesModalRef} />
        <LinkedinSearchsModal ref={linkedinSearchsModalRef} />
        <LeadsPerUserModal ref={leadsPerUserModalRef} />
        <HardResetModal ref={hardResetModalRef} />
      </div>
    </Layout>
  );
}
