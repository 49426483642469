import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";
import Vimeo from "@u-wave/react-vimeo";

const FeaturesModal = forwardRef((props, ref) => {
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 5 ? 5 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  useImperativeHandle(ref, () => ({
    open(user = undefined, lead = undefined) {
      var modal = document.getElementById("features-modal");
      window.onclick = function (event) {
        if (event.target === modal) {
          props.close();
        }
      };
    },
  }));

  const close = () => {
    props.close();
  };

  return (
    <div id="features-modal" className="modal">
      <div className="modal-content">
        <div className="form-container">
          <div className="row">
            <div className="steps-container">
              <div className="step-content">
                {step === 0 && (
                  <div>
                    <h1>
                      <b>NEW FEATURE!</b> You can now search leads on Sales
                      Navigator using our very own Chrome Extension! Watch the
                      videos to get started
                    </h1>
                    <h2>How to Install AI Chrome Extension:</h2>
                    <Vimeo
                      video={"https://vimeo.com/662879706/b17d055a59"}
                      autoplay
                    />
                  </div>
                )}
                {step === 1 && (
                  <>
                    <h2>
                      How to Search Sales Nav from the Contacts Dashboard:
                    </h2>
                    <Vimeo
                      video={"https://vimeo.com/662879778/e20a16927b"}
                      autoplay
                    />
                  </>
                )}
                {step === 2 && (
                  <div>
                    <h2>How to Search Sales Nav from Campaigns Dashboard:</h2>
                    <Vimeo
                      video={"https://vimeo.com/662883164/88b57df4e8"}
                      autoplay
                    />
                  </div>
                )}
              </div>
              <div className="buttons-container">
                {step === 0 && (
                  <div>
                    <button className="btn-next" onClick={onNext}>
                      Got it, what’s next?
                    </button>
                    <span onClick={close}>Later</span>
                  </div>
                )}
                {step > 0 && step < 2 && (
                  <div>
                    <div className="buttons-navigation">
                      <button className="btn-next" onClick={onNext}>
                        Got it, what’s next?
                      </button>
                      <button className="btn-back" onClick={onPrevious}>
                        Back
                      </button>
                    </div>
                    <span onClick={close}>Later</span>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <div className="buttons-navigation">
                      <button className="btn-next" onClick={close}>
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="buttons"></div>
        </div>
      </div>
    </div>
  );
});

export default FeaturesModal;
