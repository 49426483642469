import React, { useEffect, useState } from "react";
import styles from "./campaign.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../styled-components/input.styled";
import { Select } from "../../../styled-components/select.styled";
import { DateTime } from "luxon";
import { useContext } from "react";
import { Context } from "../../../Context";

const CampaignModal = (props) => {
  const api = process.env.REACT_APP_API;
  const user = useSelector((store) => store.user);
  const value = useContext(Context);
  const [schedule, setSchedule] = useState(false);
  const [senders, setSenders] = useState([]);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required().min(3),
    sender: yup.string().required(),
    dailyLimit: yup.number().min(1),
    days: yup.array().min(1),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let body = {
      ...data,
      version: 4,
    };

    let schedule;

    if (getValues("date") && getValues("time")) {
      schedule = DateTime.fromFormat(
        `${getValues("date")} ${getValues("time")}`,
        `yyyy-MM-dd HH:mm`
      );
    }

    body = {
      ...body,
      schedule: schedule ? schedule.toJSDate() : undefined,
    };

    if (getValues("id")) {
      const promise = axios
        .post(`${api}campaigns/edit`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            props.reload();
            props.setOpen(false);
          }
        });

      toast.promise(promise, {
        loading: "Updating campaign...",
        success: "Campaign updated!",
        error: "Uh oh, there was an error!",
      });
    } else {
      if (value.user._id !== user._id) {
        body.user = value.user._id;
      }
      const promise = axios
        .post(`${api}campaigns/add`, body)
        .then(({ data }) => {
          if (data.error) {
            console(data.error);
          } else {
            navigate(`/campaign/edit/${data._id}`);
            props.setOpen(false);
          }
        });

      toast.promise(promise, {
        loading: "Creating campaign...",
        success: "Campaign created!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  useEffect(() => {
    axios.get(`${api}sender/get/user/${value.user._id}`).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setSenders(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.campaign) {
      setValue("id", props.campaign._id);
      setValue("name", props.campaign.name);
      setValue("sender", props.campaign?.sender?._id);
      setValue("dailyLimit", props.campaign.dailyLimit || 100);
      setValue("days", props.campaign?.days);

      if (props.campaign.schedule) {
        setSchedule(true);
        let _time = DateTime.fromISO(props.campaign.schedule).toFormat("HH:mm");
        let _date = DateTime.fromISO(props.campaign.schedule).toFormat(
          "yyyy-MM-dd"
        );
        setValue("time", _time);
        setValue("date", _date);
      }
    } else {
      setValue("days", [
        "Monday",
        "Sunday",
        "Saturday",
        "Friday",
        "Wednesday",
        "Thursday",
        "Tuesday",
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campaign, senders]);

  const selectAll = () => {
    setValue("days", [
      "Monday",
      "Sunday",
      "Saturday",
      "Friday",
      "Wednesday",
      "Thursday",
      "Tuesday",
    ]);
  };

  const selectWeekdays = () => {
    setValue("days", ["Monday", "Friday", "Wednesday", "Thursday", "Tuesday"]);
  };

  useEffect(() => {
    if (!schedule) {
      setValue("time", undefined);
      setValue("date", undefined);
    }
  }, [schedule]);

  return (
    <ModalLayout
      title={"Campaign"}
      open={true}
      close={props.setOpen}
      save={handleSubmit(onSubmit)}
    >
      <form>
        <div className={styles.formGroup}>
          <label>
            Title <span>required</span>
          </label>
          <Input
            type="text"
            placeholder="My Unique Campaign"
            {...register("name", { required: true })}
            error={errors.name}
          />
        </div>
        <div className={styles.formGroup}>
          <label>
            Send From <span>required</span>
          </label>
          <Select
            {...register("sender", { required: true })}
            error={errors.sender}
          >
            <option value="">Select</option>
            {senders.map((sender) => {
              return (
                <option value={sender._id} key={sender._id}>
                  {sender.name} {`<${sender.email}>`}
                </option>
              );
            })}
          </Select>
        </div>
        <div className={styles.formGroup}>
          <label>Daily Limit</label>
          <Input
            type="number"
            placeholder="Daily Limit"
            defaultValue={100}
            {...register("dailyLimit")}
            error={errors.dailyLimit}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="">Only send on these days</label>
          <div className={styles.buttonsContainer}>
            <span onClick={() => selectAll()}>Select All</span>
            <span onClick={() => selectWeekdays()}>Weekdays Only</span>
          </div>
          <div className={styles.daysGroup}>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Sunday"
                {...register("days")}
              />{" "}
              Sunday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Monday"
                {...register("days")}
              />{" "}
              Monday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Tuesday"
                {...register("days")}
              />{" "}
              Tuesday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Wednesday"
                {...register("days")}
              />{" "}
              Wednesday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Thursday"
                {...register("days")}
              />{" "}
              Thursday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Friday"
                {...register("days")}
              />{" "}
              Friday
            </label>
            <label>
              <input
                name="days"
                type="checkbox"
                value="Saturday"
                {...register("days")}
              />{" "}
              Saturday
            </label>
          </div>
          {errors.days && (
            <p className={styles.errorMessage}>Select at least one day</p>
          )}
          <p></p>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.captured}>
            <Input
              type="checkbox"
              id="schedule"
              onChange={(e) => setSchedule(e.target.checked)}
              checked={schedule}
            />
            <span htmlFor="schedule">Schedule Start Date</span>
          </div>
        </div>
        {schedule && (
          <div className={styles.formGroup}>
            <div className={styles.dateTime}>
              <Input
                type="date"
                min={DateTime.now().toFormat("yyyy-MM-dd")}
                {...register("date")}
                defaultValue={DateTime.now().toFormat("yyyy-MM-dd")}
                datetime
                error={errors.date}
              />
              <Input
                type="time"
                {...register("time")}
                datetime
                error={errors.time}
                defaultValue={DateTime.now().toFormat("HH:mm")}
              />{" "}
            </div>
          </div>
        )}
      </form>
    </ModalLayout>
  );
};

export default CampaignModal;
