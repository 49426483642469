import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./tracking.module.scss";
import { useSelector } from "react-redux";
import cx from "classnames";

export default function TrackingBroadcast({ lead }) {
  const broadcast = useSelector((store) => store.broadcast);

  if (!broadcast) return <></>;

  return (
    <div className={styles.status}>
      <div className="completed">
        <i
          className={cx(
            "fad fa-flag-checkered",
            lead.broadcastsSent?.some((list) => list === broadcast._id) &&
              styles.active
          )}
          data-tip
          data-for={`Tooltip-completed-broadcast-${lead._id}`}
        ></i>
        <>
          <ReactTooltip
            id={`Tooltip-completed-broadcast-${lead._id}`}
            place="top"
            type="dark"
          >
            <div className="tooltip-box">Completed broadcast sequence</div>
          </ReactTooltip>
        </>
      </div>
    </div>
  );
}
