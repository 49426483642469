import React from "react";
import styles from "./status.module.scss";
import cx from "classnames";

export default function Status({ status }) {
  let statusClass = "default";
  let statusName = "Processing";

  if (status === "completed") {
    statusClass = status;
    statusName = "Completed";
  }

  if (status === "error") {
    statusClass = status;
    statusName = "Error";
  }

  return (
    <div className={cx(styles.status, styles[statusClass])}>{statusName}</div>
  );
}
