import React, { useEffect, useState, useContext } from "react";
import Layout from "../../templates/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import { Context } from "../../Context";
import "./All.scss";
import { useInputValue } from "../../hooks/useInputValue";
import { useNavigate } from "@reach/router";
import { readString } from "react-papaparse";
import DataTable from "react-data-table-component";
import { useToasts } from "react-toast-notifications";

export default function AddLinkedinTemplates({ id, type }) {
  const value = useContext(Context);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const $ = window.$;
  const api = process.env.REACT_APP_API;

  const rate = useInputValue("");
  const status = useInputValue("");
  const statusServer = useInputValue();
  const url = useInputValue("");
  const processed = useInputValue("");
  const cookie = useInputValue("");
  const user = useInputValue("");
  const campaign = useInputValue("");
  const leadsFound = useInputValue(0);
  const [leads, setLeads] = useState([]);

  const [breadcrump] = useState([
    {
      name: "Linkedin Searchs",
      link: "/linkedin-searchs",
      active: false,
    },
    {
      name: "Add Linkedin Searchs",
      active: true,
    },
  ]);

  const customStyles = {
    header: {},
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        background: "#fbfbfb",
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        p: {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        i: {
          color: "#576574",
          cursor: "pointer",
        },
        background: "#fbfbfb",
        ".check": {
          color: "#4cd137",
          fontSize: 15,
        },
        ".wrong": {
          color: "#e84118",
          fontSize: 15,
        },
        ".pending": {
          color: "#f9ca24",
          fontSize: 15,
        },
      },
    },
    pagination: {
      style: {
        background: "#fbfbfb",
      },
    },
    cells: {},
  };

  const columns = [
    {
      name: "First Name",
      sortable: false,
      width: "120px",
      cell: (row) => <p>{row.first_name.substring(0, 9) + "..."}</p>,
    },
    {
      name: "Last Name",
      sortable: false,
      width: "120px",
      cell: (row) => <p>{row.last_name.substring(0, 9) + "..."}</p>,
    },
    {
      name: "Email",
      sortable: false,
      cell: (row) => <p>{row.email}</p>,
    },
    {
      name: "Company",
      sortable: false,
      width: "100px",
      cell: (row) => <p>{row.companyName.substring(0, 5) + "..."}</p>,
    },
    {
      name: "Title",
      sortable: false,
      width: "100px",
      cell: (row) => <p>{row.title.substring(0, 5) + "..."}</p>,
    },
    {
      name: "Location",
      sortable: false,
      width: "100px",
      cell: (row) => <p>{row.location.substring(0, 5) + "..."}</p>,
    },
    {
      name: "",
      sortable: false,
      width: "50px",
      cell: (row) => (
        <>
          {row.status === "pending" && <i className="fad fa-clock pending"></i>}
          {row.status === "failed" && (
            <i className="fad fa-times-circle wrong"></i>
          )}
          {row.status === "processed" && (
            <i className="fad fa-check-circle check"></i>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      axios
        .get(`${api}linkedin-search/one`, {
          params: {
            _id: id,
          },
        })
        .then(({ data }) => {
          if (data.error) {
            console.log(data.error);
          } else {
            user.setValue(data.user);
            campaign.setValue(data.campaign);
            url.setValue(data.search);
            rate.setValue(data.rate);
            status.setValue(data.status);
            cookie.setValue(data.cookie);
            statusServer.setValue(data.status);
            processed.setValue(data.processed);

            leadsFound.setValue(
              data.leadsFound || data.leadsFound === 0 ? data.leadsFound : ""
            );

            if (data.status === "completed") {
              axios
                .get(`${api}lead/all-by-linkedin-search`, {
                  params: {
                    id: id,
                  },
                })
                .then((data) => {
                  setLeads(data.data);
                });
            }
          }
        });
    }
  }, []);

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id) {
      body = {
        url: url.value,
        rate: rate.value,
        user: value.user._id,
        status: status.value,
        leadsFound: leadsFound.value,
        cookie: cookie.value,
        _id: id,
      };

      axios.post(`${api}linkedin-search/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          if (leads.length > 0) {
            axios
              .post(`${api}linkedin-contacts/add-massive`, {
                id: id,
                campaign: campaign.value,
                user: user.value,
                leads: leads,
              })
              .then(({ data }) => {
                if (data.status === "ok") {
                  if (data.length !== leadsFound.value) {
                    let linkedin = {
                      _id: id,
                      user: value.user._id,
                      final: data.length,
                      difference: leadsFound.value - data.length,
                    };
                    axios
                      .post(`${api}linkedin-search/update-credit`, linkedin)
                      .then(({ data }) => {
                        navigate(-1);
                      });
                  }
                } else {
                  if (data.insertedDocs.length !== leadsFound.value) {
                    let linkedin = {
                      _id: id,
                      user: value.user._id,
                      final: data.insertedDocs.length,
                      difference: leadsFound.value - data.insertedDocs.length,
                    };
                    axios
                      .post(`${api}linkedin-search/update-credit`, linkedin)
                      .then(({ data }) => {
                        navigate(-1);
                      });
                  } else {
                    navigate(-1);
                  }
                }
              });
          } else {
            navigate(-1);
          }
        }
      });
    } else {
      body = {
        url: url.value,
        rate: rate.value,
        user: value.user._id,
        status: status.value,
        cookie: cookie.value,
      };

      axios.post(`${api}linkedin-search/add`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          navigate("/linkedin-search", { replace: true });
        }
      });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleFiles = (files) => {
    if (!files) {
      setLeads([]);
    }

    var reader = new FileReader();
    reader.onload = function (e) {
      const data = readString(reader.result);
      const leads = [];
      data.data.map((lead, i) => {
        if (i > 0) {
          let name = lead[0].split(" ");
          let email = "";

          if (lead[4]) {
            email = email.toLocaleLowerCase().trim();
          }

          leads.push({
            first_name: name[0],
            last_name: name[1],
            title: lead[2],
            email: lead[4],
            companyName: lead[1],
            location: lead[3],
            industry: "",
            status: "pending",
            campaign: campaign.value,
            mailStatus: "ACTIVE",
            type: "linkedin",
            user: user.value,
            userEmail: `${email}_${user.value}`,
          });
        }
      });
      if (leads.length > rate.value) {
        addToast("The amount of leads is above of the rate assigned", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        setLeads(leads);
        leadsFound.setValue(leads.length);
      }
    };
    reader.readAsText(files.target.files[0]);
  };

  const approve = () => {
    let body = {
      approve: true,
      _id: id,
    };

    axios.post(`${api}linkedin-search/edit`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        if (type == 1) {
          navigate(-1);
        } else {
          navigate("/linkedin-search", { replace: true });
        }
      }
    });
  };

  return (
    <Layout styleClass="admin__linkedin-searchs-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Linkedin Searchs</h4>

              {type == 1 ? (
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className={`breadcrumb-item`}>
                      <a onClick={goBack} style={{ cursor: "pointer" }}>
                        Back
                      </a>
                    </li>
                  </ol>
                </div>
              ) : (
                <div className="page-title-right">
                  <Breadcrumb childs={breadcrump} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div>
              <div>
                <div className="form-group position-relative">
                  <label htmlFor="">User</label>
                  <div>{user.value}</div>
                </div>
                <div className="form-group position-relative">
                  <label htmlFor="">Campaign</label>
                  <div>{campaign.value}</div>
                </div>
                <div className="form-group position-relative">
                  <label>
                    URL <span className="required">required</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Position"
                    value={url.value}
                    onChange={url.onChange}
                    required=""
                    rows={5}
                  ></textarea>
                </div>
                <div className="form-group position-relati">
                  <label htmlFor="">Cookie</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Position"
                    value={cookie.value}
                    onChange={cookie.onChange}
                    required=""
                    rows={3}
                  ></textarea>
                </div>
                <div className="form-group position-relative">
                  <label>
                    Rate <span className="required">required</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={rate.value}
                    onChange={rate.onChange}
                    required=""
                  />
                </div>
                <div className="form-group position-relative">
                  <label htmlFor="">Status</label>
                  {statusServer.value !== "completed" ? (
                    <select
                      className="form-control"
                      onChange={status.onChange}
                      value={status.value}
                    >
                      <option value="pending">pending</option>
                      <option value="processing">processing</option>
                      <option value="completed">completed</option>
                    </select>
                  ) : (
                    <p>{status.value}</p>
                  )}
                </div>
                {status.value === "completed" && (
                  <>
                    <div className="form-group position-relative">
                      <label>Qualified Leads Found</label>
                      {statusServer.value !== "completed" ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ex. 3"
                          value={leadsFound.value}
                          onChange={leadsFound.onChange}
                          disabled={leads.length > 0}
                          required=""
                        />
                      ) : (
                        <p>{leadsFound.value}</p>
                      )}
                    </div>
                    {statusServer.value !== "completed" && (
                      <div className="form-group position-relative">
                        <label>Upload Captured Leads</label>
                        <br />
                        <input
                          type="file"
                          accept=".csv"
                          onChange={handleFiles}
                          required=""
                        />
                      </div>
                    )}
                    {leads.length > 0 && (
                      <>
                        <label htmlFor="">Leads</label>
                        <div className="preview-box">
                          <DataTable
                            noHeader={true}
                            pagination={true}
                            paginationTotalRows={leads.length}
                            data={leads}
                            columns={columns}
                            customStyles={customStyles}
                            paginationRowsPerPageOptions={[5, 10]}
                            style={{ background: "transparent" }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                <hr />
                {status.value !== "" && (
                  <div className="buttons">
                    {statusServer.value !== "completed" && (
                      <button
                        type="button"
                        disabled={!url.value || !rate.value}
                        onClick={save}
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="btn btn-secondary waves-effect waves-light"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
