import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import { useToasts } from "react-toast-notifications";
import "./_EditCampaign.scss";
import LeadsTable from "../../components/leads-table/LeadsTable";
import SingleLeadModal from "../../components/modals/SingleLead";
import LeadModal from "../../components/modals/Lead";
import CampaignLayout from "../../layouts/campaign.layout";
import LinkedinModal from "../../components/modals/Linkedin";
import LeadsComponent from "../../components/leads/leads.component";
import CountdownComponent from "../../components/countdown/countdown.component";
import { Context } from "../../Context";
import ToggleStatusComponent from "../../components/toggle-status/toggle-status.component";
import ToolsComponent from "../../components/tools/tools.component";
import { createCampaign } from "../../redux/states/campaign";
import { resetTracking } from "../../redux/states/tracking";
import { useDispatch } from "react-redux";
import moment from "moment";

export default function EditCampaign({ id }) {
  const [user, setUser] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const value = useContext(Context);
  const [totalLeads, setTotalLeads] = useState(0);
  const [unverified, setUnverified] = useState(0);
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState(undefined);
  const [stats, setStats] = useState(undefined);
  const [noLeads, setNoLeads] = useState(true);
  const { addToast } = useToasts();
  const api = process.env.REACT_APP_API;

  const reload = () => {
    if (user) {
      axios.get(`${api}campaigns/get/${id}`).then(({ data }) => {
        setCampaign(data);
      });
    }
  };

  const updateLeads = () => {
    setUpdate(true);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });

    axios.get(`${api}campaigns/get/${id}`).then(({ data }) => {
      setCampaign(data);
      dispatch(createCampaign(data));
    });
  }, []);

  useEffect(() => {
    if (stats) {
      if (stats.processed === 0 || stats.unverified === 0) {
        setNoLeads(true);
      } else {
        setNoLeads(false);
      }
    }
  }, [stats]);

  useEffect(() => {
    dispatch(resetTracking());
  }, []);

  return (
    <CampaignLayout
      user={value.user}
      campaign={campaign}
      loading={!(user && campaign)}
    >
      <div className="home">
        {user && campaign ? (
          <section className="container">
            <div className="header">
              <h2>
                {campaign.name}{" "}
                <span className="title-status">{campaign.status}</span>{" "}
              </h2>
              <div className="button-header">
                <ToggleStatusComponent
                  campaign={campaign}
                  reload={reload}
                  refresh={updateLeads}
                  unverified={unverified}
                  noLeads={noLeads}
                />
              </div>
            </div>
            <span className="campaign_sender">
              <b>Campaign ID:</b> {campaign.uid}
            </span>
            {campaign.schedule && (
              <div className="campaign_sender">
                <b>Scheduled to start</b>:{" "}
                {campaign.schedule && moment(campaign.schedule).format("LLL")}
              </div>
            )}

            {campaign.sender && (
              <div className="campaign_sender">
                <b>Sender:</b> {campaign.sender.name}{" "}
                {`<${campaign.sender.email}>`}
              </div>
            )}
            <span className="campaign_sender">
              <b>Version:</b> {campaign.version}
            </span>
            <CountdownComponent setUpdate={setUpdate} />
            <ToolsComponent
              campaign={campaign}
              limit={value.user?.limits?.contacts}
              total={value.user?.leads}
              user={value.user}
              refresh={updateLeads}
              capture
              noPaddings={true}
            />
            <div className="campaigns">
              <div className="pending">
                {user && (
                  <LeadsComponent
                    title="Leads"
                    user={user._id}
                    status={"all"}
                    update={update}
                    campaign={campaign._id}
                    setUpdate={setUpdate}
                    setStats={setStats}
                    setUnverified={setUnverified}
                  />
                )}
              </div>
            </div>
          </section>
        ) : (
          <div />
        )}
      </div>
    </CampaignLayout>
  );
}
