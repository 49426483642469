import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const ChangePasswordModal = forwardRef((props, ref) => {
  const [id, setId] = useState(undefined);
  const { addToast } = useToasts();
  const password = useInputValue("");
  const repeat_password = useInputValue("");

  useImperativeHandle(ref, () => ({
    open(user = undefined) {
      if (user) {
        password.setValue("");
        repeat_password.setValue("");
        setId(user._id);
      }

      var modal = document.getElementById("ChangePasswordModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const save = () => {
    const api = process.env.REACT_APP_API;
    let body;

    if (id) {
      body = {
        _id: id,
        password: password.value,
        repeat_password: repeat_password.value,
      };

      axios.post(`${api}user/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          addToast("Password updated successful", {
            appearance: "success",
            autoDismiss: true,
          });
          console.log(data);
          setId(undefined);
          props.loadData();
          close();
        }
      });
    }
  };

  const close = () => {
    var modal = document.getElementById("ChangePasswordModal");
    modal.style.display = "none";
  };

  return (
    <div id="ChangePasswordModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2>Change Password</h2>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password.value}
                  onChange={password.onChange}
                  required=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group position-relative">
                <label htmlFor="validationTooltip02">Repeat Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder="Repeat Password"
                  value={repeat_password.value}
                  onChange={repeat_password.onChange}
                  required=""
                />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={save}
              disabled={
                !password.value || password.value !== repeat_password.value
              }
              className="btn btn-primary waves-effect waves-light"
            >
              Save
            </button>
            <button
              type="button"
              onClick={close}
              className="btn btn-secondary waves-effect waves-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChangePasswordModal;
