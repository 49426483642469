import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./export.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { Context } from "../../../Context";
import toast from "react-hot-toast";

const ExportModal = forwardRef((props, ref) => {
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const [campaigns, setCampaigns] = useState([]);
  const [filters, setFilters] = useState(undefined);

  const campaign = useInputValue("");
  const removeCampaign = useInputValue(false);
  const includeUnverified = useInputValue(false);
  const includeOPTOUT = useInputValue(false);

  const updateList = () => {
    axios
      .get(`${api}lead/campaigns`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setCampaigns(data);
        }
      });
  };

  useEffect(() => {
    updateList();
  }, []);

  useImperativeHandle(ref, () => ({
    open(filters) {
      console.log({ filters });
      setFilters(filters);
    },
  }));

  const exportCampaign = () => {
    if (!campaign.value) {
      toast.error("required field missing");
      return;
    }

    let body = {
      filters: filters,
      user: value.user._id,
      destiny: campaign.value,
      removeCampaign: removeCampaign.value,
      includeUnverified: includeUnverified.value,
      includeOPTOUT: includeOPTOUT.value,
    };

    const promise = axios
      .post(`${api}lead/export`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.refresh();
        }
      })
      .finally(() => props.setOpen());

    toast.promise(promise, {
      loading: "Processing...",
      success: "Export Successful!",
      error: "Uh oh, there was an error!",
    });
  };

  return (
    <ModalLayout
      title={"Export to Campaign"}
      open={true}
      close={props.setOpen}
      customButton={exportCampaign}
      customButtonText={"Export"}
    >
      <>
        <div className={styles.exportCampaignBody}>
          <div className={styles.formGroup}>
            <label>
              Choose campaign <span>required</span>{" "}
            </label>
            <select onChange={campaign.onChange}>
              <option value="">Select</option>
              {campaigns &&
                campaigns.map((c) => {
                  return (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className={styles.formGroup}>
            <label>I want to</label>
            {filters && filters.campaign && (
              <div className={styles.captured}>
                <input
                  type="checkbox"
                  onChange={(e) => removeCampaign.setValue(e.target.checked)}
                  checked={removeCampaign.value}
                  id="remove-campaign"
                />
                <label htmlFor="remove-campaign">
                  Remove leads from this campaign
                </label>
              </div>
            )}
            <div className={styles.captured}>
              <input
                type="checkbox"
                onChange={(e) => includeOPTOUT.setValue(e.target.checked)}
                checked={includeOPTOUT.value}
                id="include-optout"
              />
              <label htmlFor="include-optout">Include OPT-OUT leads</label>
            </div>
          </div>

          <div className={styles.notes}>
            * The status of all exported leads will be set to ACTIVE
          </div>
        </div>
      </>
    </ModalLayout>
  );
});

export default ExportModal;
