import styled, { css } from "styled-components";

export const Input = styled.input`
  background: #fff;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border: 1px solid #e0e1e4;
  border-radius: 0.375rem;
  font-family: var(--primary-font);

  ${(props) =>
    props.datetime &&
    css`
      width: 150px;
      padding-right: 0.5rem;
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: #dc2626;
    `}
`;
