import { Link } from "@reach/router";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import BillingComponent from "../../components/profile/billing/billing.component";
import NavigationComponent from "../../components/profile/navigation/navigation.component";
import ProfileComponent from "../../components/profile/profile/profile.component";
import PurchaseComponent from "../../components/profile/purchases/purchases.component";
import SettingsComponent from "../../components/profile/settings/settings.component";
import { Context } from "../../Context";
import ProfileLayout from "../../layouts/profile.layout";
import styles from "./profile.module.scss";

export default function ProfilePage({ tab }) {
  const value = useContext(Context);

  return (
    <ProfileLayout user={value.user}>
      <main className={styles.mainProfile}>
        <section className={styles.section}>
          <div className="container">
            <Link to="/dashboard" className={styles.superiorLink}>
              Go back to dashboard
            </Link>
            <div className={styles.profileGrid}>
              <NavigationComponent tab={tab} />
              <div>
                {tab === "personal" && <ProfileComponent />}
                {tab === "billing" && <BillingComponent />}
                {tab === "settings" && <SettingsComponent />}
                {tab === "purchase" && <PurchaseComponent />}
              </div>
            </div>
          </div>
        </section>
      </main>
    </ProfileLayout>
  );
}
