import styled, { css } from "styled-components";

export const Button = styled.button`
  background: none;
  height: 28px;
  border-radius: 5px;
  padding: 0px 13px;
  transition: 0.75s background;
  font-family: var(--primary-font);
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.primary &&
    css`
      background: rgb(255, 77, 79);
      color: white;
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px,
        rgb(255 77 79) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    `}
`;
