import React, { useEffect, useState, useRef, useContext } from "react";
import Layout from "../../templates/Layout";
import { Link, useLocation, useNavigate } from "@reach/router";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import moment from "moment";
import "./All.scss";
import UserModal from "../../components/modals/User";
import EmailsTemplatesModal from "../../components/modals/EmailsTemplates";
import LinkedinSearchsModal from "../../components/modals/LinkedinSearchs";
import LeadsPerUserModal from "../../components/modals/LeadsPerUser";
import HardResetModal from "../../components/modals/HardReset";
import DataTable, { defaultThemes } from "react-data-table-component";
import queryString from "query-string";
import ChangeCreditsModal from "../../components/modals/ChangeCreditsModal";
import ChangePasswordModal from "../../components/modals/ChangePasswordModal";
import Truncate from "react-truncate";
import SenderModal from "../../components/modals/sender.modal";
import ChangeLimitsModal from "../../components/modals/ChangeLimitsModal";
import { Context } from "../../Context";
import ReactTooltip from "react-tooltip";
import styles from "./user.module.scss";
import cx from "classnames";
import { Button } from "../../styled-components/button.styled";
import UserModal2 from "../../components/modals/user/user.modal";

export default function UserPage({ id }) {
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPageLeads, setRowsPerPageLeads] = useState(10);
  const [senders, setSenders] = useState([]);
  const [linkedinSearchs, setLinkedinSearchs] = useState([]);
  const [leads, setLeads] = useState([]);
  const [pendingLinkedinSearchs, setPendingLinkedinSearchs] = useState(0);
  const [processingLinkedinSearchs, setProcessingLinkedinSearchs] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [nameTemp, setNameTemp] = useState("");
  const [user, setUser] = useState(undefined);
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;
  const userModalRef = useRef();
  const senderModalRef = useRef();
  let emailsTemplatesModalRef = useRef();
  let linkedinSearchsModalRef = useRef();
  let leadsPerUserModalRef = useRef();
  let hardResetModalRef = useRef();
  let changeCreditsModalRef = useRef();
  let changeLimitsModalRef = useRef();
  let changePasswordModalRef = useRef();
  const value = useContext(Context);
  const [openUser, setOpenUser] = useState(false);

  const customStyles = {
    header: {},
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        background: "white",
      },
    },
    headCells: {
      style: {
        "&:first-child": {
          paddingLeft: 0,
        },
      },
    },
    rows: {
      style: {
        "&:first-child": {
          paddingLeft: 0,
        },
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        "*:not(i)": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
        i: {
          color: "#576574",
          cursor: "pointer",
          marginRight: 10,
        },
        background: "white",
      },
    },
    pagination: {
      style: {
        background: "white",
      },
    },
    cells: {
      style: {
        "&:first-child": {
          paddingLeft: 0,
        },
      },
    },
  };

  const columns = [
    {
      name: "Rate",
      selector: "rate",
      sortable: false,
      width: "70px",
      cell: (row) => <>{row.rate}</>,
    },
    {
      name: "Search",
      selector: "search",
      sortable: false,
      cell: (row) => (
        <>
          <Truncate width={150} ellipsis={"..."}>
            {row.search}
          </Truncate>
        </>
      ),
    },
    {
      name: "Status",
      width: "100px",
      sortable: false,
      cell: (row) => (
        <>
          <div className={`badge ${row.status}`}>{row.status}</div>
        </>
      ),
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      width: "210px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LLL")}</div>
        </>
      ),
    },
  ];

  const columnsLeads = [
    {
      name: "Name",
      sortable: false,
      cell: (row) => (
        <>
          {row.first_name} {row.last_name}
        </>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      width: "100px",
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      width: "210px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LLL")}</div>
        </>
      ),
    },
    {
      name: "",
      sortable: false,
      width: "50px",
      cell: (row) => (
        <>
          <i
            className="fal fa-pencil-alt"
            onClick={() => leadsPerUserModalRef.current.open(user, row)}
          ></i>
        </>
      ),
    },
  ];

  const columnsSenders = [
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Reply To",
      selector: "replyTo",
      sortable: false,
      width: "100px",
    },
    {
      name: "Status",
      sortable: false,
      width: "100px",
      cell: (row) => (
        <>
          <div className="text">
            {row.status === false ? "Inactive" : "Active"}
          </div>
        </>
      ),
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      width: "210px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LLL")}</div>
        </>
      ),
    },
    {
      name: "",
      sortable: false,
      width: "50px",
      cell: (row) => (
        <>
          <i className="fal fa-pencil-alt" onClick={() => openSender(row)}></i>
          <i
            className="far fa-trash-alt"
            onClick={() => removeSender(row._id)}
          ></i>
        </>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const openSender = (sender) => {
    senderModalRef.current.open(user, sender);
  };

  const loadData = () => {
    axios.get(`${api}user/get/id/${id}`).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        axios
          .get(`${api}linkedin-search/all`, { params: { user: id } })
          .then(({ data }) => {
            if (data.error) {
              console.log(data.error);
            } else {
              setPendingLinkedinSearchs(
                data.filter((list) => list.status === "pending").length
              );
              setProcessingLinkedinSearchs(
                data.filter((list) => list.status === "processing").length
              );
              setLinkedinSearchs(data);
            }
          });

        axios.get(`${api}sender/get/user/${id}`).then(({ data }) => {
          if (data.error) {
            console.log(data.error);
          } else {
            setSenders(data);
          }
        });

        setUser(data);
      }
    });
  };

  useEffect(() => {
    filtrar();
  }, [location]);

  const filtrar = () => {
    axios
      .get(`${api}lead/all`, {
        params: {
          ...queryString.parse(location.search),
          user: id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setTotalLeads(data.totalDocs);
          setLeads(data.docs);
        }
      });
  };

  const loginAs = () => {
    window.localStorage.setItem("userAdmin", JSON.stringify(value.user));
    value.activateAuth(value.token, user);
    navigate("/");
  };

  useEffect(() => {
    let queryURL = queryString.stringify({
      page: page,
      filterByName: name,
      rowsPerPage: rowsPerPageLeads,
    });
    navigate(`?${queryURL}`);
  }, [name, page, rowsPerPageLeads]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPageLeads(newPerPage);
  };

  const handleFilterByName = (e) => {
    setNameTemp(e.target.value);
  };

  useEffect(() => {
    let query = queryString.parse(location.search);
    setName(query.filterByName);
    loadData();
  }, []);

  const filterByName = () => {
    setName(nameTemp);
  };

  const removeSender = (id) => {
    let text = "Are you sure you want to remove this sender ?";
    if (!window.confirm(text)) {
      return;
    }

    axios.post(`${api}sender/delete`, { id: id }).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        loadData();
      }
    });
  };

  const openModal = () => {
    setOpenUser(true);
  };

  return (
    <Layout styleClass="users-page">
      <div className="container-fluid">
        {user && (
          <div className="user-container">
            <div className="user-box">
              <h2>
                {user.first_name} {user.last_name}
                {user.limitExceeded && (
                  <>
                    <span className="limit-exceeded">
                      <i
                        className="fas fa-exclamation-circle"
                        data-tip
                        data-for="limit-tooltip"
                      ></i>
                    </span>
                    <ReactTooltip
                      id={`limit-tooltip`}
                      place="right"
                      type="dark"
                    >
                      <div className="tooltip-box">Limit Exceeded</div>
                    </ReactTooltip>
                  </>
                )}
              </h2>
              <div className="user-box__email">{user.email}</div>
              <div className={styles.headerButtonContainer}>
                <Button onClick={() => openModal()} style={{ marginTop: 5 }}>
                  Edit User Information
                </Button>
                <Button onClick={() => loginAs()} style={{ marginTop: 5 }}>
                  Login As {user.first_name}
                </Button>
              </div>
              <br />
              <div className="user-box__heading">
                <h3>Details</h3>
                <Button
                  onClick={() => {
                    changeCreditsModalRef.current.open(user);
                  }}
                >
                  modify
                </Button>
              </div>
              <div className="user-box__action-box">
                Credits: {user.credit ? user.credit : 0}
              </div>
              <div className="user-box__action-box">
                Validation Credits:{" "}
                {user.validation_credit ? user.validation_credit : 0}
              </div>
              <div
                className="user-box__action-box"
                style={{ justifyContent: "flex-start", gap: 5 }}
              >
                SendGrid:{" "}
                {user.settings?.sendgridKey ? (
                  <Truncate width={150}>{user.settings.sendgridKey}</Truncate>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="user-box__action-box">
                <span>
                  Status: <span>{user.active ? "Active" : "Inactive"}</span>
                </span>
              </div>
              <div className="user-box__action-box">
                <span>
                  Provider: <span>{user.settings?.provider || "sendgrid"}</span>
                </span>
              </div>
              <div className="user-box__action-box">
                <span>
                  Role:{" "}
                  <span className={cx(styles.badge, styles.role)}>
                    {user.role || "N/A"}
                  </span>
                </span>
              </div>
              <hr />
              <div className="user-box__heading">
                <h3>Limits</h3>
                <Button
                  onClick={() => {
                    changeLimitsModalRef.current.open(user, value.user._id);
                  }}
                >
                  modify
                </Button>
              </div>
              <div className="user-box__action-box">
                Contacts: {user.limits ? user.limits.contacts : 0}
              </div>
              <div className="user-box__action-box">
                Campaign(s): {user.limits ? user.limits.campaigns : 0}
              </div>
              <div className="user-box__action-box">
                Broadcast(s): {user.limits ? user.limits.broadcasts : 0}
              </div>
              <div className="user-box__action-box">
                Searchs: {user.limits ? user.limits.searchs : 0}
              </div>
              <div className="user-box__action-box">
                Monthly Limits:{" "}
                {user.settings?.monthlyLimit
                  ? user.settings.monthlyLimit
                  : "default (3,000)"}
              </div>

              <hr />
              <div className="user-box__action-box">
                Password: *******
                <button
                  onClick={() => {
                    changePasswordModalRef.current.open(user);
                  }}
                >
                  modify
                </button>
              </div>
              <hr />
              <div className="user-box__action-box">
                Leads: {totalLeads ? totalLeads : 0}
                <button
                  onClick={() => {
                    hardResetModalRef.current.open(user);
                  }}
                >
                  hard reset
                </button>
              </div>
            </div>
            <div className="user-other-data">
              <div>
                <h3>
                  Linkedin Searchs{" "}
                  {pendingLinkedinSearchs > 0 && (
                    <span className={"badge pending br-4"}>
                      {pendingLinkedinSearchs}
                    </span>
                  )}{" "}
                  {processingLinkedinSearchs > 0 && (
                    <span className={"badge processing br-4"}>
                      {processingLinkedinSearchs}
                    </span>
                  )}
                </h3>
                <DataTable
                  noHeader={true}
                  pagination={true}
                  paginationPerPage={rowsPerPage}
                  paginationTotalRows={total}
                  data={linkedinSearchs}
                  columns={columns}
                  customStyles={customStyles}
                  paginationRowsPerPageOptions={[5, 10]}
                  style={{ background: "transparent" }}
                />
              </div>
              <div className="mt-30 table-container">
                <div className="users-heading">
                  <h3>Leads </h3>
                  <div className="filter-container">
                    <input
                      type="text"
                      placeholder={"Filter by Name"}
                      onInput={handleFilterByName}
                      className="filter-input"
                    />
                    <button className="filter-btn" onClick={filterByName}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
                <DataTable
                  noHeader={true}
                  pagination={true}
                  paginationPerPage={rowsPerPageLeads}
                  paginationDefaultPage={page}
                  paginationTotalRows={totalLeads}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationServer={true}
                  onChangePage={handlePageChange}
                  data={leads}
                  columns={columnsLeads}
                  customStyles={customStyles}
                  paginationRowsPerPageOptions={[10, 15]}
                  style={{ background: "transparent" }}
                />
              </div>
              <div className="mt-30 table-container">
                <div className="users-heading">
                  <h3>Senders </h3>
                  <div className="filter-container">
                    <button
                      className="button primary"
                      onClick={() => openSender(undefined)}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <DataTable
                  noHeader={true}
                  pagination={true}
                  paginationTotalRows={senders.length}
                  data={senders}
                  columns={columnsSenders}
                  customStyles={customStyles}
                  paginationRowsPerPageOptions={[5, 10]}
                  style={{ background: "transparent" }}
                />
              </div>
            </div>
          </div>
        )}
        <UserModal ref={userModalRef} loadData={loadData} />
        <SenderModal ref={senderModalRef} loadData={loadData} />
        <EmailsTemplatesModal ref={emailsTemplatesModalRef} />
        <LinkedinSearchsModal ref={linkedinSearchsModalRef} />
        <LeadsPerUserModal ref={leadsPerUserModalRef} loadData={loadData} />
        <HardResetModal ref={hardResetModalRef} />
        <ChangeCreditsModal ref={changeCreditsModalRef} loadData={loadData} />
        <ChangeLimitsModal ref={changeLimitsModalRef} loadData={loadData} />
        <ChangePasswordModal ref={changePasswordModalRef} loadData={loadData} />
        {openUser && (
          <UserModal2 setOpen={setOpenUser} user={user} reload={loadData} />
        )}
      </div>
    </Layout>
  );
}
