import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import "./_Campaign.scss";
import styles from "./campaign.module.scss";
import { useNavigate } from "@reach/router";
import CampaignLayout from "../../layouts/campaign.layout";
import ReactFlow, {
  isEdge,
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  Background,
  ConnectionLineType,
} from "react-flow-renderer";
import Sidebar from "../../components/campaign/sidebar/sidebar.component";
import EmailNode from "../../components/campaign/nodes/email-box.component";
import DelayNode from "../../components/campaign/nodes/delay-box.component";
import ButtonEdge from "../../components/campaign/nodes/button.component";
import cx from "classnames";
import EmailModal from "../../components/modals/email/email.modal";
import DelayModal from "../../components/modals/delay/delay.modal";
import toast from "react-hot-toast";
import CampaignPathImportModal from "../../components/modals/campaign-path-import/campaign-path-import.modal";

export default function CampaignV2({ campaignId }) {
  const api = process.env.REACT_APP_API;
  const [id, setId] = useState(4);
  const [disableEmails, setDisableEmails] = useState(false);
  const [missingConnections, setMissingConnections] = useState(false);
  const [nodeId, setNodeId] = useState(undefined);

  const [email, setEmail] = useState({ subject: "", body: "" });
  const [delay, setDelay] = useState({ time: "3", interval: "day" });

  const [campaign, setCampaign] = useState(undefined);

  const [openEmail, setOpenEmail] = useState(false);
  const [openDelay, setOpenDelay] = useState(false);
  const [openImportPath, setOpenImportPath] = useState(false);

  const [user, setUser] = useState(undefined);
  const navigate = useNavigate();
  const emailModalRef = useRef();
  const delayModalRef = useRef();
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const initialElements = [
    {
      id: "1",
      type: "input",
      className: "start",
      data: { type: "straight", label: "Start" },
      position: { x: 250, y: 25 },
    },
    {
      id: "2",
      type: "output",
      className: "end",
      data: { type: "straight", label: "Finish" },
      position: { x: 250, y: 145 },
    },
  ];
  const [elements, setElements] = useState(initialElements);
  const nodeTypes = {
    // dropSelector: DropboxNode,
    emailSelector: EmailNode,
    delaySelector: DelayNode,
  };

  const edgeTypes = {
    buttonedge: ButtonEdge,
  };

  useEffect(() => {
    if (openEmail) {
      emailModalRef.current.open(email, campaignId);
    }
  }, [openEmail]);

  useEffect(() => {
    if (openDelay) {
      delayModalRef.current.open(delay);
    }
  }, [openDelay]);

  useEffect(() => {
    if (elements) {
      console.log(elements);
      let emails = elements.filter((list) => list.type === "emailSelector");
      let connections = elements.filter((list) => list.type === "buttonedge");
      let delays = elements.filter((list) => list.type === "delaySelector");

      if (emails.length === 8) {
        setDisableEmails(true);
      } else {
        setDisableEmails(false);
      }

      if (emails.length + delays.length + 1 > connections.length) {
        setMissingConnections(true);
      } else {
        setMissingConnections(false);
      }
    }
  }, [elements]);

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      let flowJSON = flow.elements;

      const body = {
        flow: flowJSON,
        id: campaignId,
      };

      const promise = axios.post(`${api}campaigns/update-flow`, body);
      toast.promise(promise, {
        loading: "Saving flow...",
        success: "Flow saved!",
        error: (error) => (error ? error : "Uh oh, there was an error!"),
      });
    }
  }, [reactFlowInstance]);

  const onEdgeClick = (evt, id) => {
    let edge = [
      {
        id: id,
      },
    ];

    setElements((els) => removeElements(edge, els));
  };

  const onConnect = (params) => {
    const sourceUsed = elements.find((elem) => elem.source === params.source);
    const targetUsed = elements.find((elem) => elem.target === params.target);
    if (!sourceUsed && !targetUsed) {
      setElements((els) =>
        addEdge(
          {
            ...params,
            type: "buttonedge",
            className: "animated",
            data: {
              onEdgeClick: onEdgeClick,
            },
          },
          els
        )
      );
    }
  };
  const onElementsRemove = (elementsToRemove) => {
    setElements((els) => removeElements(elementsToRemove, els));
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onClick = (id, data, type) => {
    if (type === "email") {
      const email = {
        subject: data.subject ? data.subject : "",
        body: data.body ? data.body : "",
      };
      setNodeId(id);
      setEmail(email);
      setOpenEmail(true);
    }

    if (type === "delay") {
      const delay = {
        time: data.time,
        interval: data.interval,
      };
      setNodeId(id);
      setDelay(delay);
      setOpenDelay(true);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    let _id = id;
    let _idString = (_id++).toString();

    const newNode = {
      id: `${campaignId}_${_idString}`,
      type,
      position,
      data: {
        onDoubleClick: onClick,
      },
    };
    setId(_id++);
    if (type === "emailSelector") {
      setNodeId(`${campaignId}_${_idString}`);
      setOpenEmail(true);
    }

    if (type === "delaySelector") {
      setNodeId(`${campaignId}_${_idString}`);
      setOpenDelay(true);
    }
    setElements((es) => es.concat(newNode));
  };

  const onSaveEmail = (email) => {
    setElements((els) =>
      els.map((e) => {
        if (
          isEdge(e) ||
          e.id === "2" ||
          e.id === "1" ||
          nodeId.toString() !== e.id
        ) {
          return e;
        }

        e.data.subject = email.subject;
        e.data.body = email.body;

        return {
          ...e,
          data: {
            ...e.data,
          },
        };
      })
    );
    setOpenEmail(false);
  };

  const onSaveDelay = (delay) => {
    setElements((els) =>
      els.map((e) => {
        if (
          isEdge(e) ||
          e.id === "2" ||
          e.id === "1" ||
          nodeId.toString() !== e.id
        ) {
          return e;
        }

        e.data.time = delay.time;
        e.data.interval = delay.interval;

        return {
          ...e,
          data: {
            ...e.data,
          },
        };
      })
    );
    setOpenDelay(false);
  };

  const importPath = (campaign) => {
    let flowCleaned = JSON.stringify(campaign.flow).replaceAll(
      campaign._id,
      campaignId
    );
    flowCleaned = JSON.parse(flowCleaned);
    console.log({ flowCleaned });
    const body = {
      flow: flowCleaned,
      id: campaignId,
    };

    const promise = axios.post(`${api}campaigns/update-flow`, body);
    toast
      .promise(promise, {
        loading: "Saving flow...",
        success: "Flow saved!",
        error: (error) => (error ? error : "Uh oh, there was an error!"),
      })
      .finally(() => {
        setOpenImportPath(false);
        loadCampaign();
      });
  };

  const loadCampaign = () => {
    axios.get(`${api}campaigns/get/${campaignId}`).then(({ data }) => {
      setCampaign(data);
      if (data.flow) {
        let emails = data.flow.filter(
          (list) =>
            list.type === "emailSelector" || list.type === "delaySelector"
        );
        let newId = emails.length + 4;
        setId(newId);
        setElements(
          data.flow.map((f) => {
            let obj = {
              ...f,
            };

            if (obj.type === "buttonedge") {
              if (obj.data === undefined) {
                obj.data = {};
              }
              obj.data.onEdgeClick = onEdgeClick;
            }

            if (obj.data) {
              obj.data.onDoubleClick = onClick;
            }
            return {
              ...obj,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.user);
    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });
    loadCampaign();
  }, []);

  return (
    <CampaignLayout user={user} campaign={campaign} CampaignLayout={!campaign}>
      <div className="campaign">
        <div className="container">
          <div className="campaign-header">
            <div className="campaign-header__info">
              {/* <h1>{campaign.name}</h1> */}
              {/* <h2>{campaign.uid}</h2> */}
            </div>
            <div className="campaign-header__actions">
              <button className={cx(styles.btn)} onClick={onSave}>
                Save
              </button>
              <button
                className={cx(styles.btn)}
                onClick={() => setOpenImportPath(true)}
              >
                Import email sequence
              </button>
              <button onClick={() => navigate(-1)} className={cx(styles.btn)}>
                Go Back
              </button>
            </div>
          </div>
          {missingConnections && (
            <div className={styles.missingConnections}>
              There's some follow ups or delays without connection, if you want
              to see how connections works check this{" "}
              <a
                href="https://help.automatedinbox.com/en/articles/5923093-how-to-edit-an-email-sequence"
                target={"_blank"}
              >
                Link
              </a>
            </div>
          )}
          <div className="dndflow">
            <ReactFlowProvider>
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  elements={elements}
                  onConnect={onConnect}
                  onElementsRemove={onElementsRemove}
                  onLoad={onLoad}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                >
                  <Controls />
                  <Background color="#aaa" gap={16} />
                </ReactFlow>
              </div>
              <Sidebar disableEmails={disableEmails} />
            </ReactFlowProvider>

            {openEmail && (
              <EmailModal
                setOpen={setOpenEmail}
                onSaveEmail={onSaveEmail}
                ref={emailModalRef}
              />
            )}

            {openDelay && (
              <DelayModal
                setOpen={setOpenDelay}
                onSaveDelay={onSaveDelay}
                ref={delayModalRef}
              />
            )}
          </div>
        </div>
        {openImportPath && (
          <CampaignPathImportModal
            import={importPath}
            setOpen={setOpenImportPath}
          />
        )}
      </div>
    </CampaignLayout>
  );
}
