import React from "react";
import styles from "./modal.module.scss";
import cx from "classnames";

export default function ModalLayout({
  children,
  title,
  open,
  close,
  save,
  filter,
  start,
  customButton,
  customButtonText,
}) {
  return (
    <div className={cx(styles.modal, open && styles.open)}>
      <div className={styles.boxContainer}>
        <div className={styles.box}>
          <div className={styles.header}>{title}</div>
          <div className={styles.body}>{children}</div>
          <div className={styles.footer}>
            <button onClick={() => close(false)}>Close</button>
            {customButton && (
              <button className={styles.primary} onClick={() => customButton()}>
                {customButtonText}
              </button>
            )}
            {filter && (
              <button className={styles.primary} onClick={() => filter()}>
                Import
              </button>
            )}
            {save && (
              <button className={styles.primary} onClick={() => save()}>
                Save
              </button>
            )}
            {start && (
              <button className={styles.primary} onClick={() => start()}>
                Start Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
