import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./lead.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import toast from "react-hot-toast";
import { Context } from "../../../Context";
import ReactTooltip from "react-tooltip";

const LeadModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const api = process.env.REACT_APP_API;
  const [id, setId] = useState(undefined);
  const [lead, setLead] = useState(undefined);

  const email = useInputValue("");
  const fname = useInputValue("");
  const lname = useInputValue("");
  const company = useInputValue("");
  const industry = useInputValue("");
  const phone = useInputValue("");
  const country = useInputValue("");
  const city = useInputValue("");
  const state = useInputValue("");
  const twitter = useInputValue("");
  const facebook = useInputValue("");
  const linkedin = useInputValue("");
  const title = useInputValue("");

  const value = useContext(Context);

  useImperativeHandle(ref, () => ({
    open(lead = undefined) {
      if (lead) {
        setId(lead._id);
        setLead(lead);
      }

      email.setValue(lead.email ? lead.email : "");
      fname.setValue(lead.first_name ? lead.first_name : "");
      lname.setValue(lead.last_name ? lead.last_name : "");
      company.setValue(lead.companyName ? lead.companyName : "");
      industry.setValue(lead.industry ? lead.industry : "");

      if (lead.basic) {
        title.setValue(lead.basic.job_title ? lead.basic.job_title : "");
        linkedin.setValue(
          lead.basic.linkedin_url ? lead.basic.linkedin_url : ""
        );
        country.setValue(
          lead.basic.location_country ? lead.basic.location_country : ""
        );
        state.setValue(
          lead.basic.location_region ? lead.basic.location_region : ""
        );
        city.setValue(
          lead.basic.location_locality ? lead.basic.location_locality : ""
        );
      } else {
        title.setValue("");
        linkedin.setValue("");
        country.setValue("");
        state.setValue("");
        city.setValue("");
      }

      if (lead.premium) {
        phone.setValue(
          lead.premium.mobile_phone ? lead.premium.mobile_phone : ""
        );
        facebook.setValue(
          lead.premium.facebook_url ? lead.premium.facebook_url : ""
        );
        twitter.setValue(
          lead.premium.twitter_url ? lead.premium.twitter_url : ""
        );
      } else {
        phone.setValue("");
        facebook.setValue("");
        twitter.setValue("");
      }
      setOpen(true);
    },
  }));

  const save = () => {
    if (id) {
      let body = {
        first_name: fname.value,
        last_name: lname.value,
        companyName: company.value,
        industry: industry.value,
        id: id,
      };

      axios.post(`${api}lead/edit`, body).then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.refresh();
          setOpen(false);
        }
      });
    }
  };

  const isPremiumHide = (_lead) => {
    if (_lead) {
      if (_lead.premium) {
        return false;
      }

      if (_lead.premiumHide && _lead.premiumHide.mobile_phone) {
        return true;
      }
    } else {
      return false;
    }
  };

  const upgradeProfile = (lead) => {
    if (value.user.credit < 1) {
      toast.error("You don't have credits");
      return;
    }

    let message = "Are you sure you want to upgrade this lead for 1 credit?";
    if (window.confirm(message)) {
      const body = {
        lead: lead._id,
        user: value.user._id,
      };

      const promise = axios.post(`${api}lead/upgrade`, body).then(() => {
        props.refresh();
        setOpen(false);
      });
      toast.promise(promise, {
        loading: "Upgrading...",
        success: "Upgrade successfully!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  return (
    <ModalLayout title={"Lead"} open={open} close={setOpen} save={save}>
      <>
        <div className={styles.formGroup}>
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            value={email.value}
            disabled={true}
            onChange={email.onChange}
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.formGroup}>
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              value={fname.value}
              onChange={fname.onChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              value={lname.value}
              onChange={lname.onChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label>
            Phone <span className={styles.premium}>Premium</span>{" "}
            {isPremiumHide(lead) && (
              <>
                <span
                  className={styles.buyPremium}
                  data-tip
                  data-for={`Tooltip-premium-no-${lead._id}`}
                  onClick={() => upgradeProfile(lead)}
                >
                  <i className="fad fa-question-circle"></i>
                </span>
                <ReactTooltip
                  id={`Tooltip-premium-no-${lead._id}`}
                  place="top"
                  type="dark"
                >
                  <div className="tooltip-box">
                    Upgrade to Premium For 1 Credit
                  </div>
                </ReactTooltip>
              </>
            )}
          </label>
          <input
            type="text"
            placeholder=""
            value={phone.value}
            disabled={true}
            onChange={phone.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Company Name</label>
          <input
            type="text"
            placeholder=""
            value={company.value}
            onChange={company.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Industry</label>
          <input
            type="text"
            placeholder=""
            value={industry.value}
            onChange={industry.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>Job Title</label>
          <input
            type="text"
            placeholder=""
            value={title.value}
            disabled={true}
            onChange={title.onChange}
          />
        </div>
        <div className={styles.headerSection}>Address</div>
        <div className={styles.formGroup}>
          <label>Country</label>
          <input
            type="text"
            placeholder=""
            value={country.value}
            disabled={true}
            onChange={country.onChange}
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.formGroup}>
            <label>State</label>
            <input
              type="text"
              placeholder="State"
              value={state.value}
              disabled={true}
              onChange={state.onChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label>City</label>
            <input
              type="text"
              placeholder="City"
              value={city.value}
              disabled={true}
              onChange={city.onChange}
            />
          </div>
        </div>
        <div className={styles.headerSection}>Social Networks</div>
        <div className={styles.formGroup}>
          <label>Linkedin</label>
          <input
            type="text"
            placeholder=""
            value={linkedin.value}
            disabled={true}
            onChange={linkedin.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>
            Twitter <span className={styles.social}>Social</span>
          </label>
          <input
            type="text"
            placeholder=""
            value={twitter.value}
            disabled={true}
            onChange={twitter.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>
            Facebook <span className={styles.social}>Social</span>
          </label>
          <input
            type="text"
            placeholder=""
            value={facebook.value}
            disabled={true}
            onChange={facebook.onChange}
          />
        </div>
      </>
    </ModalLayout>
  );
});

export default LeadModal;
