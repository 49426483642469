import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./enrichment.module.scss";
import axios from "axios";
import ModalLayout from "../../../layouts/modal.layout";
import { useInputValue } from "../../../hooks/useInputValue";
import { readString } from "react-papaparse";
import { Context } from "../../../Context";
import { useNavigate } from "@reach/router";
import toast from "react-hot-toast";
import InputFile from "../../input-file/input-file.component";

const EnrichmentModal = forwardRef((props, ref) => {
  const [setTotalLeads] = useState(0);
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;
  const [leads, setLeads] = useState([]);
  const value = useContext(Context);
  const premium = useInputValue(false);
  const title = useInputValue("");
  const personalEmail = useInputValue(true);
  const [sampleLeads, setSampleLeads] = useState([]);

  useImperativeHandle(ref, () => ({
    open(lead = undefined, total) {
      setTotalLeads(total);
    },
  }));

  const handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      const data = readString(reader.result);
      const leads = [];

      let fname = -1;
      let lname = -1;
      let email = -1;
      let company = -1;
      let country = -1;

      data.data.forEach((lead, i) => {
        if (i === 0) {
          lead.forEach((col, i) => {
            if (col.toLocaleLowerCase().indexOf("first") > -1) {
              fname = i;
            }
            if (col.toLocaleLowerCase().indexOf("last") > -1) {
              lname = i;
            }
            if (col.toLocaleLowerCase().indexOf("country") > -1) {
              country = i;
            }
            if (col.toLocaleLowerCase().indexOf("email") > -1) {
              email = i;
            }
            if (col.toLocaleLowerCase().indexOf("company") > -1) {
              company = i;
            }
          });
        }

        if (i > 0) {
          leads.push({
            first_name: lead[fname],
            last_name: lead[lname],
            email: lead[email],
            companyName: lead[company],
            country: lead[country],
            status: "-",
            mailStatus: "ACTIVE",
            user: value.user._id,
          });
        }
      });

      setLeads(leads);
      setSampleLeads(leads.slice(0, 5));
    };
    reader.readAsText(files.target.files[0]);
  };

  const save = () => {
    let credits = leads.length * (premium.value ? 2 : 1);

    if (credits > value.user.credit) {
      toast.error("You don't have enought credits");
      return;
    }

    let body = {
      name: title.value,
      amount: leads.length,
      credits: credits,
      premium: premium.value,
      status: "processing",
      personalEmail: personalEmail.value,
      campaign: props.campaign,
      user: value.user._id,
      leads: leads,
    };
    const promise = axios
      .post(`${api}enrichment/add`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        } else {
          props.setOpen(false);
          navigate(`/enrichment/${data._id}`, { replace: true });
        }
      });

    toast.promise(promise, {
      loading: "Processing...",
      success: "Enrichment process started!",
      error: (err) => {
        return err.message ? err.message : "Uh oh, there was an error!";
      },
    });
  };

  return (
    <ModalLayout
      title={"Enrichment"}
      open={true}
      close={props.setOpen}
      customButton={save}
      customButtonText={`Enrich ${leads.length} leads, credits: ${
        leads.length * (premium.value ? 2 : 1)
      }`}
    >
      <>
        <div className={styles.formGroup}>
          <label>Enrichment Title</label>
          <input
            type="text"
            placeholder="Ex. My first Enrichment"
            value={title.value}
            onChange={title.onChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label>
            Upload CSV <span className="required">required</span>
          </label>
          <InputFile change={handleFiles} />
          <div className={styles.warning}>
            You can use one of this combinations: <br />
            <br />
            - First Name, Last Name and Email <br />
            - First Name, Last Name and Country
            <br />
            <br />
            But if you want better and more accurate results you can provide
            First Name, Last Name, Email, Country and Company Name.
          </div>
          {/* <input
            type="file"
            accept=".csv"
            className={styles.file}
            onChange={handleFiles}
            required=""
          /> */}
          <div className={styles.enrichPreview}>
            {sampleLeads.length > 0 && (
              <>
                <label>File Preview (showing 5)</label>
                <div className="preview-box">
                  <table>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Company Name</th>
                        <th>Country</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sampleLeads.map((slead, i) => {
                        return (
                          <tr key={`samplelist-${i}`}>
                            <td>{slead.first_name}</td>
                            <td>{slead.last_name}</td>
                            <td>{slead.email}</td>
                            <td>{slead.companyName}</td>
                            <td>{slead.country}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <div className={styles.formGroup}>
            <label className={styles.premiumFields}>
              <input
                type="checkbox"
                onChange={(e) => premium.setValue(e.target.checked)}
              />{" "}
              Get premium fields if found
            </label>
            <div className={styles.premium}>
              <div className={styles.item}>Phone</div>
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="">If nothing's found, get:</label>
          <label className={styles.checkboxField}>
            <input
              type="checkbox"
              onChange={(e) => personalEmail.setValue(e.target.checked)}
              checked={personalEmail.value}
            />{" "}
            Personal email
          </label>
          {personalEmail.value === false && (
            <div className={styles.warning}>
              This will increase your search results. Recommended if you’re
              targeting small businesses
            </div>
          )}
        </div>
      </>
    </ModalLayout>
  );
});

export default EnrichmentModal;
