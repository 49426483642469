import React, { useEffect, useState, useRef, useContext } from "react";
import Layout from "../../templates/Layout";
import { Link } from "@reach/router";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import moment from "moment";
import { Context } from "../../Context";
import "./All.scss";
import UserModal from "../../components/modals/User";

export default function AllEmailsTemplates() {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [datatable, setdatatable] = useState(undefined);
  const value = useContext(Context);
  const $ = window.$;
  const api = process.env.REACT_APP_API;
  let userModalRef = useRef();

  const [breadcrump] = useState([
    {
      name: "Email Templates",
      active: true,
    },
  ]);

  const changeStatus = (obj) => {
    const api = process.env.REACT_APP_API;
    let body = {
      _id: obj._id,
    };

    if (obj.active) {
      body["active"] = false;
    } else {
      body["active"] = true;
    }

    // activateAuth
    axios.post(`${api}user/edit`, body).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        loadData();
      }
    });
  };

  const open = (user) => {
    userModalRef.current.open(user);
  };

  const loadData = () => {
    if (datatable) {
      datatable.destroy();
    }
    console.log(value.user._id)
    axios.get(`${api}email-template/all`, {
      params: {
        user: value.user._id,
      }
    }).then(({ data }) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setEmailTemplates(
          data.emails
        );
        let datatableObj = $("#datatable").DataTable();
        setdatatable(datatableObj);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Email Templates</h4>

              <div className="page-title-right">
                <Breadcrumb childs={breadcrump} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {/* <h4 className="card-title">All Users</h4> */}
                <div style={{ marginBottom: 10 }}>
                  <Link to="/email-templates/add" className="btn btn-primary waves-effect waves-light btn-sm">
                    Add Email Template
                  </Link>
                </div>
                <table
                  id="datatable"
                  className="table table-bordered dt-responsive nowrap"
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Subject</th>
                      {/* <th>Content</th> */}
                      <th>Approved</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {emailTemplates.map((et) => {
                      return (
                        <tr key={et._id}>
                          <td>{et.position}</td>
                          <td>{et.subject}</td>
                          {/* <th>{et.content}</th> */}
                          <td className='status-approved'>
                            {et.approved ? 
                              <React.Fragment>
                                <i className="fas fa-check-circle green"></i> Approved
                              </React.Fragment>: 
                              <React.Fragment>
                                <i className="fas fa-exclamation-circle yellow"></i> Pending
                              </React.Fragment> }
                          </td>
                          <td>{moment(et.createdAt).format("LL")}</td>
                          <td>
                            <div className="icons">
                              <Link to={`/email-templates/edit/${et._id}`}>
                                <i
                                  className="bx bxs-pencil"
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <UserModal ref={userModalRef} loadData={loadData} />
      </div>
    </Layout>
  );
}
