import React, { useEffect } from "react";
import { useState } from "react";
import { Popover, Whisper } from "rsuite";
import { useInputValue } from "../../hooks/useInputValue";
import styles from "./filter-leads.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { Context } from "../../Context";

export default function FilterLeadsComponent({ filter }) {
  const name = useInputValue("");
  const [enableStatus, setEnableStatus] = useState(false);
  const [enablePhone, setEnablePhone] = useState(false);
  const [status, setStatus] = useState([]);
  const value = useContext(Context);

  const clean = () => {
    name.setValue("");
    filter("");
  };

  const changeStatus = (e) => {
    if (e.target.checked) {
      setStatus([...status, e.target.value]);
    } else {
      // remove from list
      setStatus(status.filter((_status) => _status !== e.target.value));
    }
  };

  useEffect(() => {
    if (!enableStatus) {
      setStatus([]);
    }
  }, [enableStatus]);

  return (
    <div className={styles.filterContainer}>
      <div>
        <Whisper
          trigger="click"
          placement={"bottomEnd"}
          speaker={
            <Popover
              title={"Filters"}
              visible
              classPrefix="popover-menu-campaign"
            >
              <div className={styles.filtersContainer}>
                {value.user.subscription !== "free" && (
                  <div className={styles.optionContainer}>
                    <div className={styles.option}>
                      <label>
                        <input
                          type="checkbox"
                          checked={enableStatus}
                          onChange={(e) => setEnableStatus(e.target.checked)}
                        />{" "}
                        Status
                      </label>
                    </div>
                  </div>
                )}

                <div>
                  {enableStatus && (
                    <div className={styles.choices}>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="ACTIVE"
                            checked={
                              status.find((list) => list === "ACTIVE")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          ACTIVE
                        </label>
                      </div>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="COMPLETE"
                            checked={
                              status.find((list) => list === "COMPLETE")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          COMPLETE
                        </label>
                      </div>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="RESPONDED"
                            checked={
                              status.find((list) => list === "RESPONDED")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          RESPONDED
                        </label>
                      </div>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="BOUNCED"
                            checked={
                              status.find((list) => list === "BOUNCED")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          BOUNCED
                        </label>
                      </div>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="OPT-OUT"
                            checked={
                              status.find((list) => list === "OPT-OUT")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          OPT-OUT
                        </label>
                      </div>
                      <div>
                        <label className={styles.options}>
                          <input
                            type="checkbox"
                            value="AUTOREPLIED"
                            checked={
                              status.find((list) => list === "AUTOREPLIED")
                                ? true
                                : false
                            }
                            onChange={(e) => changeStatus(e)}
                          />{" "}
                          AUTOREPLIED
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.optionContainer}>
                  <div className={styles.option}>
                    <label>
                      <input
                        type="checkbox"
                        checked={enablePhone}
                        onChange={(e) => setEnablePhone(e.target.checked)}
                      />{" "}
                      Phone Number
                    </label>
                  </div>
                </div>
              </div>
            </Popover>
          }
        >
          <div
            className={cx(
              styles.filterButton,
              status.length > 0 && styles.active
            )}
          >
            <i className="fas fa-filter"></i> Filters
          </div>
        </Whisper>
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          placeholder={"Email, First Name or Last Name"}
          value={name.value}
          onChange={name.onChange}
          className={styles.input}
        />
        {name.value.length > 0 && (
          <button className={styles.clean} onClick={clean}>
            <i className="far fa-times"></i>
          </button>
        )}
      </div>

      <button
        className={styles.filterBtn}
        onClick={() => filter(name.value, status, enablePhone)}
      >
        Find
      </button>
    </div>
  );
}
