import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";
import { Link } from "@reach/router";

const EmailsTemplatesModal = forwardRef((props, ref) => {
  const [leads, setLeads] = useState([]);
  const [emailsTemplates, setEmailsTemplates] = useState([]);

  useImperativeHandle(ref, () => ({
    open(emailsTemplates = undefined) {
      setEmailsTemplates(emailsTemplates);
      var modal = document.getElementById("EmailsTemplatesModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const close = () => {
    var modal = document.getElementById("EmailsTemplatesModal");
    modal.style.display = "none";
  };

  return (
    <div id="EmailsTemplatesModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="pending-emails">Pending Emails Templates</h2>
            </div>

            <div className="col-md-12">
              {emailsTemplates.map((et) => {
                return (
                  <div
                    className="form-group position-relative emails-templates-list"
                    key={et._id}
                  >
                    {et.subject}

                    <Link to={`/email-templates/edit/${et._id}/1`}>
                      <i className="bx bxs-pencil"></i>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EmailsTemplatesModal;
