import { createSlice } from "@reduxjs/toolkit";

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: {},
  reducers: {
    createCampaign: (state, action) => action.payload,
    modifyCampaign: (state, action) => ({ ...state, ...action.payload }),
    resetCampaign: () => {},
  },
});

export const { createCampaign, modifyCampaign, resetCampaign } =
  campaignSlice.actions;

export default campaignSlice.reducer;
