import React from "react";
import styles from "./navigation.module.scss";
import cx from "classnames";
import { useNavigate } from "@reach/router";

export default function NavigationComponent({ changeTab, tab }) {
  const navigate = useNavigate();

  return (
    <div className={styles.navigation}>
      <div className={styles.navigationBody}>
        <div
          className={cx(styles.option, tab === "personal" && styles.active)}
          onClick={() => navigate("/profile/personal")}
        >
          <i className="fad fa-user-circle"></i> Profile
        </div>
        <div
          className={cx(styles.option, tab === "billing" && styles.active)}
          onClick={() => navigate("/profile/billing")}
        >
          <i className="fad fa-credit-card"></i>
          Billing
        </div>
        <div
          className={cx(styles.option, tab === "purchase" && styles.active)}
          onClick={() => navigate("/profile/purchase")}
        >
          <i className="fa-duotone fa-cart-shopping"></i>
          Purchase History
        </div>
        <div
          className={cx(styles.option, tab === "settings" && styles.active)}
          onClick={() => navigate("/profile/settings")}
        >
          <i className="fa-duotone fa-sliders"></i>
          Settings
        </div>
      </div>
    </div>
  );
}
