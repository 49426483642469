import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./Home.scss";
import axios from "axios";
import Loading from "../../components/loading/Loading";
import CampaignLayout from "../../layouts/campaign.layout";
import LoginModal from "../../components/modals/LoginModal";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import toast from "react-hot-toast";
import FeaturesModal from "../../components/modals/FeaturesModal";
import ContactsWidget from "../../components/widgets/contacts/contacts.widget";
import LeadSearchWidget from "../../components/widgets/lead-search/lead-search.widget";
import AutomatedInboxWidget from "../../components/widgets/automated-inbox/automated-inbox.widget";
import SalesNavWidget from "../../components/widgets/sales-nav/sales-nav.widget";
import CampaignSmallComponent from "../../components/campaign-small/campaign-small.component";
import styles from "./home.module.scss";
import cx from "classnames";
import BroadcastSmallCard from "../../components/broadcast/small-card/broadcast-small-card.component";
import BroadCastModal from "../../components/modals/broadcast/broadcast.modal";
import { Context } from "../../Context";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import CampaignModal from "../../components/modals/campaign/campaign.modal";
import { useIntercom } from "react-use-intercom";

export default function Home() {
  const userState = useSelector((store) => store.user);
  const { showNewMessages } = useIntercom();
  const { tab, setTab } = useContext(Context);
  const [user, setUser] = useState(undefined);
  const [broadcast, setBroadcast] = useState(undefined);
  const [campaign, setCampaign] = useState(undefined);
  const [campaigns, setCampaigns] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openBroadcast, setOpenBroadcast] = useState(false);
  const [openCampaign, setOpenCampaign] = useState(false);

  const [loadingLabel, setLoadingLabel] = useState(false);
  const [modalFeature, setModalFeature] = useState(false);
  const [enableBroadcast, setEnableBroadcast] = useState(false);
  const api = process.env.REACT_APP_API;

  const campaingModalRef = useRef();
  const loginModalRef = useRef();
  const featuresModalRef = useRef();
  const broadcastModal = useRef();

  const open = () => {
    let campaignsLimit = user?.limits?.campaigns;

    if (!loading) {
      return false;
    }

    if (campaigns.length >= campaignsLimit && user.role === "regular") {
      toast.error("You reached the limit of campaigns");
      return false;
    }

    setCampaign(undefined);
    setOpenCampaign(true);
  };

  const openBroadcastModal = () => {
    if (!loading) {
      return false;
    }

    if (!enableBroadcast && user.role === "regular") {
      toast.error("You reached the limit of broadcasts for this month");
      return;
    }

    setOpenBroadcast(true);
  };

  const edit = (campaign) => {
    setCampaign(campaign);
    setOpenCampaign(true);
  };

  const editBroadcast = (broadcast) => {
    setBroadcast(broadcast);
  };

  const reload = () => {
    setCampaigns([]);
    setLoadingLabel(true);
    if (user) {
      axios.get(`${api}campaigns/get/user/${user._id}`).then(({ data }) => {
        setCampaigns(data);
        setLoading(true);
        setLoadingLabel(false);
      });
      axios.get(`${api}broadcast/get/user/${user._id}`).then(({ data }) => {
        setBroadcasts(data);
        setLoading(true);
        setLoadingLabel(false);
      });
      axios
        .get(`${api}user/limits`, {
          params: {
            user: user._id,
            ...user.limits,
            start: DateTime.now()
              .startOf("month")
              .toFormat("yyyy-MM-dd HH:mm:ss"),
            end: DateTime.now().endOf("month").toFormat("yyyy-MM-dd HH:mm:ss"),
          },
        })
        .then(({ data }) => {
          setEnableBroadcast(data.broadcast);
        });
    }
  };

  const plan = useMemo(() => {
    let plan = "Business";
    if (user && user.subscription === "free") {
      plan = "Free";
    }

    return plan;
  }, [user]);

  const broadcastsAvailable = useMemo(() => {
    let remaining = 0;
    if (broadcasts && user && user.limits) {
      if (broadcasts.length > user.limits.broadcasts) {
        return "No";
      }
      remaining = user.limits.broadcasts - broadcasts.length;
    }

    return remaining;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcasts]);

  const deleteCampaing = (c) => {
    var r = window.confirm(
      "Are you sure you want to remove this campaing? if you proceed, you will no longer able to check the stats and other information related"
    );
    if (r === false) {
      return;
    }

    axios
      .post(`${api}campaigns/remove/`, {
        id: c._id,
      })
      .then(({ data }) => {
        reload();
        toast.success("Campaign deleted correctly");
      });
  };

  const campaings = useMemo(() => {
    let remaining = 0;
    if (campaigns && user && user.limits) {
      if (campaigns.length > user.limits.campaigns) {
        return "No";
      }
      remaining = user.limits.campaigns - campaigns.length;
    }

    return remaining;
  }, [campaigns, user]);

  const startMessage = () => {
    showNewMessages("Hi, I want to upgrade my account");
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });
  }, []);

  useEffect(() => {
    if (user) {
      setLoadingLabel(true);
      axios.get(`${api}campaigns/get/user/${user._id}`).then(({ data }) => {
        setCampaigns(data);
        setLoading(true);
        setLoadingLabel(false);
      });
      axios.get(`${api}broadcast/get/user/${user._id}`).then(({ data }) => {
        setBroadcasts(data);
        setLoading(true);
        setLoadingLabel(false);
      });
      axios
        .get(`${api}user/limits`, {
          params: {
            user: user._id,
            ...user.limits,
            start: DateTime.now()
              .startOf("month")
              .toFormat("yyyy-MM-dd HH:mm:ss"),
            end: DateTime.now().endOf("month").toFormat("yyyy-MM-dd HH:mm:ss"),
          },
        })
        .then(({ data }) => {
          setEnableBroadcast(data.broadcast);
        });
    }
  }, [user]);

  useEffect(() => {
    console.log({ userState });
  }, [userState]);

  return (
    <CampaignLayout user={user}>
      <div className="home">
        {user ? (
          <section className="container">
            <h2>
              Welcome {user.first_name} {user.last_name}
              {user.subscription === "free" && (
                <button
                  className={styles.upgrade}
                  onClick={() => startMessage()}
                >
                  <i className="fa-solid fa-star"></i> Upgrade
                </button>
              )}
            </h2>
            <h3>Your plan: {plan}</h3>

            <div className="widgets">
              <ContactsWidget />
              <LeadSearchWidget />
              <SalesNavWidget />
              <AutomatedInboxWidget />
            </div>
            {user.subscription !== "free" && (
              <div className="campaigns">
                <div className={styles.homeTabs}>
                  <div
                    className={cx(
                      styles.tab,
                      tab === "campaings" && styles.active
                    )}
                    onClick={() => setTab("campaings")}
                  >
                    <i className="fad fa-envelope"></i>
                    <div>
                      <h3>
                        Campaigns <span>{campaings} campaign(s) available</span>
                      </h3>
                      <button
                        className={cx(styles.buttonCampaign)}
                        disabled={tab !== "campaings"}
                        onClick={() => open()}
                      >
                        <i className="fa-duotone fa-circle-plus"></i> Create
                        Campaign
                      </button>
                    </div>
                  </div>
                  <div
                    className={cx(
                      styles.tab,
                      tab === "broadcast" && [styles.active, styles.red]
                    )}
                    onClick={() => setTab("broadcast")}
                  >
                    <i className="fad fa-bullhorn"></i>
                    <div>
                      <h3>
                        Broadcast{" "}
                        <span>
                          {broadcastsAvailable} broadcast(s) available
                        </span>
                      </h3>
                      <button
                        className={cx(styles.buttonCampaign, styles.broadcast)}
                        disabled={tab !== "broadcast"}
                        onClick={() => openBroadcastModal()}
                      >
                        <i className="fa-duotone fa-circle-plus"></i> Create
                        Broadcast
                      </button>
                    </div>
                  </div>
                </div>
                {tab === "campaings" && (
                  <div className="campaigns__list">
                    {campaigns.map((c) => {
                      return (
                        <CampaignSmallComponent
                          campaign={c}
                          key={c._id}
                          edit={edit}
                          deleteCampaing={deleteCampaing}
                        />
                      );
                    })}
                    {loadingLabel && (
                      <div className="loading-container">
                        <SmallLoaderComponent />
                        <div className="text">Loading Campaigns...</div>
                      </div>
                    )}
                  </div>
                )}
                {tab === "broadcast" && (
                  <div className={styles.broadcastList}>
                    {broadcasts.map((b) => {
                      return (
                        <BroadcastSmallCard
                          broadcast={b}
                          key={b._id}
                          edit={editBroadcast}
                          reload={reload}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </section>
        ) : (
          <Loading />
        )}

        {openCampaign && (
          <CampaignModal
            reload={reload}
            setOpen={setOpenCampaign}
            campaign={campaign}
          />
        )}

        {(openBroadcast || broadcast) && (
          <BroadCastModal
            ref={broadcastModal}
            broadcast={broadcast}
            setOpen={setOpenBroadcast}
            setBroadcast={setBroadcast}
            reload={reload}
          />
        )}

        <LoginModal ref={loginModalRef} />
        {modalFeature && (
          <FeaturesModal
            ref={featuresModalRef}
            close={() => setModalFeature(false)}
          />
        )}
      </div>
    </CampaignLayout>
  );
}
