import React from "react";
import { Link } from "@reach/router";

export default function Breadcrumb({ childs }) {
  return (
    <ol className="breadcrumb m-0">
      {childs.map((child, i) => {
        return (
          <li
            key={`child-${i}`}
            className={`breadcrumb-item ${child.active && "active"}`}
          >
            {child.active ? (
              child.name
            ) : (
              <Link to={child.link}>{child.name}</Link>
            )}
          </li>
        );
      })}
    </ol>
  );
}
