import React from "react";
import styles from "./tip.module.scss";

export default function TipComponent({ title, content }) {
  return (
    <div className={styles.tipContainer}>
      <div className={styles.tipHeader}>
        <div>{title}</div>
        <i className="fad fa-lightbulb-on"></i>
      </div>
      <div
        className={styles.tipContent}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}
