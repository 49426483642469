import React, { memo } from "react";

import { Handle } from "react-flow-renderer";

export default memo(({ id, data }) => {
  return (
    <>
      <Handle type="target" position="top" />
      <div onDoubleClick={() => data.onDoubleClick(id, data, "delay")}>
        {data.time
          ? `${data.time} ${data.interval}`
          : "Double click to edit..."}
      </div>
      <Handle type="source" position="bottom" />
    </>
  );
});
