import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";

const LeadsPerUserModal = forwardRef((props, ref) => {
  const [id, setId] = useState("");
  const [user, setUser] = useState("");
  const [lead, setLead] = useState("");
  const email = useInputValue("");
  const api = process.env.REACT_APP_API;

  useImperativeHandle(ref, () => ({
    open(user = undefined, row = undefined) {
      if (user) {
        setUser(user);
        setId(user._id);
        setLead(undefined);
        email.setValue("");
      }
      if (row) {
        email.setValue(row.email);
        setLead(row);
      }
      var modal = document.getElementById("leadPerUser");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const close = () => {
    var modal = document.getElementById("leadPerUser");
    modal.style.display = "none";
  };

  const searchLead = () => {
    let body = {
      id: id,
      email: email.value,
    };
    axios.post(`${api}lead/search-lead`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        if (data) {
          setLead(data);
        } else {
          setLead(undefined);
        }
      }
    });
  };

  const unsubscribe = () => {
    let body = {
      user: user._id,
      email: email.value,
      campaign: user.campaign,
    };

    axios.post(`${api}lead/delete-lead`, body).then(({ data }) => {
      if (data.error) {
        console(data.error);
      } else {
        props.loadData();
        close();
      }
    });
  };

  return (
    <div id="leadPerUser" className="modal">
      {user && (
        <div className="modal-content">
          <span className="close" onClick={close}>
            &times;
          </span>
          <div className="form-container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group position-relative">
                  <label>Search Lead by Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email.value}
                    onChange={email.onChange}
                    required=""
                  />
                </div>
              </div>
              {lead && (
                <>
                  <div className="col-md-6">
                    <div className="form-group position-relative">
                      <label>First name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        defaultValue={lead.first_name ? lead.first_name : ""}
                        disabled={false}
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group position-relative">
                      <label htmlFor="validationTooltip02">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationTooltip02"
                        placeholder="Last name"
                        defaultValue={lead.last_name ? lead.last_name : ""}
                        disabled={false}
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group status">
                      <label htmlFor="">Status</label>
                      {lead.unsubscribe === true ? (
                        <p className="unsubscribed">Unsubscribed</p>
                      ) : (
                        <p className="active">Active</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 buttons-lead">
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={unsubscribe}
                      disabled={lead.unsubscribe === true}
                    >
                      Unsubscribe
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="buttons">
              <button
                type="button"
                onClick={close}
                className="btn btn-secondary waves-effect waves-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default LeadsPerUserModal;
