/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import styles from "./follow-up.module.scss";
import { useForm } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import { Controller } from "react-hook-form";
import { useInputValue } from "../../../../hooks/useInputValue";
import toast from "react-hot-toast";
import axios from "axios";
import { useEffect } from "react";
import { Button } from "../../../../styled-components/button.styled";
import EmptyFollowUp from "../empty-follow-up/empty-follow-up.component";
import { Input } from "../../../../styled-components/input.styled";
import { Select } from "../../../../styled-components/select.styled";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";

export default function FollowUp({
  open,
  setOpen,
  campaign,
  followUp,
  updateFollowUp,
  index,
  setIndex,
  total,
  addFollowUp,
  enableTest = true,
}) {
  const validContent = (value) => {
    const valid = value.replace(/<[^>]+>/g, "");
    return valid
      ? {
          isValid: true,
        }
      : {
          isValid: false,
        };
  };

  const schema = yup.object().shape({
    time: yup.number(),
    interval: yup.string(),
    subject: yup
      .string()
      .required()
      .test("validator-custom-name", function (value) {
        const validation = validContent(value);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
    content: yup
      .string()
      .required()
      .test("validator-custom-name", function (value) {
        const validation = validContent(value);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
  });
  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const api = process.env.REACT_APP_API;
  const quillRef = useRef();
  const quillRefSubject = useRef();
  const [test, setTest] = useState(false);

  // Test
  const testEmail = useInputValue("");
  const testFname = useInputValue("");
  const testLname = useInputValue("");
  const testCompany = useInputValue("");
  const testIndustry = useInputValue("");

  const insert = (text, quillReference) => {
    let editor = quillReference.current.getEditor();
    let range = editor.getSelection();
    let position = range ? range.index : 0;
    editor.insertText(position, text);
  };

  const cancel = () => {
    setOpen(false);
    setIndex(undefined);
  };

  const bindFields = (body, lead) => {
    let bodyBind = body;

    if (bodyBind.indexOf("{{first_name}}") > -1) {
      if (lead.first_name) {
        bodyBind = bodyBind.replace(/{{first_name}}/g, lead.first_name);
      } else {
        if (bodyBind.indexOf("{{last_name}}") === -1) {
          bodyBind = bodyBind.replace(/{{first_name}}/g, "Friend");
        } else {
          bodyBind = bodyBind.replace(/{{first_name}}/g, "");
        }
      }
    }

    if (bodyBind.indexOf("{{last_name}}") > -1) {
      if (lead.last_name) {
        bodyBind = bodyBind.replace(/{{last_name}}/g, lead.last_name);
      } else {
        bodyBind = bodyBind.replace(/{{last_name}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{company}}") > -1) {
      if (lead.companyName) {
        bodyBind = bodyBind.replace(/{{company}}/g, lead.companyName);
      } else {
        bodyBind = bodyBind.replace(/{{company}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{industry}}") > -1) {
      if (lead.industry) {
        bodyBind = bodyBind.replace(/{{industry}}/g, lead.industry);
      } else {
        bodyBind = bodyBind.replace(/{{industry}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{email}}") > -1) {
      if (lead.email) {
        bodyBind = bodyBind.replace(/{{email}}/g, lead.email);
      } else {
        bodyBind = bodyBind.replace(/{{email}}/g, "");
      }
    }

    if (bodyBind.indexOf("{{unsubscribe}}") > -1) {
      bodyBind = bodyBind.replace(
        /{{unsubscribe}}/g,
        `<a href="https://staging.automatedinbox.com/unsubscribe/${
          lead.email
        }/${campaign._id.toString()}">unsubscribe</a>`
      );
    }

    return bodyBind;
  };

  const sendTest = () => {
    const lead = {
      first_name: testFname.value,
      last_name: testLname.value,
      email: testEmail.value,
      companyName: testCompany.value,
      industry: testIndustry.value,
    };
    if (!testFname.value || !testLname.value || !testEmail.value) {
      toast.error("Required fields for testing");
      return;
    }

    let _body = bindFields(getValues("content"), lead);
    let _subject = bindFields(getValues("subject"), lead);

    const body = {
      content: _body,
      subject: _subject.replace(/<[^>]+>/g, ""),
      to: testEmail.value,
      campaign: campaign,
    };

    const promise = axios
      .post(`${api}lead/send-test`, body)
      .then(({ data }) => {
        if (data.error) {
          console(data.error);
        }
      });
    toast.promise(promise, {
      loading: "Sending test...",
      success: "Email test sent!",
      error: (error) => (error ? error : "Uh oh, there was an error!"),
    });
  };

  const onSubmit = (data) => {
    followUp.subject = data.subject;
    followUp.content = data.content;
    followUp.delay = data.time;
    followUp.interval = data.interval;
    updateFollowUp(followUp, true);
    setOpen(false);
  };

  const onSubmitNoClose = (data) => {
    followUp.subject = data.subject;
    followUp.content = data.content;
    followUp.delay = data.time;
    followUp.interval = data.interval;
    updateFollowUp(followUp);
  };

  useEffect(() => {
    setValue("subject", followUp?.subject);
    setValue("content", followUp?.content);
    setValue("time", followUp?.delay);
    setValue("interval", followUp?.interval);
  }, [followUp]);

  if (!open) return <EmptyFollowUp total={total} addFollowUp={addFollowUp} />;

  return (
    <div className={styles.followUpContainer}>
      {index > 0 && (
        <div className={cx(styles.formGroup, styles.wait)}>
          <label>Interval</label>
          <div className={styles.delayFields}>
            <label>Wait</label>
            <div className={styles.formGroup}>
              <Input
                type={"number"}
                placeholder={"1"}
                {...register("time")}
                defaultValue={followUp.delay || 3}
                className={errors.email && styles.error}
              />
            </div>
            <div className={styles.formGroup}>
              <Select
                {...register("interval", {
                  required: false,
                })}
                defaultValue={followUp.interval || "hour"}
              >
                <option value="hour">Hour(s)</option>
                <option value="day">Day(s)</option>
              </Select>
            </div>
            <div className={styles.afterText}>After the previous email</div>
          </div>
        </div>
      )}
      <div className={cx(styles.body, index === 0 && styles.noDelay)}>
        <div className={styles.formGroup}>
          <label>
            Subject <span>required</span>
          </label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <ReactQuill
                ref={quillRefSubject}
                theme="snow"
                modules={{ toolbar: null }}
                value={value}
                onChange={onChange}
                className={cx(styles.subject, errors.subject && styles.error)}
              />
            )}
            name="subject"
            variant="outlined"
            defaultValue={followUp?.subject}
            control={control}
          />
          <div className={styles.buttonsFlex}>
            <button onClick={() => insert("{{first_name}}", quillRefSubject)}>
              first name
            </button>
            <button onClick={() => insert("{{last_name}}", quillRefSubject)}>
              last name
            </button>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="">
            Content <span>required</span>
          </label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={onChange}
                className={cx(styles.content, errors.content && styles.error)}
              />
            )}
            name="content"
            variant="outlined"
            defaultValue={followUp?.content}
            control={control}
          />
          <div className={styles.buttonsFlex}>
            <button onClick={() => insert("{{first_name}}", quillRef)}>
              first name
            </button>
            <button onClick={() => insert("{{last_name}}", quillRef)}>
              last name
            </button>
            <button onClick={() => insert("{{email}}", quillRef)}>email</button>
            <button onClick={() => insert("{{company}}", quillRef)}>
              company
            </button>
            <button onClick={() => insert("{{industry}}", quillRef)}>
              industry
            </button>
            <button onClick={() => insert("{{unsubscribe}}", quillRef)}>
              unsubscribe
            </button>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button primary onClick={handleSubmit(onSubmitNoClose)}>
            Save
          </Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            Save and Close
          </Button>
          <Button onClick={() => cancel()}>Cancel</Button>
        </div>
        <br />
        {enableTest && (
          <label className={styles.headerSection}>
            <input
              type="checkbox"
              checked={test}
              onChange={(e) => setTest(e.target.checked)}
            />{" "}
            Test Email
          </label>
        )}

        {test && (
          <div className={cx(styles.formGroup, styles.testContainer)}>
            <div className={styles.grid}>
              <div className={styles.formGroup}>
                <label>
                  First name <span>required</span>
                </label>
                <Input
                  type="text"
                  placeholder="Jane"
                  onChange={testFname.onChange}
                  value={testFname.value}
                />
              </div>
              <div className={styles.formGroup}>
                <label>
                  Last name <span>required</span>
                </label>
                <Input
                  type="text"
                  placeholder="Doe"
                  onChange={testLname.onChange}
                  value={testLname.value}
                />
              </div>
            </div>

            <div className={styles.grid}>
              <div className={styles.formGroup}>
                <label>Company</label>
                <Input
                  type="text"
                  placeholder="Automated Inbox"
                  onChange={testCompany.onChange}
                  value={testCompany.value}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Industry</label>
                <Input
                  type="text"
                  placeholder="Tech"
                  onChange={testIndustry.onChange}
                  value={testIndustry.value}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label>
                Email <span>required</span>
              </label>

              <Input
                type="text"
                placeholder="janedoe@automatedinbox.com"
                onChange={testEmail.onChange}
                value={testEmail.value}
              />
            </div>

            <div>
              <Button onClick={sendTest}>Send Test Email</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
