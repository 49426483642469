import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./Modal.scss";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { readString } from "react-papaparse";
import { Link } from "@reach/router";
import Truncate from "react-truncate";

const LinkedinSearchsModal = forwardRef((props, ref) => {
  const [leads, setLeads] = useState([]);
  const [linkedinSearchs, setLinkedinSearchs] = useState([]);

  useImperativeHandle(ref, () => ({
    open(data = undefined) {
      setLinkedinSearchs(data);
      console.log(data);
      var modal = document.getElementById("LinkedinSearchsModal");
      modal.style.display = "block";

      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
  }));

  const close = () => {
    var modal = document.getElementById("LinkedinSearchsModal");
    modal.style.display = "none";
  };

  return (
    <div id="LinkedinSearchsModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={close}>
          &times;
        </span>
        <div className="form-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="pending-emails">Pending Linkedin Searchs</h2>
            </div>

            <div className="col-md-12">
              {linkedinSearchs.map((et) => {
                return (
                  <div
                    className="form-group position-relative emails-templates-list"
                    key={et._id}
                  >
                    <Truncate lines={1} ellipsis={"..."}>
                      {et.search}
                    </Truncate>

                    <Link to={`/linkedin-searchs/edit/${et._id}/1`}>
                      <i className="bx bxs-pencil"></i>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LinkedinSearchsModal;
