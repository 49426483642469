import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { Context } from "../../../../Context";
import { Button } from "../../../../styled-components/button.styled";
import styles from "./template.module.scss";

export default function TemplateComponent({
  title,
  description,
  staff,
  object,
  owner,
  replaceSequence,
  template,
  editTemplate,
  deleteTemplate,
  admin,
}) {
  const value = useContext(Context);

  const onClick = () => {
    replaceSequence(object);
  };

  return (
    <div className={styles.templateContainer}>
      <div className={styles.templateHeader}>
        <div>
          <div className={styles.name}>{title}</div>
          {staff && (
            <span
              className={styles.official}
              data-tip
              data-for={`Tooltip-open-official`}
            >
              official
            </span>
          )}
          <div className={styles.description}>{description}</div>
        </div>
        <i className="fa-duotone fa-wand-magic-sparkles"></i>
      </div>
      <div className={styles.buttonContainer}>
        <Button primary onClick={() => onClick()}>
          Use this Template
        </Button>
        {(owner === value.user._id || admin) && (
          <>
            <Button onClick={() => editTemplate(template)}>
              <i className="fa-duotone fa-pen"></i>
            </Button>
            <Button onClick={() => deleteTemplate(template)}>
              <i className="fa-duotone fa-trash"></i>
            </Button>
          </>
        )}
      </div>
      <ReactTooltip id={`Tooltip-open-official`} place="top" type="dark">
        <div className="tooltip-box">
          This template was created by the team of automated inbox
        </div>
      </ReactTooltip>
    </div>
  );
}
