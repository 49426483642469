import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./login.module.scss";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "@reach/router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import toast from "react-hot-toast";
import { useIntercom } from "react-use-intercom";
import { Context } from "../../../Context";
import { useDispatch } from "react-redux";
import { createUser } from "../../../redux/states/user";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { activateAuth } = useContext(Context);
  const { update } = useIntercom();
  const [caps, setCaps] = useState(false);

  const onSubmit = async (data, event) => {
    const recaptchaToken = await executeRecaptcha("signin");

    let body = {
      email: data.email,
      password: data.password,
      responseCaptcha: recaptchaToken,
      version: "2",
    };

    const promise = axios.post(`${api}auth/login`, body);
    toast.promise(promise, {
      loading: "Login...",
      success: ({ data }) => {
        update({ name: data.user.first_name, email: data.user.email });
        activateAuth(data.token, data.user);
        dispatch(createUser(data.user));

        if (data.user.role === "regular") {
          toast.dismiss();
          navigate("/", { replace: true });
        } else {
          toast.dismiss();
          navigate("/dashboard", { replace: true });
        }
      },
      error: (error) => {
        return `${error.response.data.message}`;
      },
    });
  };

  useEffect(() => {
    let inputs = document.querySelectorAll("input");
    inputs.forEach((element) => {
      element.addEventListener("keyup", function (event) {
        if (event.getModifierState("CapsLock")) {
          setCaps(true);
        } else {
          setCaps(false);
        }
      });
    });
  }, []);

  return (
    <div className={styles.auth}>
      {/* <GoogleReCaptcha refreshReCaptcha={false} onVerify={onVerify} /> */}
      <div className={styles.authGrid}>
        <div className={styles.formColumn}>
          <img src="/logo.svg" alt="logo" />
          <div className={styles.formContainer}>
            <h1>Welcome back!</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.formGroup}>
                <input
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  className={errors.email && styles.error}
                />
              </div>

              <div className={styles.formGrid}>
                <div className={styles.formGroup}>
                  <input
                    placeholder="Password"
                    type="password"
                    {...register("password", { required: true })}
                    className={errors.password && styles.error}
                  />
                </div>
              </div>
              {caps && (
                <div className={styles.warningCaps}>
                  Email and Password are case sensitive
                </div>
              )}
              <input
                type="submit"
                className={styles.button}
                value={"Sign in"}
              />
            </form>
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </div>
        </div>
        <div className={styles.imageColumn}>
          {/* <img src="/auth-01.jpeg" alt="" /> */}
        </div>
      </div>
    </div>
  );
}
