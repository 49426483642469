import React, { useEffect, useState, useRef } from "react";
import Layout from "../../templates/Layout";
import { Link, useLocation, useNavigate } from "@reach/router";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import moment from "moment";
import UserModal from "../../components/modals/User";
import EmailsTemplatesModal from "../../components/modals/EmailsTemplates";
import LinkedinSearchsModal from "../../components/modals/LinkedinSearchs";
import LeadsPerUserModal from "../../components/modals/LeadsPerUser";
import HardResetModal from "../../components/modals/HardReset";
import DataTable, { defaultThemes } from "react-data-table-component";
import queryString from "query-string";
import { Popover, Whisper } from "rsuite";
import ReactTooltip from "react-tooltip";
import { Button } from "../../styled-components/button.styled";

export default function TemplatesPage() {
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [templates, setTemplates] = useState([]);
  const [usersTemp, setUsersTemp] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [nameTemp, setNameTemp] = useState("");
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;

  const customStyles = {
    header: {},
    headRow: {
      style: {
        minHeight: "40px",
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {
        minHeight: "35px",
        "&:not(:last-of-type)": {
          borderBottomWidth: "1px",
          borderBottomColor: "#f1f2f6",
        },
        i: {
          fontFamily: "'Font Awesome 6 Pro' !important",
          cursor: "pointer",
        },
        "*": {
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
        },

        ".pending": {
          backgroundColor: "#ffc31233",
          color: "#f79f1f",
          fontSize: "12px",
          fontWeight: 600,
          marginLeft: 10,
          borderRadius: 4,
        },
        ".processing": {
          backgroundColor: "#0652dd57",
          color: "#0652dd",
          fontSize: "12px",
          fontWeight: 600,
          marginLeft: 10,
          borderRadius: 4,
        },
        ".request": {
          backgroundColor: "#57606f57",
          color: "#57606f",
        },
      },
    },
    cells: {
      style: {},
    },
  };

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: false,
      minWidth: "200px",
      cell: (row) => (
        <>
          <Link to={`/admin/template/${row._id}`} className={"name-link"}>
            <>
              <div className="text">{row.name}</div>
            </>
          </Link>
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    {
      name: "Created At",
      selector: "createdAt",
      sortable: false,
      minWidth: "120px",
      cell: (row) => (
        <>
          <div className="text">{moment(row.createdAt).format("LL")}</div>
        </>
      ),
    },
    {
      name: "",
      sortable: false,
      width: "50px",
      cell: (row) => (
        <>
          <Whisper
            trigger="click"
            placement={"bottomEnd"}
            speaker={
              <Popover title="Actions" visible>
                <>
                  <div
                    className="option"
                    onClick={() => navigate(`/admin/tip/${row._id}`)}
                  >
                    Edit
                  </div>
                </>
              </Popover>
            }
          >
            <i className="far fa-ellipsis-h"></i>
          </Whisper>
        </>
      ),
    },
  ];

  const [breadcrump] = useState([
    {
      name: "Email Templates",
      active: true,
    },
  ]);

  const loadData = () => {
    axios.get(`${api}template/all`).then(({ data }) => {
      setTemplates(data);
    });
  };

  useEffect(() => {
    filtrar();
  }, [location]);

  const filtrar = () => {
    axios
      .get(`${api}templates/all`, {
        params: {
          pagination: false,
        },
      })
      .then(({ data }) => {
        setTemplates(data);
      });
  };

  const handleFilterByName = (e) => {
    setNameTemp(e.target.value);
  };

  useEffect(() => {
    let queryURL = queryString.stringify({
      page: page,
      filterByName: name,
      rowsPerPage: rowsPerPage,
    });
    navigate(`?${queryURL}`);
  }, [name, page, rowsPerPage]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (nameTemp) {
      setTemplates(
        usersTemp
          .map((user) => {
            return {
              ...user,
              name: user.first_name + " " + user.last_name,
            };
          })
          .filter((list) =>
            list.name.toLowerCase().includes(nameTemp.toLowerCase())
          )
      );
    } else {
      setTemplates(usersTemp);
    }
  }, [nameTemp]);

  const filterByName = () => {
    setName(nameTemp);
  };

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Email Templates</h4>

              <div className="page-title-right">
                <Breadcrumb childs={breadcrump} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div style={{ marginBottom: 10 }} className={"users-heading"}>
                  <Button onClick={() => navigate("/admin/template")}>
                    Add Templates
                  </Button>
                  <div className="filter-container">
                    <input
                      type="text"
                      placeholder={"Filter by Name"}
                      onInput={handleFilterByName}
                      className="filter-input"
                    />
                    <button className="filter-btn" onClick={filterByName}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
                {templates && (
                  <DataTable
                    noHeader={true}
                    pagination={true}
                    data={templates}
                    columns={columns}
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[15, 20, 30]}
                    customStyles={customStyles}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
