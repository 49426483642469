import { useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import Layout from "../../templates/Layout";
import styles from "./save.module.scss";
import toast from "react-hot-toast";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { FormGroup } from "../../styled-components/form-group.styled";
import { Input } from "../../styled-components/input.styled";
import { Select } from "../../styled-components/select.styled";
import { Button } from "../../styled-components/button.styled";
import ReactQuill from "react-quill";
import { useRef } from "react";
import cx from "classnames";
import { useState } from "react";
import FollowUp from "../../components/campaign/sequence/follow-up/follow-up.component";
import { useMemo } from "react";
import ReactTooltip from "react-tooltip";

export default function TemplateSavePage({ id }) {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(undefined);
  const quillRefSubject = useRef();
  const [followUps, setFollowUps] = useState([]);
  const [followUp, setFollowUp] = useState(undefined);
  const [index, setIndex] = useState(undefined);
  const [openBuilder, setOpenBuilder] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("draft");

  const saveTemplate = () => {
    let body = {
      name,
      status,
      flow: followUps,
    };
    if (id) {
      body.id = id;
      const promise = axios
        .post(`${api}template/edit`, body)
        .then(({ data }) => {
          navigate(`/admin/templates`);
        });
      toast.promise(promise, {
        loading: "Updating template...",
        success: "Template updated!",
        error: "Uh oh, there was an error!",
      });
    } else {
      const promise = axios
        .post(`${api}template/add`, body)
        .then(({ data }) => {
          navigate(`/admin/templates`);
        });
      toast.promise(promise, {
        loading: "Creating template...",
        success: "Template created!",
        error: "Uh oh, there was an error!",
      });
    }
  };

  const updateFollowUp = (followUp, closeBox) => {
    let tempFollowUps = followUps.map((fp, i) => {
      if (i === index) {
        return followUp;
      }
      return fp;
    });
    setFollowUps(tempFollowUps);
    if (closeBox) {
      setIndex(undefined);
    }
  };

  const openFollowUp = (followUp, i) => {
    if (i === index) {
      setOpenBuilder(false);
      setIndex(undefined);
      return;
    }
    setContent(followUp.content);
    setSubject(followUp.subject);
    setFollowUp(followUp);
    setOpenBuilder(true);
    setIndex(i);
  };

  const orderFollowUps = useMemo(() => {
    return followUps.sort((a, b) => {
      return a.position > b.position;
    });
  }, [followUps]);

  const upEmail = (i, followUp) => {
    let tempFollowUps = [...followUps];
    let newPosition = i - 1;
    if (newPosition > -1) {
      let temp = tempFollowUps[newPosition];
      tempFollowUps[newPosition] = followUp;
      tempFollowUps[i] = temp;
    }
    setFollowUps(tempFollowUps);
  };

  const addNewFollowUp = () => {
    let newFollowUp = {
      subject: "",
      content: "",
      delay: 3,
      interval: "day",
    };
    let tempFollowUps = [...followUps];
    tempFollowUps.push(newFollowUp);
    setFollowUps(tempFollowUps);
    setFollowUp(newFollowUp);
    setIndex(tempFollowUps.length - 1);
    setOpenBuilder(true);
  };

  const isInvalid = (followUp) => {
    if (followUp.subject === "" || followUp.content === "") {
      return true;
    }

    return false;
  };

  const downEmail = (i, followUp) => {
    let tempFollowUps = [...followUps];
    let newPosition = i + 1;
    if (newPosition < tempFollowUps.length) {
      let temp = tempFollowUps[newPosition];
      tempFollowUps[newPosition] = followUp;
      tempFollowUps[i] = temp;
    }
    setFollowUps(tempFollowUps);
  };

  const deleteFollowUp = (e, i) => {
    if (!window.confirm("Are you sure you want to delete this follow up?")) {
      e.stopPropagation();
      return;
    }

    let tempFollowUps = [...followUps];
    tempFollowUps.splice(i, 1);
    setFollowUps(tempFollowUps);
    setIndex(undefined);
    setOpenBuilder(false);
    e.stopPropagation();
  };

  useEffect(() => {
    if (id) {
      axios.get(`${api}template/${id}`).then(({ data }) => {
        setFollowUps(data.flow);
        setName(data.name);
        setStatus(data.status);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout styleClass="all-users">
      <div className="container-fluid">
        <div className="container">
          <FormGroup>
            <label>Title</label>
            <Input onChange={(e) => setName(e.target.value)} value={name} />
          </FormGroup>
          <FormGroup>
            <label>Status</label>
            <Select onChange={(e) => setStatus(e.target.value)} value={status}>
              <option value="published">Published</option>
              <option value="draft">Draft</option>
            </Select>
          </FormGroup>
          <div className="campaign-header">
            <div className="campaign-header__actions">
              <Button className={cx(styles.btn)} onClick={() => saveTemplate()}>
                Save Email Sequence
              </Button>
            </div>
          </div>
          <div className={styles.followUpsContainer}>
            <div className={styles.followColumn}>
              <div className={styles.followUpsScroll}>
                {orderFollowUps.map((followUpItem, i) => {
                  return (
                    <div
                      className={cx(
                        styles.followUpBox,
                        index === i && styles.active,
                        openBuilder && styles.opacity,
                        isInvalid(followUpItem) && styles.redBorder
                      )}
                      key={`follow-${i}`}
                      onClick={() => openFollowUp(followUpItem, i)}
                    >
                      <div className={styles.boxContent}>
                        <div className={styles.subjectHeader}>
                          <div
                            className={styles.headerIcon}
                            data-tip
                            data-for={`Tooltip-open-sequence-number`}
                          >
                            <i className="fad fa-envelope"></i> {i + 1}
                            {i === 0 && " - Intro"}
                            {isInvalid(followUpItem) && " - Missing Content"}
                          </div>
                          <ReactTooltip
                            id={`Tooltip-open-sequence-number`}
                            place="top"
                            type="dark"
                          >
                            <div className="tooltip-box">
                              This number indicate the position in the sequence
                              for this follow up
                            </div>
                          </ReactTooltip>
                          <div className={styles.buttonPosition}>
                            <i
                              className="fad fa-arrow-circle-up"
                              onClick={() => upEmail(i, followUpItem)}
                            ></i>
                            <i
                              className="fad fa-arrow-circle-down"
                              onClick={() => downEmail(i, followUpItem)}
                            ></i>
                          </div>
                        </div>
                        <div className={styles.subjectBox}>
                          <label htmlFor="">Subject</label>
                          <div className={styles.subjectTitle}>
                            {followUpItem.subject.replace(/<[^>]+>/g, "")}
                          </div>
                        </div>
                        {i > 0 && (
                          <div className={styles.delayBox}>
                            <label htmlFor="">Wait</label>
                            <div className={styles.delayTitle}>
                              {followUpItem.delay} {followUpItem.interval}
                              {"(s) "}
                              <span> after the previous email</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.footerBox}>
                        <button onClick={(e) => deleteFollowUp(e, i)}>
                          <i className="fad fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
                {followUps.length < 10 && (
                  <button
                    className={cx(styles.addFollowButton)}
                    onClick={() => addNewFollowUp()}
                  >
                    {followUps.length === 0
                      ? "Add Intro Email"
                      : "Add Follow Up"}
                  </button>
                )}
              </div>
            </div>
            <div>
              <FollowUp
                campaign={campaign}
                subject={subject}
                content={content}
                followUp={followUp}
                open={openBuilder}
                setOpen={setOpenBuilder}
                updateFollowUp={updateFollowUp}
                index={index}
                setIndex={setIndex}
                total={followUps.length}
                addFollowUp={addNewFollowUp}
                enableTest={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
