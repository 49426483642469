import { configureStore } from "@reduxjs/toolkit";
import { broadcastSlice } from "./states/broadcast";
import { campaignSlice } from "./states/campaign";
import { trackingSlice } from "./states/tracking";
import { userSlice } from "./states/user";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    campaign: campaignSlice.reducer,
    tracking: trackingSlice.reducer,
    broadcast: broadcastSlice.reducer,
  },
});
