import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Context } from "../../../Context";
import styles from "./automated-inbox.module.scss";
import axios from "axios";
import Truncate from "react-truncate";
import cx from "classnames";

export default function AutomatedInboxWidget() {
  const value = useContext(Context);
  const navigate = useNavigate();
  const [searchs, setSearchs] = useState([]);
  const [loading, setLoading] = useState(false);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api}widget/automated-inbox`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setSearchs(data);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.widget}>
      <div className={styles.title}>Automated Inbox Searches</div>
      <div className={styles.searchs}>
        {!loading &&
          searchs.map((search) => {
            return (
              <div
                className={styles.search}
                key={search._id}
                onClick={() =>
                  navigate(`/automated-inbox-searches/${search._id}`)
                }
              >
                <div className={styles.rate}>{search.credits}</div>
                <div className={styles.details}>
                  <div className={styles.name}>
                    <Truncate width={200} ellipsis={"..."}>
                      {search.name}
                    </Truncate>
                  </div>
                  <div className={cx(styles.status, styles.completed)}>
                    Completed
                  </div>
                </div>
              </div>
            );
          })}
        {!loading && searchs.length === 0 && (
          <div className={styles.nothing}>You don't have any search yet</div>
        )}
      </div>
    </div>
  );
}
